import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Section } from "../../components/organisms";
import {
  Header,
  Body,
  ContactForm,
  RelatedCaseStudies,
  CreativeWorkHeader,
  CreativeWorkBody,
  CreativeWork,
  TccHeader,
  TafeNswBody,
  TccBody,
  ProvidusBody,
  ProvidusHeader,
  TafenswHeader,
  OurClients,
} from "./components";
import { gql } from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { Context, description, thumbnail, title } from "../../Context";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
  },
  formContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 500,
    margin: `0 auto`,
  },
  section: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  label: {
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  headerPaddingTop: {
    backgroundColor: "#f9f9f9",
    // backgroundImage: "url(`${}`)",
    paddingTop: "240px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    paddingBottom: "220px",
    paddingTop: "122px",
    paddingLeft: "120px",
    paddingRight: "120px",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingBottom: 0,
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "240px",
      backgroundPosition: "82% 93%",
      backgroundSize: "150%",
      backgroundRepeat: "no-repeat",
    },
  },
  workHeaderPaddingTop: {
    "@media (max-width:1024px)": {
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingBottom: "160px",
      backgroundColor: "#f1f5f6",
    },
    backgroundColor: "#f1f5f6",
    paddingTop: "240px",
    backgroundSize: "cover",
    backgroundPosition: "82% 100%",
    paddingBottom: "220px",
    paddingLeft: "120px",
    paddingRight: "120px",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingBottom: 0,
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "160px",
      backgroundPosition: "82% 100%",
      backgroundSize: "150%",
      backgroundRepeat: "no-repeat",
    },
  },
  bodyPaddingTop: {
    marginTop: "-150px",
    paddingLeft: "120px",
    paddingRight: "120px",
    paddingTop: 0,
    paddingLeft: "120px",
    paddingRight: "120px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
      marginTop: "20px",
      marginTop: "-110px",
    },
  },
  workbodyPaddingTop: {
    marginTop: "-180px",
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
      marginTop: "20px",
      marginTop: "-110px",
    },
  },
  RelatedCaseStudiesPaddingTop: {
    paddingTop: "14px",
    paddingLeft: 0,
    paddingRight: 0,
  },
  contactBottomPaddingTop: {
    backgroundColor: "#f9f9f9",
    paddingLeft: "90px",
    paddingRight: "90px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  },
  CreativeWork: {
    "@media (max-width:1024px)": {
      marginTop: 0,
      padding: "0 20px",
    },
    padding: 0,
    marginTop: "-150px",
  },
  CreativeWorkBody: {
    "@media (max-width:1024px)": {
      padding: "0 20px",
    },
  },
  tccHeaderPaddingTop: {
    backgroundColor: "#f9f9f9",
    // backgroundImage: "url(`${}`)",
    paddingTop: "240px",
    backgroundSize: "cover",
    backgroundPosition: "100% 100%",
    paddingBottom: "180px",
    paddingLeft: "120px",
    paddingRight: "120px",
    "@media (max-width:767px)": {
      paddingLeft: "20px !important ",
      paddingRight: "20px !important ",
      paddingBottom: 0,
      backgroundPosition: "75% 100% !important ",
    },
    "@media (max-width:1024px)": {
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingBottom: "180px",
      paddingTop: "180px",
      backgroundSize: "cover",
      backgroundPosition: "100% 100%",
      backgroundRepeat: "no-repeat",
    },
  },
  tafebodyPaddingTop: {
    paddingLeft: "120px",
    paddingRight: "120px",
    paddingTop: 0,
    paddingBottom: "80px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
      marginTop: "-20px",
    },
    "@media (max-width:1024px)": {
      paddingBottom: "40px",
    }
  },
  TccbodyPaddingTop: {
    padding: "60px 0px 90px 0px",
    "@media (max-width:1024px)": { padding: " 45px 0px 65px 0px" },
  },
  tafeheaderPaddingTop: {
    backgroundColor: "#f4f4f4",
    backgroundPosition: "center",
    // backgroundImage: "url(`${}`)",
    paddingTop: "240px",
    backgroundSize: "cover",
    backgroundPosition: "75% 25%",
    paddingBottom: "220px",
    paddingLeft: "120px",
    paddingRight: "120px",
    "@media (max-width:785px)": {
      paddingBottom: "160px",
      paddingTop: "180px"
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "240px",
      backgroundPosition: "82% 93%",
      backgroundSize: "150%",
      backgroundRepeat: "no-repeat",
    },
    "@media only screen and (max-width:1023px)":{
      backgroundPosition: "75% 100%",
      paddingRight: "20px",
      paddingLeft: "20px"
    }
  },
  providusheaderPaddingTop: {
    backgroundColor: "#f9f9f9",

    // backgroundImage: "url(`${}`)",
    paddingTop: "200px",
    backgroundSize: "cover",
    backgroundPosition: "initial",
    paddingBottom: "220px",
    paddingLeft: "120px",
    paddingRight: "120px",
    "@media (max-width: 767px)": {
      paddingBottom: "140px",
      paddingTop: "50%",
      backgroundSize: "150%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "130% 0%",
      textAlign: "center",
      paddingLeft: "40px",
      paddingRight: "40px",
    },
    "@media (max-width: 1023.95px)": {
      paddingTop: "52% !important",
      paddingBottom: "200px",
      backgroundSize: "150%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "125% 0%",
      textAlign: "center",
    },
    "@media screen and (min-width:1024px) and (max-width:1560px)": {
      backgroundPosition: "70%",
    },
  },
}));

const CaseStudies = () => {
  const classes = useStyles();
  const menuId = useContext(Context);
  // const menuId = JSON.parse(localStorage.getItem("menuId"));
  // const menuId = useSelector(state => state.storagedata.menuId);
  // const menuId = Cookies.get('menuId')
  //if (menuId) {
  var GET_PAGE = gql`
    {
      menuItem(id: ${menuId}, idType: DATABASE_ID) {
        id
        label
        connectedNode {
          node {
            ... on Page {
              id
              globalOurClient {
                typesOfClient{
                 clientImage{
                   mediaItemUrl
                 }
               }
              }
              caseStudies {
                headercontent
                backgroundimage {
                  mediaItemUrl
                }
                bodyimage {
                  mediaItemUrl
                }
                bodycontent
                mobileimage {
                  mediaItemUrl
                }
              }
              metaDetails {
                customMetaDescription
                customMetaTitle
              }
              featuredImage {
                node {
                  mediaItemUrl
                }
              }
            }
          }
        }
      }
    }
  `;
  //}

  const { loading, error, data } = useQuery(GET_PAGE);

  var pageContent = data?.menuItem.connectedNode?.node.caseStudies;
  var globalOurClient=data?.menuItem.connectedNode?.node.globalOurClient;
  const metaDetails =data?.menuItem.connectedNode?.node?.metaDetails;
  const featuredImage =data?.menuItem.connectedNode?.node?.featuredImage;
  var pageTitle = data?.menuItem?.label;
  // const handleClick = () => {
  //   window.history.back();
  // };
  const helemetMeta = (
    <Helmet>
    <title>{pageTitle}</title>
    <meta name="description" content={`${metaDetails && metaDetails.customMetaDescription !== null ? metaDetails.customMetaDescription : description  }`} />
    <meta name="title" property="og:title" content={`${metaDetails && metaDetails.customMetaTitle !== null ? metaDetails.customMetaTitle : title  }`} />
    <meta name="description" property="og:description" content={`${metaDetails && metaDetails.customMetaDescription !== null ? metaDetails.customMetaDescription : description  }`} />
    <meta name="image" property="og:image" content={`${featuredImage && featuredImage !== null ? featuredImage.node.mediaItemUrl : thumbnail  }`} />
  </Helmet>
  )

    const location = useLocation();
  var page = location.pathname;
  if (page == "/ourwork/corum-health/" && page == "/ourwork/corum-health/") {
    return (
      <div className={classes.root}>
        {helemetMeta}
        <Section
          className={classes.workHeaderPaddingTop }
          style={{
            backgroundImage: `url(${pageContent?.backgroundimage?.mediaItemUrl})`,
          }}
        >
          <CreativeWorkHeader post={pageContent} />
        </Section>
        <Section className={classes.CreativeWorkBody}>
          <CreativeWorkBody post={pageContent} />
        </Section>
        <Section className={classes.CreativeWork}>
          <CreativeWork post={pageContent} />
        </Section>
        <Section className={classes.RelatedCaseStudiesPaddingTop}>
          <RelatedCaseStudies />
        </Section>
        <Section className={classes.RelatedCaseStudiesPaddingTop}>
          <OurClients post={globalOurClient}  />
        </Section>
        <Section className={classes.contractBottomPaddingTop}>
          <ContactForm />
        </Section>
      </div>
    );
  } else if (page == "/ourwork/tcc" && page == "/ourwork/tcc") {
    return (
      <div className={classes.root}>
         {helemetMeta}
        <Section
          className={classes.tccHeaderPaddingTop}
          style={{
            backgroundImage: `url(${pageContent?.backgroundimage?.mediaItemUrl})`,
          }}
        >
          <TccHeader post={pageContent} />
        </Section>
        <Section className={classes.TccbodyPaddingTop}>
          <TccBody post={pageContent} />
        </Section>
        <Section className={classes.RelatedCaseStudiesPaddingTop}>
          <RelatedCaseStudies />
        </Section>
        <Section className={classes.RelatedCaseStudiesPaddingTop}>
          <OurClients post={globalOurClient} />
        </Section>
        <Section className={classes.contactBottomPaddingTop}>
          <ContactForm />
        </Section>
      </div>
    );
  } else if (page == "/ourwork/tafe-nsw" && page == "/ourwork/tafe-nsw") {
    return (
      <div className={classes.root}>
        {helemetMeta}
        <Section
          className={classes.tafeheaderPaddingTop + " " + "kg_tafe_section"}
          style={{
            backgroundImage: `url(${pageContent?.backgroundimage?.mediaItemUrl})`,
          }}
        >
          <TafenswHeader post={pageContent}  />
        </Section>
        <Section className={classes.tafebodyPaddingTop}>
          <TafeNswBody post={pageContent} />
        </Section>
        <Section className={classes.RelatedCaseStudiesPaddingTop}>
          <RelatedCaseStudies />
        </Section>
        <Section className={classes.RelatedCaseStudiesPaddingTop}>
          <OurClients post={globalOurClient} />
        </Section>
        <Section className={classes.contactBottomPaddingTop}>
          <ContactForm />
        </Section>
      </div>
    );
  } else if (
    page == "/ourwork/providus-investments" &&
    page == "/ourwork/providus-investments"
  ) {
    return (
      <div className={classes.root}>
        {helemetMeta}
        <Section
          className={classes.providusheaderPaddingTop}
          style={{
            backgroundImage: `url(${pageContent?.backgroundimage?.mediaItemUrl})`,
          }}
        >
          <ProvidusHeader post={pageContent} />
        </Section>
        <Section className={classes.bodyPaddingTop}>
          <ProvidusBody post={pageContent} />
        </Section>
        <Section className={classes.RelatedCaseStudiesPaddingTop}>
          <RelatedCaseStudies />
        </Section>
        <Section className={classes.RelatedCaseStudiesPaddingTop}>
          <OurClients post={globalOurClient} />
        </Section>
        <Section className={classes.contactBottomPaddingTop}>
          <ContactForm />
        </Section>
      </div>
    );
  } else {
    return (
      <div className={classes.root}>
        {helemetMeta}
        <Section
          className={classes.headerPaddingTop}
          style={{
            backgroundImage: `url(${pageContent?.backgroundimage?.mediaItemUrl})`,
          }}
        >
          <Header post={pageContent}/>
        </Section>
        <Section className={classes.bodyPaddingTop}>
          <Body post={pageContent} />
        </Section>
        <Section className={classes.RelatedCaseStudiesPaddingTop}>
          <RelatedCaseStudies />
        </Section>
        <Section className={classes.RelatedCaseStudiesPaddingTop}>
          <OurClients post={globalOurClient} />
        </Section>
        <Section className={classes.contactBottomPaddingTop}>
          <ContactForm />
        </Section>
      </div>
    );
  }
};
export default CaseStudies;
