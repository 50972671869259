import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { gql } from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";

const useStyles = makeStyles((theme) => ({
  root: {
    "& a": {
      textDecoration: "none",
    },
    "& .slick-initialized .slick-slide": { padding: "0 15px" },
    "& .slick-dots li.slick-active button": {
      background: "#37f",
      borderColor: "#37f",
    },
    "& .slick-dots li:hover button": {
      background: "#37f",
      borderColor: "#37f",
    },
    "& .slick-dots li button": {
      background: "#fff",
      display: "inline-block",
      width: "12px",
      height: "12px",
      borderRadius: "50%",
      opacity: 1,
      boxShadow: "none",
      transition: "background .5s",
      border: "2px solid #fff",
      padding: "0",
      margin: "0 6px 0 0",
      outline: "0",
      cursor: "pointer",
    },
    "& .slick-slider ul.slick-dots": { bottom: "-60px" },
    "& .slick-slider ul.slick-dots li": {
      width: "auto",
      height: "auto",
      margin: "0",
      padding: "0",
    },
    "& .slick-slider ul.slick-dots li button:before": { display: "none" },
    "& .slick-slider .slick-list": {
      paddingBottom: "3em",
      "@media (max-width: 1023.95px)": {
        paddingBottom: "30px",
      },
    },
    "& .kg-audit-methodology .kg-top-title > h1 ": {
      fontSize: "2rem",
      marginTop: 0,
    },
    "& .kg-audit-methodology > p": {
      fontSize: "1.1667rem",
      marginTop: 0,
      marginBottom: "40px",
    },
    "& .kg-audit-methodology": {
      paddingRight: "12%",
    },
    "& .MuiTypography-root .MuiTypography-h4 .MuiTypography-gutterBottom ": {
      display: "none",
    },
    "& .MuiButton-outlinedSizeLarge": {
      marginTop: "20px",
    },
    "& .kg-img": {
      width: "100%",
      height: "auto",
      display: "block",
      position: "relative",
      objectFit: "cover",
      marginLeft: "auto",
      verticalAlign: "middle",
    },
    "&  .kg-img > img": {
      top: 0,
      left: 0,
      right: 0,
      width: "100%",
      bottom: 0,
      height: "100%",
      display: "inline-block",
      position: "absolute",
      objectFit: "contain",
      objectPosition: "center",
    },
    "& .kg-img:after": {
      width: "100%",
      content: "''",
      display: "block",
      position: "relative",
      paddingBottom: "80%",
    },
    "& .kg-top-title ": {
      display: "none",
    },
    "& .makeStyles-expertbox-82": {
      paddingLeft: 0,
      paddingRight: 0,
    },
    "& .MuiGrid-root.MuiGrid-container.MuiGrid-justify-content-xs-space-between":
      {
        padding: "8.7% 11.4%",
        alignItems: "center",
        "@media (min-width:1025px) and (max-width:1299px)": {
          padding: "30px",
        },
        "@media screen and (max-width:1024px)": {
          padding: "40px",
        },
      },
    "& p.MuiTypography-root.kg-title.MuiTypography-body1": {
      fontSize: "2em",
      marginBottom: "15px",
    },
    "& .MuiGrid-grid-xs-12": {
      "@media screen and (max-width:1024px)": {
        order: 2,
      },
    },
    "& .makeStyles-kgMe-88": {
      "@media screen and (max-width:767px)": {
        width: "100%",
        order: 1,
        margin: "auto",
      },
    },
  },
  imageroot: {
    display: "block",
    maxWidth: "100%",
    cursor: "pointer",
    flexBasis: "100%",
    height: "100%",
    objectFit: "cover",
    position: "relative",
    verticalAlign: "middle",
    marginLeft: "auto !important",
    "&:after": {
      content: '""',
      paddingBottom: "68.5%",
      position: "relative",
      display: "block",
      width: "100%",
    },
    // [theme.breakpoints.down("md")]: {
    //   "&:after": {
    //     paddingBottom: "63.5%",
    //   },
    // },
    [theme.breakpoints.down("xs")]: {
      "&:after": {
        paddingBottom: "63.5%",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "&:after": {
        paddingBottom: "63.5%",
      },
    },
  },
  images: {},
  titleContainer: {
    margin: "0",
    paddingTop: "20px",
    alignItems: "center",
    color: "#202f43",
    fontWeight: 700,
    [theme.breakpoints.down("xs")]: {
      textTransform: "uppercase",
    },
    [theme.breakpoints.down("sm")]: {
      textTransform: "uppercase",
    },
  },
  consultantTitle: {
    paddingBottom: "70px",
    paddingTop: "25px",
    paddingLeft: "15px",
    color: "#202f43",
    paddingBottom: "70px",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "20px",
      paddingLeft: "0",
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "20px",
      paddingLeft: "0",
    },
  },
  carouselItem: {
    padding: "0 15px",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  CarouselSlider: {
    paddingBottom: "0",
  },
  subTitle: {
    // marginLeft: "24px",
    fontWeight: 700,
    color: "#202f43",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "30px",
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "30px",
      width: "100%",
    },
  },
  carouselDot: {
    display: "none",
    // borderColor: "#b3bac4",
    // background: "#b3bac4",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "center",
      position: "absolute",
      bottom: 0,
    },
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      position: "absolute",
      bottom: 0,
    },
  },
  expertbox: {
    background: "#f2f3f8",
    paddingBottom: "80px",
    paddingTop: "80px",
    width: "100%",
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingTop: "80px",
      paddingBottom: "120px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingTop: "80px",
      paddingBottom: "120px",
    },
  },
  button: {
    borderRadius: "0",
    padding: "10px 20px",
    color: "#1948e8",
    borderColor: "#1948e8",
    borderWidth: "2px",
    backgroundColor: "transparent",
    marginTop: "24px",
    textTransform: "capitalize",
    [theme.breakpoints.down("xs")]: {
      padding: "6px 8px",
      marginTop: 0,
      margin: 0,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "6px 8px",
      marginTop: 0,
      margin: 0,
    },
    "&:hover": {
      boxShadow: "inset 0 0 0 2em #1948e8",
      color: "#fff",
      transition: ".6s",
    },
  },
  buttonContainer: {
    marginTop: "25px",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "encenterd",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  link: {
    textDecoration: "none",
  },
  gridcontainer: {
    display: "block",
    width: "100%",
  },
  slider: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "30px",
    },
  },
  images: {
    padding: 0,
    width: "100%",
    textAlign: "center",
    display: "unset",
    [theme.breakpoints.down("md")]: {
      padding: "3em 1em 1em",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "1.5em 0.5em 0.5em",
    },
  },
  kgMe: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  // )
}));

const OurServices = (props) => {
  var settings = {
    // autoplaySpeed: 5000,
    speed: 500,
    autoplay: false,
    infinite: true,
    arrows: false,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 1,
          // dots: true,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 1,
          // dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
      {
        breakpoint: 786,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  };

  const { className, ...rest } = props;
  const classes = useStyles();

  const navigate = useNavigate();

  const post = props.servicesName;

  const rowMarkup = post?.map((item, index) => {
    var backgroundColor =
      item?.node?.connectedNode?.node?.services?.backgroundColor === null
        ? "#f9f9f9"
        : item?.node?.connectedNode?.node?.services?.backgroundColor;
    var color =
      item?.node?.connectedNode?.node?.services?.backgroundColor === null
        ? "#000"
        : "#fff";
    var contentcolor =
      item?.node?.connectedNode?.node?.services?.backgroundColor === null
        ? "#000"
        : "#fff";
    var image = (image =
      item?.node?.connectedNode?.node?.featuredImage?.node?.mediaItemUrl);
    var content = item?.node?.connectedNode?.node?.services?.homePageContent;
    var btnclr =
      item?.node?.connectedNode?.node?.services?.backgroundColor === null
        ? "#1B4BF1"
        : "#fff";

    if (item?.node?.label == "Custom App") {
      image =
        item?.node?.connectedNode?.node?.services?.typesOfServices[0]
          ?.serviceImage?.mediaItemUrl;
    }

    if (item?.node?.label == "Creative") {
      backgroundColor = "#f9f9f9";
      contentcolor = "#000";
      color = "#000";
      btnclr = "#1B4BF1";
      image =
        item?.node?.connectedNode?.node?.services?.typesOfServices[0]
          ?.serviceImage?.mediaItemUrl;
    }

    return (
      <>
        {item ? (
          <Grid
            container
            justifyContent="space-between"
            key={index}
            style={{ backgroundColor: backgroundColor }}
          >
            <Grid
              item
              container
              justifyContent="flex-start"
              alignItems="center"
              xs={12}
              md={6}
              lg={6}
              xl={6}
            >
              {item?.node?.label && (
                <Typography className="kg-title" style={{ color: color }}>
                  {item?.node?.label}
                </Typography>
              )}

              <Typography
                dangerouslySetInnerHTML={{ __html: content }}
                style={{ color: contentcolor }}
              ></Typography>
              <Button
                variant="outlined"
                size="large"
                style={{
                  color: btnclr,
                  borderColor: btnclr,
                }} // Set text and outline color to blue
                onClick={() =>
                  navigate(`${item?.node?.connectedNode?.node?.uri}`)
                }
              >
                Learn more
              </Button>
            </Grid>
            <div className={classes.kgMe}>
              <Link to={item?.node?.connectedNode?.node?.uri}>
                <Grid
                  item
                  container
                  spacing={2}
                  className={classes.titleContainer}
                >
                  <div className="kg-img">
                    <img
                      src={image}
                      alt=""
                      className={classes.images}
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="2000"
                    />
                  </div>
                </Grid>
              </Link>
            </div>
          </Grid>
        ) : (
          ""
        )}
      </>
    );
  });

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
      name="about"
      id="about"
    >
      <div className={classes.expertbox}>
        {rowMarkup ? <Slider {...settings}>{rowMarkup}</Slider> : ""}
      </div>
    </div>
  );
};

OurServices.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default OurServices;
