import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .kg_home_hero0": {
      padding: "120px;",
      alignItems: "center",
      paddingBottom: "120px",
      [theme.breakpoints.down("sm")]: {
        padding: "90px 20px !important",
      },
    },
    "& .kg_home_hero0 .kg_content_hero": {
      "@media (max-width:1023px)": {
        order: "1 !important",
        flexWrap: "wrap",
        flexDirection: "column",
      },
      "@media only screen and (max-width: 1920px) and (min-width: 1023px)": {
        paddingBottom: "0px",
      },
      paddingBottom: "30px",
    },

    "& .kg_home_hero1": {
      padding: "120px",
      "@media only screen and (max-width:1023px)": {
        padding: "60px 20px",
      },
    },
    "& .kg_home_hero1 .kg_content_hero h5": {
      "@media only screen and (max-width:1023px)": {
        fontSize: "2.25rem",
        marginTop: "30px",
      },
    },
    "& .kg_home_hero1 .kg_content_hero p": {
      maxWidth: "800px",
      "@media only screen and (max-width:1023px)": {
        maxWidth: "100%",
      },
    },

    "& .kg_home_hero1 figure:after": {
      paddingBottom: "68.3%",
      "@media only screen and (min-width:1921px)": {
        paddingBottom: "55%",
      },
    },
    "& .kg_home_hero1 .kg_content_hero": {
      maxWidth: "55%",
      flexBasis: "55%",
      "@media only screen and (max-width:1440px)": {
        maxWidth: "50%",
        flexBasis: "50%",
        paddingLeft: "0",
      },
      "@media only screen and (max-width:1023px)": {
        maxWidth: "100%",
        flexBasis: "100%",
        order: "2",
      },
    },

    "& .kg_home_hero1 .kg_home_hero_image1": {
      maxWidth: "45%",
      flexBasis: "45%",
      "@media only screen and (max-width:1440px)": {
        maxWidth: "50%",
        flexBasis: "50%",
      },
      "@media only screen and (max-width:1023px)": {
        maxWidth: "100%",
        flexBasis: "100%",
        padding: "0 ",
        order: "1",
      },
    },
    "& .kg_home_hero1 .kg_home_hero_image1 figure": {
      maxWidth: "80%",
      "@media only screen and (max-width:1440px)": {
        maxWidth: "100%",
      },
      "@media only screen and (max-width:1023px)": {
        maxWidth: "570px",
      },
    },

    "& .kg_home_hero2": {
      padding: "100px 120px",
      [theme.breakpoints.down("sm")]: {
        padding: "60px 20px !important",
      },
      "@media only screen and (max-width:1440px)": {
        paddingTop: "0 !important",
      },
      "@media only screen and (max-width:1023px)": {
        paddingTop: "10px !important",
      },
    },
    "& .kg_home_hero2 .kg_content_hero h5": {
      "@media only screen and (max-width:1023px)": {
        marginTop: "40px",
      },
    },
    "& .kg_home_hero2 .kg_content_hero": {
      maxWidth: "55%",
      flexBasis: "55%",
      "@media only screen and (max-width:1440px)": {
        maxWidth: "50%",
        flexBasis: "50%",
      },
      "@media only screen and (max-width:1023px)": {
        maxWidth: "100%",
        flexBasis: "100%",
      },
    },
    "& .kg_home_hero2 .kg_home_hero_image2": {
      maxWidth: "45%",
      flexBasis: "45%",
      "@media only screen and (max-width:1440px)": {
        maxWidth: "50%",
        flexBasis: "50%",
      },
      "@media only screen and (max-width:1023px)": {
        maxWidth: "100%",
        flexBasis: "100%",
      },
    },
    "& .kg_home_hero3 h5": {
      "@media (max-width:1023px)": {
        marginBottom: "15px",
      },
    },
    "& .kg_home_hero4 h5": {
      fontSize: "58px",
      lineHeight: "65px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "38px",
        lineHeight: "44px",
      },
    },
    "& .kg_home_hero4 figure:after": {
      // paddingBottom: "270px",
      "@media only screen and (max-width: 1920px) and (min-width: 1440px)": {
        paddingBottom: "60% !important",
      },
    },
    "& .kg_home_hero p": {
      fontSize: "1.25rem",
      letterSpacing: "1px",
      maxWidth: "100%",
      marginTop: "5px",
      "@media only screen and (min-width:1921px)": {
        maxWidth: "100%",
        fontSize: "1.8rem",
        lineHeight: "50px",
      },
      "@media only screen and (max-width:1023px)": {
        maxWidth: "100%",
        fontSize: "1.125rem",
      },
    },
    "&  ul li": {
      width: "50%",
      fontSize: "22px",
      fontWeight: "600",
      lineHeight: "36px",
      paddingRight: "25px",
    },
    "& ul li": {
      "@media (max-width:767px)": {
        fontSize: "18px",
        fontWeight: "600",
        lineHeight: "30px",
        width: "100%",
      },
    },
    position: "relative",
    "& .kg_home_hero:nth-child(odd) .kg_content_hero ": {
      order: 2,
      // [theme.breakpoints.down("sm")]: {
      //   order: 2,
      // },
    },
    "& .kg_home_hero0 .kg_content_hero ": {
      maxWidth: "60%",
      flexBasis: "60%",
      "@media (max-width:1440px)": {
        maxWidth: "50%",
        flexBasis: "50%",
      },
      "@media (max-width:1023px)": {
        flexWrap: "wrap",
        flexDirection: "column",
        maxWidth: "100%",
        flexBasis: "100%",
      },

      [theme.breakpoints.down("sm")]: {
        order: 2,
      },
    },

    "& .kg_home_hero0 .kg_home_hero_image0 ": {
      maxWidth: "40%",
      flexBasis: "40%",
      "@media (max-width:1440px)": {
        maxWidth: "50%",
        flexBasis: "50%",
      },
      "@media (max-width:1023px)": {
        maxWidth: "100%",
        flexBasis: "100%",
      },
    },
    "& .kg_home_hero.kg_home_hero0": {
      "@media only screen and (max-width:1500px) and (min-width:1024px)": {
        paddingTop: "190px !important",
      },
    },
  },
  heading: {
    "& h5": {
      fontSize: "3.75rem",
      lineHeight: "72px",
      marginBottom: "38px",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        fontSize: "38px",
        lineHeight: "44px",
        marginBottom: "40px",
        marginTop: "70px",
      },
      "@media only screen and (min-width:1921px)": {
        fontSize: "5rem",
        lineHeight: "95px",
      },
      "@media only screen and (max-width:1023px)": {
        fontSize: "2.25rem",
        marginBottom: "20px",
      },
    },
    "&  p": {
      fontSize: "22px",
      lineHeight: "36px",
      fontWeight: 600,
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
        fontWeight: "600",
        lineHeight: "30px",
      },
      "@media (min-width:1024px) and (max-width:1400px)": {
        fontSize: "20px",
        lineHeight: "34px",
      },
    },
    "@media only screen and (max-width: 1920px) and (min-width: 1023px) ": {
      flexGrow: 0,
      maxWidth: "50%",
      flexBasis: "50%",
    },
  },
  consultantCard: {
    alignItems: "center",
    padding: "150px 120px",
    paddingTop: "190px",
    [theme.breakpoints.down("sm")]: {
      padding: "60px 20px",
    },
    "@media only screen and (max-width:1500px) and (min-width:1024px)": {
      padding: "95px 120px !important",
    },
  },
  imageroot: {
    justifyContent: "center !important",
    flexGrow: 0,
    maxWidth: "40%",
    flexBasis: "40%",
    padding: "0 30px",
    "@media only screen and (max-width:1023px)": {
      maxWidth: "100%",
      flexBasis: "100%",
      padding: "0",
    },
  },

  imageContainer: {
    display: "block",
    maxWidth: "570px",
    height: "100%",
    objectFit: "cover",
    position: "relative",
    verticalAlign: "middle",
    margin: "0",
    width: "100%",
    zIndex: 9,
    "&:after": {
      paddingBottom: "91.3%",
      content: '""',
      position: "relative",
      display: "block",
      width: "100%",
      "@media only screen and (min-width:1921px)": {
        paddingBottom: "55%",
      },
      "@media only screen and (max-width: 1920px) and (min-width: 1440px)": {
        paddingBottom: "70% !important",
      },
    },
    "@media only screen and (min-width:1921px)": {
      maxWidth: "100%",
    },
    "@media only screen and (max-width: 1920px) and (min-width: 1023px)": {
      maxWidth: "100%",
    },
  },
  consultant: {
    position: "absolute",
    top: "0",
    right: "0",
    left: "0",
    bottom: "0",
    objectFit: "contain",
    objectPosition: "center",
    width: "100%",
    height: "100%",
    display: "inline-block",
  },
  sublines: {
    display: "block",
    width: "100%",
    textAlign: "center",
    marginTop: "60px",
  },
  kgbutton: {
    color: "#0e44ff",
    border: "2px solid #0e44ff",
    display: "block",
    padding: "10px 36px",
    marginTop: "35px",
    textTransform: "capitalize",
    backgroundColor: "transparent",
    borderRadius: 0,
    "&:hover": {
      color: "#fff",
      boxShadow: "inset 0 0 0 2em #0e44ff",
      transition: ".6s",
    },
    kgbtna:{
      textDecoration:"none"
    }
  },
}));

const AuditHero = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const post = props.pageContent?.node?.services.typesOfServices;
  return (
    <div className={clsx(classes.root, className, "kg_home_hero")} {...rest}>
      {post?.map((item, index) => {
        return (
          <>
            {item ? (
              <Grid
                container
                direction="row"
                justify="space-between"
                className={
                  classes.consultantCard +
                  " " +
                  "kg_home_hero kg_home_hero" +
                  index
                }
                style={{ backgroundColor: `#${item.color}` }}
              >
                <Grid
                  item
                  container
                  justify="flex-start"
                  xs={12}
                  md={5}
                  lg={6}
                  xl={6}
                  className={classes.heading + " " + "kg_content_hero"}
                >
                  <Typography variant="h5" display="block">
                    {item.nameOfService}
                  </Typography>
                  <Typography variant="body1" className={classes.subline}>
                    <div
                      className="page-content"
                      dangerouslySetInnerHTML={{ __html: item.serviceInfo }}
                    ></div>
                  </Typography>
                  {index === 0 && (
                    <div className={classes.kgherobutton}>
                      <a
                        className={classes.kgbtna}
                        href="https://backend.apprato.com.au/audit-questionnaire//"
                        rel="noreferrer"
                      >
                        <Button
                          type="submit"
                          className={classes.kgbutton}
                          style={{ width: "270px" }}
                        >
                          FREE AUDIT APPRAISAL
                        </Button>
                      </a>
                    </div>
                  )}
                </Grid>
                <Grid
                  item
                  container
                  alignItems="center"
                  xs={12}
                  md={4}
                  lg={5}
                  xl={5}
                  className={
                    classes.imageroot + " " + "kg_home_hero_image" + index
                  }
                >
                  {item.serviceImage?.mediaItemUrl ? (
                    <>
                      <figure className={classes.imageContainer}>
                        <img
                          src={item.serviceImage.mediaItemUrl}
                          className={classes.consultant}
                          alt="Jordan Pearce"
                        />
                      </figure>
                    </>
                  ) : (
                    <>
                      <figure className={classes.imageContainer}>
                        <img
                          src="/images/placeholder.png"
                          className={classes.consultant}
                          alt="Jordan Pearce"
                        />
                      </figure>
                    </>
                  )}
                  {item?.serviceImageAfterTitle ? (
                    <Typography variant="body1" className={classes.sublines}>
                      {item.serviceImageAfterTitle}
                    </Typography>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            ) : (
              ""
            )}
          </>
        );
      })}
    </div>
  );
};

AuditHero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default AuditHero;
