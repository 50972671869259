import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Section } from "../../components/organisms";
import {
  Header,
  Body,
  ContactForm,
} from "./components";
import { gql } from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { Context, description, thumbnail, title } from "../../Context";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
  },
  formContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 500,
    margin: `0 auto`,
  },
  section: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  label: {
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  formContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 500,
    margin: `0 auto`,
  },
  section: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  label: {
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  headerPaddingTop: {
    paddingTop: "122.23px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    paddingBottom: "100px",
    paddingTop: "122px",
    paddingLeft: "120px",
    paddingRight: "120px",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingBottom: 0,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingBottom: 0,
    },
    // [theme.breakpoints.down("xs")]: {
    //   paddingBottom: "100px",
    // },
  },
  bodyPaddingTop: {
    marginTop: 0,
    padding: 0,
  },
  RelatedCaseStudiesPaddingTop: {
    paddingTop: "14px",
    paddingLeft: 0,
    paddingRight: 0,
  },
  contactBottomPaddingTop: {
    background: "transparent",
    paddingTop: "0",
    paddingLeft: "90px",
    paddingRight: "90px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingBottom: 0,
    },
  },
}));

const About = () => {
  const classes = useStyles();

  const menuId = useContext(Context);
  // const menuId = JSON.parse(localStorage.getItem("menuId"));

  // if (menuId) {
  var GET_PAGE = gql`
      {
        menuItem(id: ${menuId}, idType: DATABASE_ID) {
          id
          label
          connectedNode {
            node {
              ... on Page {
                id
                aboutUs {
                  ourTeam {
                    image {
                      mediaItemUrl
                    }
                    name
                    position
                    certificate {
                      name
                    }
                    description
                  }
                }
                content(format: RENDERED)
                featuredImage {
                  node {
                    mediaItemUrl
                  }
                }
                metaDetails {
                  customMetaDescription
                  customMetaTitle
                }
              }
            }
          }
        }
      }
    `;
  // }s

  const { loading, error, data } = useQuery(GET_PAGE);
  if (loading) return <p>Loading Page...</p>;
  var pageContent = data?.menuItem.connectedNode?.node;
  const metaDetails =data?.menuItem.connectedNode?.node?.metaDetails;
  const featuredImage =data?.menuItem.connectedNode?.node?.featuredImage;

  // document.title = 'About us'
  
  return (
    <div className={classes.root}>
      <Helmet>
        <title>About us</title>
        <meta name="description" content={`${metaDetails && metaDetails.customMetaDescription !== null ? metaDetails.customMetaDescription : description  }`} />
        <meta name="title" property="og:title" content={`${metaDetails && metaDetails.customMetaTitle !== null ? metaDetails.customMetaTitle : title  }`} />
        <meta name="description" property="og:description" content={`${metaDetails && metaDetails.customMetaDescription !== null ? metaDetails.customMetaDescription : description  }`} />
        <meta name="image" property="og:image" content={`${featuredImage && featuredImage !== null ? featuredImage.node.mediaItemUrl : thumbnail  }`} />
      </Helmet>
      <Section className={classes.headerPaddingTop}>
        <Header post={pageContent} />
      </Section>
      <Section className={classes.bodyPaddingTop}>
        <Body post={pageContent} />
      </Section>
      <Section className={classes.contactBottomPaddingTop}>
        <ContactForm />
      </Section>
    </div>
  );
};

export default About;
