import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",

    "& .kg_home_hero:nth-child(even) .kg_content_hero ": {
      order: 2,
      // [theme.breakpoints.down("sm")]: {
      //   order: 2,
      // },
    },
    "& .kg_home_hero .kg_content_hero ": {
      [theme.breakpoints.down("sm")]: {
        order: 2,
      },
    },
    "& .kg_home_hero:first-child": {
      color: " #fff",
    },
  },
  heading: {
    "& h1, & h2": {
      fontFamily: "AktivGrotesk-Medium",
      fontSize: "3.5em",
      lineHeight: "72px",
      marginBottom: "44px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "38px",
        lineHeight: "44px",
        marginBottom: "40px",
        marginTop: "70px",
      },
      "@media (min-width:1024px) and (max-width:1400px)": {
        fontSize: "48px",
        lineHeight: "58px",
        marginBottom: "44px",
      },
    },
    "&  p": {
      fontSize: "22px",
      lineHeight: "36px",
      fontWeight: 600,
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
        fontWeight: "600",
        lineHeight: "30px",
      },
      "@media (min-width:1024px) and (max-width:1400px)": {
        fontSize: "20px",
        lineHeight: "34px",
      },
    },
  },
  consultantCard: {
    alignItems: "center",
    padding: "150px 120px",
    [theme.breakpoints.down("sm")]: {
      padding: "60px 20px",
    },
  },
  imageroot: {
    justifyContent: "center !important",
    [theme.breakpoints.down("sm")]: {
      order: 1,
    },
  },
  imageContainer: {
    display: "block",
    maxWidth: "570px",
    height: "100%",
    objectFit: "cover",
    position: "relative",
    verticalAlign: "middle",
    margin: "0",
    width: "100%",
    "&:after": {
      paddingBottom: "91.3%",
      content: '""',
      position: "relative",
      display: "block",
      width: "100%",
    },
  },
  consultant: {
    position: "absolute",
    top: "0",
    right: "0",
    left: "0",
    bottom: "0",
    objectFit: "contain",
    objectPosition: "center",
    width: "100%",
    height: "100%",
    display: "inline-block",
  },
}));

const Hero = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const post = props?.posts?.node?.platforms.typesOfPlateforms;

  return (
    <div className={clsx(classes.root, className, "kg_home_hero")} {...rest}>
      {post?.map((item, index) => {
        return (
          <>
            {item ? (
              <Grid
                container
                direction="row"
                justify="space-between"
                className={classes.consultantCard + " " + "kg_home_hero"}
                style={{ backgroundColor: `#${item.backColor}` }}
              >
                <Grid
                  item
                  container
                  justify="flex-start"
                  xs={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className={classes.heading + " " + "kg_content_hero"}
                >
                  {index > 0 ? (
                    <Typography variant="h2" display="block">
                      {item.nameOfPlatform}
                    </Typography>
                  ) : (
                    <Typography variant="h1" display="block">
                      {item.nameOfPlatform}
                    </Typography>
                  )}
                  <Typography variant="body1" className={classes.subline}>
                    {item.platformInfo}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  alignItems="center"
                  xs={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className={classes.imageroot}
                >
                  {item.platformImage?.mediaItemUrl ? (
                    <>
                      <figure className={classes.imageContainer}>
                        <img
                          src={item.platformImage.mediaItemUrl}
                          className={classes.consultant}
                          alt="Jordan Pearce"
                        />
                      </figure>
                    </>
                  ) : (
                    <>
                      <figure className={classes.imageContainer}>
                        <img
                          src="/images/placeholder.png"
                          className={classes.consultant}
                          alt="Jordan Pearce"
                        />
                      </figure>
                    </>
                  )}
                </Grid>
              </Grid>
            ) : (
              ""
            )}
          </>
        );
      })}
    </div>
  );
};

Hero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Hero;
