import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter,HashRouter  } from "react-router-dom";
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
} from "@apollo/client";

import * as serviceWorker from "./serviceWorker";
import App from "./App";
import ScrollToTop from "./ScrollToTop";

// ReactDOM.render(<App />, document.getElementById('root'));

const client = new ApolloClient({
  uri: "https://backend.apprato.com.au/graphql",
  cache: new InMemoryCache(),
});

ReactDOM.hydrate(
  <ApolloProvider client={client}>
    <BrowserRouter>   
    <ScrollToTop />
      <App />
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
