import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { gql } from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";

const useStyles = makeStyles((theme) => ({
  root: {
    "& a": {
      textDecoration: "none",
    },
    "& .slick-initialized .slick-slide": { padding: "0 15px" },
    "& .slick-dots li.slick-active button": {
      background: "#37f",
      borderColor: "#37f",
    },
    "& .slick-dots li:hover button": {
      background: "#37f",
      borderColor: "#37f",
    },
    "& .slick-dots li button": {
      background: "#fff",
      display: "inline-block",
      width: "12px",
      height: "12px",
      borderRadius: "50%",
      opacity: 1,
      boxShadow: "none",
      transition: "background .5s",
      border: "2px solid #fff",
      padding: "0",
      margin: "0 6px 0 0",
      outline: "0",
      cursor: "pointer",
    },
    "& .slick-slider ul.slick-dots": { bottom: "0" },
    "& .slick-slider ul.slick-dots li": {
      width: "auto",
      height: "auto",
      margin: "0",
      padding: "0",
    },
    "& .slick-slider ul.slick-dots li button:before": { display: "none" },
    "& .slick-slider .slick-list": {
      paddingBottom: "3em",
      "@media (max-width: 1023.95px)": {
        paddingBottom: "30px",
      },
    },
  },
  imageroot: {
    display: "block",
    maxWidth: "100%",
    cursor: "pointer",
    flexBasis: "100%",
    height: "100%",
    objectFit: "cover",
    position: "relative",
    verticalAlign: "middle",
    marginLeft: "auto !important",
    "&:after": {
      content: '""',
      paddingBottom: "68.5%",
      position: "relative",
      display: "block",
      width: "100%",
    },
    // [theme.breakpoints.down("md")]: {
    //   "&:after": {
    //     paddingBottom: "63.5%",
    //   },
    // },
    [theme.breakpoints.down("xs")]: {
      "&:after": {
        paddingBottom: "63.5%",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "&:after": {
        paddingBottom: "63.5%",
      },
    },
  },
  images: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "0",
    right: "0",
    left: "0",
    bottom: "0",
    objectFit: "cover",
    objectPosition: "center",
    display: "inline-block",
    [theme.breakpoints.down("xs")]: {
      borderRadius: "4px",
    },
    [theme.breakpoints.down("sm")]: {
      borderRadius: "4px",
    },
  },
  titleContainer: {
    margin: "0",
    paddingTop: "20px",
    alignItems: "center",
    color: "#202f43",
    fontWeight: 700,
    [theme.breakpoints.down("xs")]: {
      textTransform: "uppercase",
    },
    [theme.breakpoints.down("sm")]: {
      textTransform: "uppercase",
    },
  },
  consultantTitle: {
    paddingBottom: "70px",
    paddingTop: "25px",
    paddingLeft: "15px",
    color: "#202f43",
    paddingBottom: "70px",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "20px",
      paddingLeft: "0",
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "20px",
      paddingLeft: "0",
    },
  },
  carouselItem: {
    padding: "0 15px",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  CarouselSlider: {
    paddingBottom: "0",
  },
  subTitle: {
    // marginLeft: "24px",
    fontWeight: 700,
    color: "#202f43",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "30px",
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "30px",
      width: "100%",
    },
  },
  carouselDot: {
    display: "none",
    // borderColor: "#b3bac4",
    // background: "#b3bac4",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "center",
      position: "absolute",
      bottom: 0,
    },
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      position: "absolute",
      bottom: 0,
    },
  },
  expertbox: {
    background: "#f2f3f8",
    paddingLeft: "105px",
    paddingRight: "105px",
    paddingBottom: "80px",
    paddingTop: "80px",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingTop: "60px",
      paddingBottom: "60px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingTop: "60px",
      paddingBottom: "60px",
    },
  },
  button: {
    borderRadius: "0",
    padding: "10px 20px",
    color: "#1948e8",
    borderColor: "#1948e8",
    borderWidth: "2px",
    backgroundColor: "transparent",
    marginTop: "24px",
    textTransform: "capitalize",
    [theme.breakpoints.down("xs")]: {
      padding: "6px 8px",
      marginTop: 0,
      margin: 0,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "6px 8px",
      marginTop: 0,
      margin: 0,
    },
    "&:hover": {
      boxShadow: "inset 0 0 0 2em #1948e8",
      color: "#fff",
      transition: ".6s",
    },
  },
  buttonContainer: {
    marginTop: "25px",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "encenterd",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  link: {
    textDecoration: "none",
  },
  gridcontainer: {
    display: "block",
    width: "100%",
  },
  slider: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "30px",
    },
  },
  // image: {
  //   padding: 0,
  //   textAlign: "center",
  //   display: "unset",
  //   [theme.breakpoints.down("md")]: {
  //     padding: "3em 1em 1em",
  //   },
  //   [theme.breakpoints.down("sm")]: {
  //     padding: "1.5em 0.5em 0.5em",
  //   },
  // },
  // )
}));

const Insights = (props) => {
  var settings = {
    // autoplaySpeed: 5000,
    speed: 500,
    autoplay: true,
    infinite: true,
    arrows: false,
    dots: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 3,
          // dots: true,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
          // dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
      {
        breakpoint: 786,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  };
  const { className, ...rest } = props;
  const classes = useStyles();

  const navigate = useNavigate();

  const GET_POSTS = gql`
    {
      posts(where: { orderby: { field: DATE, order: DESC } }, first: 6) {
        nodes {
          title
          uri
          slug
          featuredImage {
            node {
              id
              mediaDetails {
                file
              }
            }
          }
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_POSTS);
  if (loading) return <p>Loading Posts...</p>;
  if (error) return <p>An error occured!</p>;

  const rowMarkup = data.posts?.nodes.map((item, index) => {
    return (
      <>
        {item ? (
          <Grid container justifyContent="space-between" key={index}>
            <Grid
              item
              container
              justifyContent="flex-start"
              alignItems="center"
              xs={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.imageroot}
              onClick={() => navigate(item.slug)}
            >
              {item.featuredImage?.node?.mediaDetails.file ? (
                <>
                  <img
                    src={
                      "https://backend.apprato.com.au/wp-content/uploads/" +
                      item.featuredImage?.node?.mediaDetails.file
                    }
                    alt=""
                    className={classes.images}
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="2000"
                  />
                </>
              ) : (
                <>
                  <img
                    src="/images/placeholder.png"
                    className={classes.images}
                    alt="Jordan Pearce"
                  />
                </>
              )}
            </Grid>
            <Link to={item.slug}>
              <Grid
                item
                container
                spacing={2}
                className={classes.titleContainer}
              >
                <Typography variant="body1" className={classes.subTitle}>
                  {item.title ? item.title : ""}
                </Typography>
              </Grid>
            </Link>
          </Grid>
        ) : (
          ""
        )}
      </>
    );
  });

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
      name="about"
      id="about"
    >
      <div className={classes.expertbox}>
        <Grid container justify="space-between">
          <Typography className={classes.consultantTitle} variant="h4">
            Latest Insights.
          </Typography>
        </Grid>
        {rowMarkup ? <Slider {...settings}>{rowMarkup}</Slider> : ""}
        <Grid container className={classes.buttonContainer} justify="center">
          <Button
            variant="outlined"
            className={classes.button}
            // href="/blog"
            style={{ backgroundColor: "transparent", width: "202px" }}
            onClick={() => navigate("/blog")}
          >
            View Our Insights
          </Button>
        </Grid>
      </div>
    </div>
  );
};

Insights.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Insights;
