import React, { useLayoutEffect } from "react";
import { useLocation  } from "react-router-dom";

export default function ScrollToTop() {
  const location = useLocation();
  const pathname = location.pathname;
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
