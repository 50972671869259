import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Section } from "../../components/organisms";
import {
  Header,
  ContactForm,
  CreativeWork,
  DevelopmentWork,
  OurClients
} from "./components";
import { gql } from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import Cookies from "js-cookie";
import { Context, description, thumbnail, title } from "../../Context";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor:"#fff",
  },
  headerPaddingTop: {
    backgroundColor: "#f9f9f9",
    // backgroundImage: "url(`${}`)",
    paddingTop: "122.23px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    paddingBottom: "220px",
    paddingTop: "122px",
    paddingLeft: "120px",
    paddingRight: "120px",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingBottom: 0,
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "240px",
      backgroundPosition: "82% 93%",
      backgroundSize: "150%",
      backgroundRepeat: "no-repeat",
    },
  },

  CreativeWorkPaddingTop: {
    padding:0
  },
  DevelopmentWorkPaddingTop: {
    padding:0
  },
  contactBottomPaddingTop: {
    backgroundColor: "#f9f9f9",
    paddingLeft: "90px",
    paddingRight: "90px",
    paddingTop: "90px",
    paddingBottom: "60px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  },
}));

const OurWork = () => {
  const classes = useStyles();
  
  const menuId = useContext(Context);
  // const menuId = JSON.parse(localStorage.getItem("menuId"));
  // const menuId = useSelector(state => state.storagedata.menuId);
  // const menuId = Cookies.get('menuId')
  //if (menuId) {
  var GET_PAGE = gql`
    {
      menuItem(id:  ${menuId}, idType: DATABASE_ID) {
        id
        label
        connectedNode {
          node {
            ... on Page {
              id
              globalOurClient {
                typesOfClient{
                 clientImage{
                   mediaItemUrl
                 }
               }
              }
              featuredImage {
                node {
                  mediaItemUrl
                }
              }
              content(format: RENDERED)
              metaDetails {
                customMetaDescription
                customMetaTitle
              }
            }
          }
        }
        childItems {
          edges {
            node {
              id
              label
              databaseId
              connectedNode {
                node {
                  ... on Page {
                    id
                    featuredImage {
                      node {
                        mediaItemUrl
                      }
                    }
                    uri
                    caseStudies {
                      framework
                      workType
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
  //}

  const { loading, error, data } = useQuery(GET_PAGE);
  var pageContent = data?.menuItem.connectedNode?.node;
  const casestudies = data?.menuItem.childItems.edges;
  const metaDetails =data?.menuItem.connectedNode?.node?.metaDetails;
  const featuredImage =data?.menuItem.connectedNode?.node?.featuredImage;
  var pageTitle = data?.menuItem?.label;

  // const handleClick = () => {
  //   window.history.back();
  // };
  const helemetMeta = (
    <Helmet>
    <title>{pageTitle}</title>
    <meta name="description" content={`${metaDetails && metaDetails.customMetaDescription !== null ? metaDetails.customMetaDescription : description  }`} />
    <meta name="title" property="og:title" content={`${metaDetails && metaDetails.customMetaTitle !== null ? metaDetails.customMetaTitle : title  }`} />
    <meta name="description" property="og:description" content={`${metaDetails && metaDetails.customMetaDescription !== null ? metaDetails.customMetaDescription : description  }`} />
    <meta name="image" property="og:image" content={`${featuredImage && featuredImage !== null ? featuredImage.node.mediaItemUrl : thumbnail  }`} />
  </Helmet>
  )

  // const handleClick = () => {
  //   window.history.back();
  // };

  return (
    <div className={classes.root}>
      {helemetMeta}
      <Section className={classes.headerPaddingTop}>
        <Header post={pageContent} />
      </Section>
      <Section className={classes.DevelopmentWorkPaddingTop}>
        <DevelopmentWork casestudies={casestudies}/>
      </Section>
      <Section className={classes.CreativeWorkPaddingTop}>
        <CreativeWork  casestudies={casestudies}/>
      </Section>
      <Section className={classes.sectionNoPaddingTop}>
        <OurClients post={pageContent}/>
      </Section>
      <Section className={classes.contactBottomPaddingTop}>
       	<ContactForm />
      </Section>
    </div>
  );
};

export default OurWork;
