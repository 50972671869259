import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Section } from "../../components/organisms";
import { Header, Body, RelatedPosts ,
   ContactForm
  } from "./components";
import { gql } from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { description, thumbnail, title } from "../../Context";
import  cheerio  from "cheerio";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    backgroundColor:"#fff"
  },
  // pagePaddingTop: {
  //   paddingTop: theme.spacing(3),
  //   [theme.breakpoints.up("md")]: {
  //     paddingTop: theme.spacing(5),
  //   },
  // },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
  pagePaddingTop: {
    paddingTop: 0,
    paddingLeft: "120px",
    paddingRight: "120px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    // paddingBottom: "40px",
    // paddingLeft: theme.spacing(0),
  },
  headerPaddingTop: {
    backgroundColor: "#f9f9f9",
    paddingTop: "122.23px",
    paddingBottom: "300px",
    paddingTop: "122px",
    paddingLeft: "120px",
    paddingRight: "120px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingBottom: 0,
    },
  },
  bodyPaddingTop: {
    marginTop: "-250px",
    paddingLeft: "120px",
    paddingRight: "120px",
    paddingTop: 0,
    paddingLeft: "120px",
    paddingRight: "120px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
      marginTop: "20px",
    },
    // [theme.breakpoints.down("md")]: {
    //   maxWidth: "50%",
    //   flexBasis: "50%",
    //   justifyContent: "flex-end !important"
    // },
    // [theme.breakpoints.down("sm")]: {
    //   maxWidth: "50%",
    //   flexBasis: "50%",
    //   justifyContent: "flex-end !important"
    // },
  },
  contactBottomPaddingTop: {
    backgroundColor: "#f9f9f9",
    paddingLeft: "90px",
    paddingRight: "90px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  },
  RelatedPostsPaddingTop: {
    paddingTop: "14px",
    paddingLeft: 0,
    paddingRight: 0,
  },
  shape: {},
}));

const BlogListing = () => {
  const classes = useStyles();
  const { slug } = useParams();;
  // var path = location.pathname.split("/")[3];
  const GET_POST = gql`{
    post(
      id: "${slug}"
      idType: SLUG
    ) {
      title
      featuredImage {
        node {
          id
          link
          mediaDetails {
            file
          }
        }
      }
      author {
        node {
          firstName
          lastName
          name
        }
      }
      uri
      slug
      date
      content
      categories(first: 1) {
        edges {
          node {
            id
            slug
            name
          }
        }
      }
    }
  }`;

  const { loading, error, data } = useQuery(GET_POST);
  const navigate = useNavigate();

  useEffect(() => {
    if (data?.post === null) {
      navigate("/notfound", { replace: true });
    }
  }, [data, navigate]);

  if (loading) return <p>Loading Posts...</p>;
  // if (error) return <p>An error occured!</p>;
  const featuredImage =data?.post?.featuredImage;

  function removeHTML(text , wordLimit) {
    const $ = cheerio.load(text);
  const originalText = $.root().text();
  const words = originalText.split(" ");
  if (words.length <= wordLimit) {
    return originalText;
  }
  const truncatedWords = words.slice(0, wordLimit);
  const truncatedText = truncatedWords.join(" ");
  return truncatedText;
  }

  const metadescription =  data?.post?.content 
  const metatitle = data?.post?.title
  return (
    <div className={classes.root}>
      <Helmet>
        <title>{metatitle}</title>
        <meta name="description" content={`${metadescription && metadescription !== null ? removeHTML(metadescription , 45) : description  }`} />
        <meta name="title" property="og:title" content={`${metatitle && metatitle !== null ? metatitle : title  }`} />
        <meta name="description" property="og:description" content={`${metadescription && metadescription !== null ? removeHTML(metadescription , 45) : description  }`} />
        <meta name="image" property="og:image" content={`${featuredImage && featuredImage !== null ? "https://backend.apprato.com.au/wp-content/uploads/"+featuredImage.node.mediaDetails.file : thumbnail  }`} />
      </Helmet>
      <Section className={classes.headerPaddingTop}>
        <Header post={data.post} />
      </Section>
      <Section className={classes.bodyPaddingTop}>
        <Body post={data.post} />
      </Section>
      <Section className={classes.RelatedPostsPaddingTop}>
        <RelatedPosts post={data.post} />
      </Section>
      <Section className={classes.contactBottomPaddingTop}>
        <ContactForm />
      </Section>
    </div>
  );
};

export default BlogListing;
