import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { gql } from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

const useStyles = makeStyles((theme) => ({
  root: {
    "& h6": {
      "@media (min-width:1280px)": {
        fontSize: "1.5rem",
      },
      "@media (min-width: 960px)": {
        fontSize: "22px",
      },
    },
    "& .slick-initialized .slick-slide": { padding: "0 15px" },
    "& .slick-dots li.slick-active button": {
      background: "#37f",
      borderColor: "#37f",
    },
    "& .slick-dots li:hover button": {
      background: "#37f",
      borderColor: "#37f",
    },
    "& .slick-dots li button": {
      background: "#fff",
      display: "inline-block",
      width: "12px",
      height: "12px",
      borderRadius: "50%",
      opacity: 1,
      boxShadow: "none",
      transition: "background .5s",
      border: "2px solid #fff",
      padding: "0",
      margin: "0 6px 0 0",
      outline: "0",
      cursor: "pointer",
    },
    "& .slick-slider ul.slick-dots": { bottom: "0" },
    "& .slick-slider ul.slick-dots li": {
      width: "auto",
      height: "auto",
      margin: "0",
      padding: "0",
    },
    "& .slick-slider ul.slick-dots li button:before": { display: "none" },
    "& .slick-slider .slick-list": {
      paddingBottom: "3em",
      "@media (max-width: 1023.95px)": {
        paddingBottom: "30px",
      },
    },
  },
  imageroot: {
    display: "block",
    cursor: "pointer",
    maxWidth: "100%",
    flexBasis: "100%",
    height: "100%",
    objectFit: "cover",
    position: "relative",
    verticalAlign: "middle",
    marginLeft: "auto !important",
    "&:after": {
      content: '""',
      paddingBottom: "68.5%",
      position: "relative",
      display: "block",
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      "&:after": {
        paddingBottom: "90.5%",
      },
    },
    [theme.breakpoints.down("xs")]: {
      "&:after": {
        paddingBottom: "90.5%",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "&:after": {
        paddingBottom: "90.5%",
      },
    },
  },
  images: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "0",
    right: "0",
    left: "0",
    bottom: "0",
    objectFit: "cover",
    objectPosition: "center",
    display: "inline-block",
    [theme.breakpoints.down("xs")]: {
      borderRadius: "4px",
    },
    [theme.breakpoints.down("sm")]: {
      borderRadius: "4px",
    },
  },
  titleContainer: {
    margin: "0",
    paddingTop: "20px",
    cursor: "pointer",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      textTransform: "uppercase",
    },
    [theme.breakpoints.down("sm")]: {
      textTransform: "uppercase",
    },
  },
  consultantTitle: {
    paddingBottom: "20px",
    paddingLeft: "15px",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "20px",
      paddingLeft: "0",
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "20px",
      paddingLeft: "0",
    },
    "@media (min-width: 960px)": {
      fontSize: "2.5625rem",
      paddingBottom: "50px !important",
    },
  },
  carouselItem: {
    padding: "0 15px",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  CarouselSlider: {
    paddingBottom: 0,
  },
  slider: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "30px",
    },
  },
  subTitle: {
    marginLeft: "24px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "14px",
    },
  },
  carouselDot: {
    // [theme.breakpoints.up("xs")]: {
    //   display: "flex",
    //   justifyContent: "center",
    //   position: "absolute",
    //   bottom: 0,
    //   marginBottom: "30px",
    // },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      position: "absolute",
      bottom: "0 !important",
    },
  },
  expertbox: {
    background: "#4b69d6",
    paddingLeft: "105px",
    paddingRight: "105px",
    paddingTop: "80px",
    paddingBottom: "80px",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
      backgroundColor: "#1948e8",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
      backgroundColor: "#1948e8",
    },
  },
  button: {
    borderRadius: "0",
    padding: "10px 20px",
    color: "#fff",
    borderColor: "#fff",
    borderWidth: "2px",
    backgroundColor: "transparent",
    marginTop: "34px",
    [theme.breakpoints.down("xs")]: {
      padding: "6px 8px",
      margin: 0,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "6px 8px",
      margin: 0,
    },
    "&:hover": {
      boxShadow: "inset 0 0 0 2em #1948e8",
      color: "#fff",
      transition: ".6s",
    },
  },
  buttonContainer: {
    marginTop: "34px",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  typoh6: {},
  // image: {
  //   padding: 0,
  //   textAlign: "center",
  //   display: "unset",
  //   [theme.breakpoints.down("md")]: {
  //     padding: "3em 1em 1em",
  //   },
  //   [theme.breakpoints.down("sm")]: {
  //     padding: "1.5em 0.5em 0.5em",
  //   },
  // },
  // )
}));

const Work = (props) => {
  var settings = {
    // autoplaySpeed: 5000,
    speed: 500,
    autoplay: true,
    infinite: true,
    arrows: false,
    dots: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 2,
          dots: true,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
      {
        breakpoint: 786,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  };

  const navigate = useNavigate();
  const GET_MENU = gql`
    {
      menuItem(id: "2122", idType: DATABASE_ID) {
        id
        label
        childItems {
          edges {
            node {
              id
              label
              databaseId
              connectedNode {
                node {
                  ... on Page {
                    id
                    featuredImage {
                      node {
                        mediaItemUrl
                      }
                    }
                    uri
                    caseStudies {
                      framework
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_MENU);
  //const casestudies = data?.menuItem.childItems.edges;

  const casestudies = data?.menuItem?.childItems.edges.filter(
    (edge) => edge?.node?.connectedNode != null
  );

  console.log(casestudies,'casestudies')
  const { className, ...rest } = props;
  const classes = useStyles();
  const rowMarkup = casestudies?.map((item, index) => {
    return (
      <>
        {item ? (
          <Grid container justify="space-between">
            <Grid
              item
              container
              justify="flex-start"
              alignItems="center"
              xs={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.imageroot}
              onClick={() => navigate(item.node.connectedNode.node.uri)}
            >
              {item?.node?.connectedNode?.node?.featuredImage ? (
                <>
                  <img
                    src={
                      item?.node?.connectedNode?.node?.featuredImage?.node
                        ?.mediaItemUrl
                    }
                    alt=""
                    className={classes.images}
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="2000"
                  />
                </>
              ) : (
                <>
                  <img
                    src="/images/placeholder.png"
                    className={classes.images}
                    alt="Jordan Pearce"
                  />
                </>
              )}
            </Grid>

            <Grid
              item
              container
              spacing={2}
              className={classes.titleContainer}
              onClick={() => navigate(item.node.connectedNode.node.uri)}
            >
              <Typography
                variant="h6"
                style={{ color: "#fff" }}
                className={classes.typoh6}
              >
                {item.node.label}
              </Typography>
              <Typography
                variant="body1"
                className={classes.subTitle}
                style={{ color: "#fff" }}
              >
                {item?.node?.connectedNode?.node?.caseStudies?.framework}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          ""
        )}
      </>
    );
  });

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
      name="about"
      id="about"
    >
      <div className={classes.expertbox}>
        <Grid container justify="space-between">
          <Typography
            className={classes.consultantTitle}
            variant="h4"
            style={{ color: "#fff" }}
          >
            Our Work
          </Typography>
        </Grid>

        {rowMarkup ? <Slider {...settings}>{rowMarkup}</Slider> : ""}

        <Grid container className={classes.buttonContainer} justify="center">
          <Button
            variant="outlined"
            className={classes.button}
            style={{ backgroundColor: "transparent", width: "202px" }}
            onClick={() => navigate("/ourwork/acer/")}
          >
            View Our Work
          </Button>
        </Grid>
      </div>
    </div>
  );
};

Work.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Work;
