import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Section } from "../../components/organisms";
import {
  Body,
  Experts,
  Platforms,
  Hero,
  CreativeHero,
  ContactForm,
} from "./components";
import {
  AuditBody,
  AuditHero,
  AuditServices,
  AuditGrowth,
  AuditHelp,
  AuditFaq,
} from "./components/Auditcomponents";

import { gql } from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { useLocation } from "react-router-dom";
import { Context, description, thumbnail, title } from "../../Context";
import { Helmet } from "react-helmet";
//import { sas1, sas2, sas3, sas4, sas5 } from "./data";

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 *
 */

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    backgroundColor: "#fff",
  },
  sectionPaddingTop: {
    backgroundColor: "#f2f3f8",
    paddingTop: "40px",
    paddingBottom: "40px",
    paddingLeft: 0,
    paddingRight: 0,
  },
  sectionPaddingTops: {
    backgroundColor: "#f2f3f8",
    paddingTop: "40px",
    paddingBottom: "100px",
    paddingLeft: 0,
    paddingRight: 0,
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
  pagePaddingTop: {
    paddingTop: 0,
    paddingLeft: "120px",
    paddingRight: "120px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    // paddingBottom: "40px",
    // paddingLeft: theme.spacing(0),
  },
  headerPaddingTopCustom: {
    paddingTop: 0,
    paddingLeft: 0,
    paddingBottom: "40px",
    paddingRight: 0,
    paddingLeft: theme.spacing(0),
    [theme.breakpoints.up("md")]: {
      padding: 0,
      paddingTop: 0,
      paddingLeft: 0,
      paddingBottom: "40px",
    },
  },
  creativeheaderPaddingTopCustom: {
    paddingTop: 0,
    paddingLeft: 0,
    paddingBottom: "40px",
    paddingRight: 0,
    paddingLeft: theme.spacing(0),
    [theme.breakpoints.up("md")]: {
      padding: 0,
      paddingTop: 0,
      paddingLeft: 0,
      paddingBottom: "40px",
    },
  },
  headerPaddingTop: {
    backgroundColor: "#f9f9f9",
    paddingTop: "122.23px",
    paddingBottom: "60px",
    paddingTop: "122px",
    paddingLeft: "120px",
    paddingRight: "120px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingBottom: 0,
    },
  },
  bodyPaddingTop: {
    marginTop: "-250px",
    paddingLeft: "120px",
    paddingRight: "120px",
    paddingTop: 0,
    paddingLeft: "120px",
    paddingRight: "120px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
      marginTop: "20px",
    },
  },
  contactBottomPaddingTop: {
    paddingLeft: "90px",
    paddingRight: "90px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  },
  RelatedPostsPaddingTop: {
    paddingTop: "14px",
    paddingLeft: 0,
    paddingRight: 0,
  },
  shape: {},
}));

const Services = () => {
  const menuId = useContext(Context);
  //if (menuId) {
  var GET_PAGE = gql`
    {
      menuItem(id: ${menuId}, idType: DATABASE_ID) {
        id
        label
        connectedNode {
          node {
            ... on Page {
              content(format: RENDERED)
              featuredImage {
                node {
                  mediaItemUrl
                }
              }
              services {
                fieldGroupName
                homePageContent
                backgroundColor
                color
                typesOfServices {
                  color
                  nameOfService
                  fieldGroupName
                  serviceInfo
                  serviceImageAfterTitle
                  serviceImage {
                    mediaItemUrl
                  }
                }
              }
              auditservices {
                backgroundColor
                color
                fieldGroupName
                serviceAuditDescription
                serviceAuditTitle
                typesOfServicesAudit {
                  fieldGroupName
                  nameOfServiceAudit
                  serviceAuditColor
                  serviceAuditImageAfterTitle
                  serviceAuditInfo
                  serviceAuditImage {
                    mediaItemUrl
                  }
                }
              }
              serviceappratohelp{
                backgroundColor
                color
                fieldGroupName
                serviceAppratoHelpDescription
                serviceAppratoHelpTitle
                typesOfServicesAppratoHelp{
                  fieldGroupName
                  nameOfServiceAppratoHelp
                  serviceAppratoHelpInfo
                  serviceAppratoHelpImage{
                    mediaItemUrl
                  }
                  serviceAppratoHelpImageAfterTitle
                }
              }
              servicefaq{
                faqTitle
                servicesFaq{
                  faqQuestion
                  faqAnswer
                }
              }
              metaDetails {
                customMetaDescription
                customMetaTitle
              }
            }
          }
        }
      }
    }
  `;
  //}
  const { loading, error, data } = useQuery(GET_PAGE);
  var pageContent = data?.menuItem.connectedNode;

  var headingTopColor = "#f9f9f9";
  if (pageContent?.node?.services?.backgroundColor) {
    headingTopColor = pageContent?.node?.services?.backgroundColor;
  }
  const classes = useStyles();

  const location = useLocation();
  var page = location.pathname.replace(/\/+$/, "");

  const metaDetails = data?.menuItem.connectedNode?.node?.metaDetails;
  const featuredImage = data?.menuItem.connectedNode?.node?.featuredImage;

  var pageTitle = data?.menuItem?.label;

  const helemetMeta = (
    <Helmet>
      <title>{pageTitle}</title>
      <meta
        name="description"
        content={`${
          metaDetails && metaDetails.customMetaDescription !== null
            ? metaDetails.customMetaDescription
            : description
        }`}
      />
      <meta
        name="title"
        property="og:title"
        content={`${
          metaDetails && metaDetails.customMetaTitle !== null
            ? metaDetails.customMetaTitle
            : title
        }`}
      />
      <meta
        name="description"
        property="og:description"
        content={`${
          metaDetails && metaDetails.customMetaDescription !== null
            ? metaDetails.customMetaDescription
            : description
        }`}
      />
      <meta
        name="image"
        property="og:image"
        content={`${
          featuredImage && featuredImage !== null
            ? featuredImage.node.mediaItemUrl
            : thumbnail
        }`}
      />
    </Helmet>
  );

  if (
    page == "/services/review-upgrade-audit" ||
    page == "/services/review-upgrade-audit/"
  ) {
    return (
      <div className={classes.root}>
        {helemetMeta}
        <Section
          className={classes.headerPaddingTopCustom}
          style={{ paddingBottom: `0` }}
        >
          <AuditBody loading={loading} pageContent={pageContent} />
        </Section>
        <Section
          className={classes.creativeheaderPaddingTopCustom}
          style={{ paddingBottom: `0` }}
        >
          <AuditHero loading={loading} pageContent={pageContent} />
        </Section>
        <Section
          className={classes.creativeheaderPaddingTopCustom}
          style={{ paddingBottom: `0` }}
        >
          <AuditServices loading={loading} pageContent={pageContent} />
        </Section>
        <Section
          className={classes.creativeheaderPaddingTopCustom}
          style={{ paddingBottom: `0` }}
        >
          <AuditGrowth loading={loading} pageContent={pageContent} />
        </Section>
        <Section
          className={classes.creativeheaderPaddingTopCustom}
          style={{ paddingBottom: `0` }}
        >
          <AuditHelp loading={loading} pageContent={pageContent} />
        </Section>
        <Section
          className={classes.creativeheaderPaddingTopCustom}
          style={{ paddingBottom: `0` }}
        >
          <AuditFaq loading={loading} pageContent={pageContent} />
        </Section>
        <Section className={classes.sectionMarginBottom}>
          <ContactForm />
        </Section>
      </div>
    );
  } else if (page == "/services/custom-app" || page == "/services/custom-app") {
    return (
      <div className={classes.root}>
        {helemetMeta}
        <Section className={classes.headerPaddingTopCustom}>
          <Hero loading={loading} pageContent={pageContent} />
        </Section>
        <Section className={classes.sectionMarginBottom}>
          <ContactForm />
        </Section>
      </div>
    );
  } else if (page == "/services/creative" && page == "/services/creative") {
    return (
      <div className={classes.root}>
        {helemetMeta}
        <Section className={classes.creativeheaderPaddingTopCustom}>
          <CreativeHero loading={loading} pageContent={pageContent} />
        </Section>
        <Section className={classes.sectionMarginBottom}>
          <ContactForm />
        </Section>
      </div>
    );
  } else {
    return (
      <div className={classes.root}>
        {helemetMeta}
        <Section
          className={classes.headerPaddingTop}
          style={{ backgroundColor: `${headingTopColor}` }}
        >
          <Body loading={loading} pageContent={pageContent} />
        </Section>
        <Section className={classes.sectionPaddingTop}>
          <Experts />
        </Section>
        <Section className={classes.sectionPaddingTops}>
          <Platforms />
        </Section>
        <Section className={classes.sectionMarginBottom}>
          <ContactForm />
        </Section>
      </div>
    );
  }
};

// InnerPage.propTypes = propTypes;
// InnerPage.defaultProps = defaultProps;
// #endregion

export default Services;
