import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { gql } from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  container: {
    background: "white",
    paddingLeft: "105px",
    paddingRight: "105px",
    paddingBottom: "80px",
    paddingTop: "80px",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingTop: "60px",
      paddingBottom: "60px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingTop: "60px",
      paddingBottom: "60px",
    },
  },
  title: {
    paddingBottom: "20px",
    color: "#202f43",
    fontWeight: 700,
  },
  imageContainer: {
    width: "50%",
    [theme.breakpoints.up("sm")]: {
      width: "20%",
    },
    float: "left",
    padding: "10px",
    boxSizing: "border-box",
    textAlign: "center", 
    display: "flex", 
    justifyContent: "center",
    alignItems: "center", 
  },
  image: {
    maxWidth: "100%", 
    maxHeight: "150px", 
    display: "inline-block", 
    verticalAlign: "middle", 
    position: "absolute",
      top: "0",
      right: "0",
      left: '0',
      bottom: "0",
      objectFit: "contain",
      objectPosition: "center",
      width: "auto",
      height: "auto",
      maxWidth: "100%",
      maxHeight: "100%",
      display: "inline-block",
      margin: "auto",
  },
  ourclient:
  {
    display: "inline-block",
    width: "100%",
    height: "100%",
    maxWidth: "100%",
    objectFit: "cover",
    position: "relative",
    verticalAlign: "middle",
    marginRight: "auto !important",
    overflow: "hidden",
    margin: "0 40px !important",
    "&::after": {
      paddingBottom: "45%",
      content: '""',
      position: "relative",
      display: "block",
      width: "100%",
      overflow: "hidden",
    },
    "@media only screen and (max-width:767px)": {
      margin: "0 20px !important",
    },
    "@media only screen and (max-width: 767px)": {
      margin: "0 10px !important",
   }
  }
}));



const OurClients = () => {

    const GET_MENU = gql`
    {
      menuItem(id: "2713", idType: DATABASE_ID) {
        id
        label
        globalOurClient 
        {
             typesOfClient{
              clientImage{
                mediaItemUrl
              }
            }
           }
      }
    }
    `;
    const { loading, error, data } = useQuery(GET_MENU);
    const post = data?.menuItem;
    const classes = useStyles();

  const images = [
    "kathmandu2-min.png",
    "image 4-min.png",
    "team buys done-min.png",
    "ryobi2-min.png",
    "advanture2-min.png",
    "one keeloo doneee-min.png",
    "creswick done-min.png",
    "cart sshare donnn-min.png",
    "ouesland done4-min.png",
    "nsw done2-min.png",
    "plaries done-min.png",
    "farm2 done-min.png",
    "adorn done4-min.png",
    "victoria done-min.png",
    "acer done-min.png",
    "aquiladone-min.png",
    "aeg done-min.png",
    "coram done-min.png",
    "bed bath done-min.png",
    "struck done-min.png",
  ];

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography className={classes.title} variant="h4">
          Clients we've worked with
        </Typography>
        <Grid container spacing={2}>
        {post?.globalOurClient?.typesOfClient?.map((image, index) => (
      
            <Grid key={index} className={classes.imageContainer}>
             <div className={classes.ourclient}>
                <img
                src={image?.clientImage?.mediaItemUrl}
                alt={`Image ${index + 1}`}
                className={classes.image}
                />
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default OurClients;
