import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, TextField, Button, Hidden } from "@material-ui/core";
import { SectionHeader } from "../../../../components/molecules";
import { Description } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    // "& input#filled-search": {
    //   color: "#0e44ff",
    //   padding: "0 30px",
    //   [theme.breakpoints.down("sm")]: {
    //     padding: 0,
    //   },
    // },
    "& .MuiInputBase-root.MuiInput-root.makeStyles-textfield-57.MuiInputBase-fullWidth.MuiInput-fullWidth.MuiInputBase-formControl.MuiInput-formControl": {
      borderBottom: "2px solid #0e44ff",
    },
    "& .MuiInputBase-root.MuiInput-root.MuiInputBase-fullWidth.MuiInput-fullWidth.MuiInputBase-formControl.MuiInput-formControl ": {
      borderBottom: "2px solid",
    },
    "& .kg-what-we-area": {
      marginTop: 0,
      "@media only screen and (max-width:1023px)": {
        marginTop: "50px",
      },
      // paddingBottom: "360px",
      // [theme.breakpoints.down("sm")]: {
      //   fontSize: "16px",
      //   marginTop: "80px",
      //   // paddingBottom: "20px",
      // },
    },
    "& .kg-main-area .kg-left-area h2": {
      marginTop: "0",
      fontSize: "34px",
      lineHeight: "40px",
      marginBottom: "36px",
    },
    "& .kg-main-area .kg-left-area p": {
      fontWeight: "600",
      fontSize: "23px",
      lineHeight: "32px",
      margin: "0",
      "@media only screen and (max-width:1023px)": {
        fontSize: "16px",
        lineHeight: "26px",
      },
      "@media only screen and (max-width:785px)": {
        marginBottom: "20px"
      },
    },
    "& .kg-what-we-area .kg-main-area": {
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        flexWrap: "wrap",
      },
    },
    "& .kg-what-we-area .kg-left-area": {
      width: "100%",
      maxWidth: "455px",
      float: "left",
      "@media only screen and (max-width:785px)": {
        maxWidth: "100%",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    "& .kg-what-we-area .kg-right-content": {
      width: "65%",
      float: "left",
      paddingLeft: "50px",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 0,
        width: "100%",
      },
    },
    "& .kg-what-we-area .kg-right-content ul li ": {
      fontSize: "18px",
      marginBottom: "12px",
      lineHeight: "24px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "16px",
        marginBottom: "10px",
        lineHeight: "22px",
      },
    },
    "& .kg-what-we-area .kg-right-content ul": {
      margin: 0,
    },
    "& .kg-hero-content-area .kg-inner-hero-content p.kg-full-conten": {
      fontSize: "23px",
    },
    "& .kg_images figure": {
      height: "100%",
      display: "block",
      position: "relative",
      objectFit: "cover",
      // marginLeft: "auto !important",
      verticalAlign: "middle",
      maxWidth: "100%",
      marginRight: 0,
      marginLeft: "60px",
      marginTop: 0,
      // margin: "0",
      "&:after": {
        width: "100%",
        content: '""',
        display: "block",
        position: "relative",
        paddingBottom: "55%",
      },
      "@media (max-width: 1023px)": {
        marginLeft: 0,
      },
      "@media (max-width: 991px)": {
        marginLeft: 0,
      },
    },
    "& .kg-hero-content-area": {
      position: "relative",
      top: "-50px",
    },
    "& .kg-hero-content-area .kg-inner-hero-content": {
      maxWidth: "715px",
    },
    "& .kg-body-image": {
      display: "block",
      maxWidth: "100%",
      height: "100%",
      objectFit: "cover",
      position: "relative",
      margin: "auto",
      width: "100%",
      marginLeft: "60px",
      "@media (max-width: 1023px)": { 
        marginLeft: "30px"
      },
      "@media (max-width: 785px)": { 
        marginLeft: 0
      }
    },
    "& .kg-body-image:after": {
      paddingBottom: "25%",
      content: '""',
      position: "relative",
      display: "block",
      width: "100%",
    },
  },
  reversemob: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  divImage: {
    display: "block",
    height: "100%",
    objectFit: "cover",
    position: "relative",
    verticalAlign: "middle",
    marginLeft: "auto !important",
    "& img": {
      "@media (max-width:1024px)": {
        objectFit: "contain",
      },
    },
    "&:after": {
      "@media (max-width:767px)": {
        paddingBottom: "55%",
      },
      paddingBottom: "144%",
      content: '""',
      position: "relative",
      display: "block",
      width: "100%",
      "@media (max-width: 1023px)": {
        paddingBottom: "55%",
        paddingBottom: "80% !important"
      },
      "@media (max-width:991px)": {
        paddingBottom: "55%",
      },
    },
  },
  banner: {
    height: "100%",
    width: "100%",
    position: "absolute",
    top: "62%",
    right: 0,
    left: "50%",
    bottom: 0,
    objectFit: "cover",
    objectPosition: "center",
    width: "100%",
    height: "100%",
    display: "inline-block",
    WebkitTransform: "translate(-50%,-75%)",
    transform: "translate(-53%,-75%)",
  },
  bannerGrid: {
    maxWidth: "1000px",
  },
  imageBody: {
    display: "flex",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      width: "100%",
      margin: "auto",
      order: 2,
    },
    "@media (max-width: 991px) ": {
      order: 2,
    },
    "@media (max-width: 767px)": {
      order: 2,
    },
  },
  mainImageDiv: {
    width: "100%",
    position: "relative",
    top: "-5%",
  },
  banner1: {
    top: "0",
    left: "0",
    right: "0",
    width: "100%",
    bottom: "0",
    height: "100%",
    display: "inline-block",
    position: "absolute",
    objectFit: "cover",
    objectPosition: "center",
  },
  imageBody2: {
    // paddingTop: "11%",
    "@media (max-width: 1023px) ": {
      order: 1,
      maxWidth: "100%",
    },
    "@media (max-width: 991px)": {
      maxWidth: "100%",
      order: 1,
    },
    "@media (max-width: 767px)": {
      maxWidth: "100%",
      order: 1,
    },
  },
  banner2: {
    position: "absolute",
    top: "0",
    right: "0",
    left: "0",
    bottom: "0",
    objectFit: "cover",
    objectPosition: "center",
    width: "100%",
    height: "100%",
    display: "inline-block",
  },
}));

const TafeNswBody = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const post = props.post;
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Grid
        container
        justify="space-between"
        className={classes.reversemob + " " + "kg-tafe"}
      >
        {/* <Grid
          item
          alignItems="center"
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          data-aos={"fade-up"}
        > */}
        <Grid
          item
          alignItems="center"
          xs={12}
          sm={4}
          md={4}
          lg={4}
          xl={4}
          data-aos={"fade-up"}
          className={classes.imageBody}
        >
          <Grid
            item
            alignItems="center"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            data-aos={"fade-up"}
            className={classes.bannerGrid}
          >
            <Hidden smDown>
              <div className={classes.divImage}>
                {post?.bodyimage?.mediaItemUrl ? (
                  <img
                    className={classes.banner}
                    src={post?.bodyimage?.mediaItemUrl}
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="2000"
                  />
                ) : (
                  <Skeleton
                    variant="rectangular"
                    className={classes.banner}
                    animation="wave"
                  />
                )}
              </div>
            </Hidden>
            <Hidden mdUp>
              <div className={classes.divImage}>
                {post?.mobileimage?.mediaItemUrl ? (
                  <img
                    className={classes.banner}
                    src={post?.mobileimage?.mediaItemUrl}
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="2000"
                  />
                ) : (
                  <Skeleton
                    variant="rectangular"
                    className={classes.banner}
                    animation="wave"
                  />
                )}
              </div>
            </Hidden>
          </Grid>
        </Grid>
        <Grid
          container
          item
          alignItems="center"
          xs={12}
          sm={8}
          md={8}
          lg={8}
          xl={8}
          data-aos={"fade-up"}
          className={classes.imageBody2}
        >
          <div className={classes.mainImageDiv + "  " + `kg_images`}>
            <figure>
              <img
                className={classes.banner1}
                src="/images/BE.jpg"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
              />{" "}
            </figure>
          </div>
        </Grid>
        {/* </Grid> */}
      </Grid>
      <Grid
        item
        alignItems="center"
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        data-aos={"fade-up"}
        className={classes.imageBody}
      >
        <Grid
          item
          alignItems="center"
          xs={12}
          sm={4}
          md={4}
          lg={4}
          xl={4}
          data-aos={"fade-up"}
          className={`kg-content`}
        >
          <div class="kg-what-we-area">
            <div
              dangerouslySetInnerHTML={{
                __html: post?.bodycontent,
              }}
            />
          </div>
        </Grid>
        <Grid
          container
          item
          alignItems="center"
          xs={12}
          sm={8}
          md={8}
          lg={8}
          xl={8}
          data-aos={"fade-up"}
          className={`kg-image`}
        >
          <div className="kg-body-image">
            <img
              className={classes.banner2}
              src="/images/Layer_195.jpg"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

TafeNswBody.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default TafeNswBody;
