import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, TextField, Button, Hidden } from "@material-ui/core";
import { SectionHeader } from "../../../../components/molecules";
import { Description } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    // "& input#filled-search": {
    //   color: "#0e44ff",
    //   padding: "0 30px",
    //   [theme.breakpoints.down("sm")]: {
    //     padding: 0,
    //   },
    // },
    "& .MuiInputBase-root.MuiInput-root.makeStyles-textfield-57.MuiInputBase-fullWidth.MuiInput-fullWidth.MuiInputBase-formControl.MuiInput-formControl": {
      borderBottom: "2px solid #0e44ff",
    },
    "& .MuiInputBase-root.MuiInput-root.MuiInputBase-fullWidth.MuiInput-fullWidth.MuiInputBase-formControl.MuiInput-formControl ": {
      borderBottom: "2px solid",
    },
    "& .kg-what-we-area": {
      marginTop: 0,
      width: "100%",
      // paddingBottom: "360px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
        marginTop: "80px",
        // paddingBottom: "20px",
      },
      "@media (max-width:1024px)": {
        marginTop: "40px",
      },
    },
    "& .kg-what-we-area .kg-main-area .kg-left-area h2": {
      marginTop: "0",
      fontSize: "50px",
      lineHeight: "40px",
      marginBottom: "36px",
    },
    "& .kg-main-area .kg-left-area p": {
      fontSize: "18px",
      fontWeight: "600",
      lineHeight: "24px",
      margin: "0",
      [theme.breakpoints.down("xs")]: {
        fontSize: "16px",
        lineHeight: "22px",
        marginBottom: "30px",
      },
    },
    "& .kg-what-we-area .kg-main-area": {
      display: "block",
      [theme.breakpoints.down("xs")]: {
        flexWrap: "wrap",
      },
    },
    "& .kg-what-we-area .kg-left-area": {
      width: "100%",
      float: "none",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    "& .kg-what-we-area .kg-right-content": {
      width: "100%",
      float: "none",
      paddingLeft: 0,
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 0,
        width: "100%",
      },
    },
    "& .kg-what-we-area .kg-right-content ul li ": {
      fontSize: "18px",
      marginBottom: "12px",
      lineHeight: "24px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "16px",
        marginBottom: "10px",
        lineHeight: "22px",
      },
    },
    "& .kg-what-we-area .kg-right-content ul": {
      margin: 0,
    },
    "& .kg-what-we-area .kg-what-we-inner ": {
      "@media only screen and (max-width:1023px)": {
        maxWidth: "900px",
        marginBottom: "30px",
        padding: 0,
      },
      maxWidth: "900px",
      marginBottom: "30px",
      paddingLeft: "120px",
    },
    "& .kg-what-we-area .kg-what-we-inner .kg-main-area .kg-right-content p ": {
      fontSize: "23px",
      color: "#202f43",
      fontWeight: 600,
      "@media (max-width:1024px)": {
        marginTop: "40px",
        fontSize: "18px",
      },
    },
    "& .kg_image_body:first-child": {
      maxWidth: "44%",
      flexBasis: "44%",
      "@media only screen and (max-width:767px)": {
        maxWidth: "100%",
        flexBasis: "100%",
      },
    },
  },
  imageBody_4: {
    // "@media only screen and (max-width:767px) ": {
    //   "& :nth-child(1)": {
    //     maxWidth: "44%",
    //     flexBasis: "44%",
    //   },
    //   maxWidth: "26%",
    //   flexBasis: "26%",
    //   marginBottom: "24px",
    // },
    maxWidth: "26%",
    flexBasis: "26%",
    marginBottom: "24px",
    "@media only screen and (max-width:767px)": {
      maxWidth: "100%",
      flexBasis: "100%",
      marginBottom: "24px",
    },
  },
  contentImg: {},
  banner_5: {
    width: "100%",
  },
  imageBody_3: {
    display: "flex",
    flexWrap: "wrap",
    gap: "2%",
    paddingLeft: "120px",
    paddingRight: "120px",
    "@media only screen and (max-width:1024px)": {
      padding: 0,
    },
  },
}));

const CreativeWork = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const post = props.post;
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Grid container justify="space-between" className={classes.reversemob}>
        <div class="kg-what-we-area">
          <div
            dangerouslySetInnerHTML={{
              __html: post?.bodycontent,
            }}
          />
        </div>
        <Grid
          item
          alignItems="center"
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          data-aos={"fade-up"}
          className={classes.imageBody_3}
        >
          <Grid
            item
            alignItems="center"
            xs={12}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            data-aos={"fade-up"}
            className={classes.imageBody_4 + " " + "kg_image_body"}
          >
            <div className={classes.contentImg}>
              <img
                className={classes.banner_5}
                src="/images/MAG_MOCKUP.jpg"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
              />
            </div>
          </Grid>
          <Grid
            item
            alignItems="center"
            xs={12}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            data-aos={"fade-up"}
            className={classes.imageBody_4}
          >
            <div className={classes.contentImg}>
              <img
                className={classes.banner_5}
                src="/images/Poster-Mockup-vol4.jpg"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
              />
            </div>
          </Grid>

          <Grid
            item
            alignItems="center"
            xs={12}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            data-aos={"fade-up"}
            className={classes.imageBody_4}
          >
            <div className={classes.contentImg}>
              <img
                className={classes.banner_5}
                src="/images/Poster-Mockup-vol4_2.jpg"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

CreativeWork.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default CreativeWork;
