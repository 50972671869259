import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "&": {
      background: "rgb(27 75 241)",
      padding: "120px 120px !important",
      "@media only screen and (max-width:1023px)": {
        padding: "60px 20px !important",
      },
    },
    "& p": {
      fontSize: "1.25rem",
      fontWeight: "500",
      lineHeight: "32px",
      color:"#fff",
      maxWidth:"1100px",
      textAlign:"center",
      marginLeft:"auto",
      marginRight:"auto",
      "@media only screen and (min-width:1921px)": {
        fontSize: "1.5rem",
      },
      "@media only screen and (max-width:1023px)": {
        fontSize: "1.125rem",
        lineHeight: "26px",
      },
    },
    "& button": {
      color: "#0e44ff",
      border: "2px solid #fff",
      display: "block",
      padding: "13px 36px",
      marginTop: "50px",
      borderRadius: "0",
      textTransform: "capitalize",
      backgroundColor: "transparent",
      marginLeft:"auto",
      marginRight:"auto",
      cursor:"pointer",
      "&:hover":{
        backgroundColor: "#fff",
        boxShadow: "inset 0 0 0 2em #fff",
        transition: ".6s",
      },
      "&:hover span":{
        color: "#0e44ff",
      },
      "@media only screen and (max-width:1023px)": {
        marginBottom: "24px",
        padding: "10px 30px",
      },
    },
    "& button span": {
      color: "#fff",
      textTransform: "uppercase",
      fontWeight:"600",
      fontFamily: "AktivGrotesk",
      fontSize: "1rem",
    },
    
  },

  kgservices :{
    fontSize: "3.75rem",
    lineHeight: "72px",
    marginBottom: "38px",
    fontWeight: 500,
    color: "#fff",
    marginTop:"20px",
    textAlign:"center",
    "@media only screen and (min-width:1921px)": {
      fontSize: "5rem",
    },
    "@media only screen and (max-width:1023px)": {
      marginTop: "0px",
      fontSize: "2.25rem",
      lineHeight: "38px",
    }, 
  },
  kgbtna:{
    textDecoration:"none"
  }
}));

const AuditGrowth = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const post = props.pageContent?.node?.auditservices;


  
  return (
    <div className={clsx(classes.root, className, "kg_services")} {...rest}>
      <h5 className={classes.kgservices}>Ready to make your business growth a priority? </h5>
      <p className={classes.kgservicespera}>All of our custom audits can either focus on the complete picture or be tailored to specific areas you feel need attention. Here are some of the core areas we can assess. </p>
      <a  className={classes.kgbtna} href="https://backend.apprato.com.au/audit-questionnaire/" rel="noreferrer" >
      <button>
        <span>FREE AUDIT APPRAISAL</span>
      </button>
      </a>
    </div>
  );
};

AuditGrowth.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default AuditGrowth;
