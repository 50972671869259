import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "&": {
      padding: "120px 120px !important",
      "@media only screen and (max-width:1023px)": {
        padding: "80px 20px !important",
      },
    },
    "& .kg_content_left-cols .kg_content_heading": {
      justifyContent: "start",

    },
    "& .kg_content_left-cols": {
      height:"max-content",
      "@media only screen and (max-width:1023px)": {
    
      },
    },
    "& .kg_content_left-cols .kg_content_heading h2": {
      fontSize: "3.75rem",
      marginBottom: "30px",
      marginTop: "30px",
      "@media only screen and (max-width:1023px)": {
        fontSize: "2.25rem",
        marginTop: "0px",
        lineHeight:"38px",
      },
    },
    "& .kg_content_left-cols .kg_content_pera P": {
      textAlign:"left",
      fontFamily: "AktivGrotesk-Medium",
      "@media only screen and (max-width:1023px)": {
    
      },
    },
    "& .kg_content_left-cols .kg_content_pera ": {
      justifyContent:"start"
    },
    "& .kg_content_left-cols .kg_content_pera span": {
      width:"100%",
      maxWidth:"80%",
      "@media only screen and (max-width:1023px)": {
        maxWidth:"100%",
        marginBottom: "50px",
      },
    },
    "& .kg_content_left-cols .kg_content_pera span h3" : {
     textAlign:"left",
     fontFamily: "AktivGrotesk-Bold",
    },
    "& .kg_content_row .kg_content_blogs" : {
     padding:"12px",
     "@media only screen and (max-width:1440px)": {
      maxWidth: "50%",
      flexBasis: "50%",
     },
     "@media only screen and (max-width:767px)": {
      maxWidth: "100%",
      flexBasis: "100%",
      padding:"0",
      marginBottom:"24px",
     },
     },
     "& .kg_content_row .kg_content_blogs h4" : {
        textAlign:"left",
        fontSize: "1.40rem",
        lineHeight:"26px",
        marginTop:"12px",
        "@media only screen and (max-width:1023px)": {
          fontSize: "1.25rem",
         },
      },
      "& .kg_content_row .kg_content_blogs p" : {
       marginTop:"8px",
       marginBottom:"0",
      },
  },
  headingh4: {
    fontSize: "30px",
    marginTop: "25px",
    fontWeight: 600,
    lineHeight: "28px",
    fontFamily: "AktivGrotesk",
    width: "100%",
    textAlign: "center",
    "@media only screen and (min-width:1921px)": {
      fontSize: "5rem",
    },
  },
  headingh5: {
    fontSize: "1rem",
    fontWeight: 600,
    lineHeight: "26px",
    fontFamily: "AktivGrotesk",
    textAlign: "left",
    margin:"0",
    "@media only screen and (min-width:1921px)": {
      fontSize: "1.2rem",
    },
    "@media only screen and (max-width:1023px)": {
      fontSize: "1rem",
    },
  },
  grids: {
    justifyContent: "center",
    padding: "0px",
    marginBottom: "0",
    "@media only screen and (max-width:1023px)": {
      padding: "0 20px",
    },
    "@media only screen and (max-width:991px)": {
      padding: "0 0px",
    },
  },
  figure: {
    display: "block",
    position: "relative",
    maxWidth: "40%",
    width: "100%",
    marginLeft:"0",
    marginRight:"0",
    marginTop: "0",
    objectFit: "cover",
    "&::after": {
      content: '""',
      position: "relative",
      display: "block",
      width: "100%",
      paddingBottom: "55%",
    },
    "& img": {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      objectFit: "contain",
      objectPosition: "center",
      display: "inline-block",
      width: "auto",
      height: "auto",
      maxWidth: "100%",
      maxHeight: "100%",
      margin: "0",
    },
  },
  titleh4: {
    marginTop: 0,
    fontSize: "3.5em",
    fontFamily: "AktivGrotesk-Medium",
    lineHeight: "72px",
    marginBottom: "44px",
    "@media only screen and (min-width:1921px)": {
      fontSize: "5rem",
    },
    "@media only screen and (max-width:1023px)": {
      fontSize: "45px",
    },
  },
  titledescription: {
    fontSize: "21px",
    textAlign: "center",
    fontFamily: "AktivGrotesk",
    fontWeight: 500,
    lineHeight: "28px",
    marginTop: 0,
    marginBottom: "100px",
    maxWidth: "69%",
    "@media only screen and (min-width:1921px)": {
      fontSize: "1.5rem",
    },
    "@media only screen and (max-width:1023px)": {
      fontSize: "18px",
      marginBottom: "75px",
    },
    "@media only screen and (max-width:991px)": {
      maxWidth: "100%",
    },
  },
  kghelparea:{
    padding:"20px",
    background:"#f2f3f8",
  }
}));

const AuditHelp = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const post = props.pageContent?.node?.serviceappratohelp;
  return (
    <div className={clsx(classes.root, className, "kg_apprato_help")} {...rest}>
      <Grid container direction="row" justify="center">
        <Grid
          item
          container
          justify="flex-start"
          xs={12}
          md={4}
          lg={4}
          xl={4}
          className={classes.heading + " " + "kg_content_left-cols"}
        >
           <Grid container direction="row" justify="center" className={classes.heading + " " + "kg_content_heading"}>
        {post?.serviceAppratoHelpTitle ? (
          <Typography variant="h2" display="block" className={classes.titleh4}>
            {post.serviceAppratoHelpTitle}
          </Typography>
        ) : (
          <Typography variant="h2" display="block" className={classes.titleh4}>
            How Apprato Can Help
          </Typography>
        )}
      </Grid>
      <Grid container direction="row" justify="center" className={classes.heading + " " + "kg_content_pera"}>
        {post?.serviceAppratoHelpDescription ? (
          <Typography
            variant="p"
            display="block"
            className={classes.titledescription}
          >
            <div
              className="page-content"
              dangerouslySetInnerHTML={{
                __html: post.serviceAppratoHelpDescription,
              }}
            ></div>
          </Typography>
        ) : (
          <Typography
            variant="p"
            display="block"
            className={classes.titledescription}
          >
            Why Choose Us? <br /> It doesn’t matter if you’re opening your first
            boutique clothing store or running a global B2B corporation—you need
            to make the buyer journey seamless if you want to convert customers.
            Our audits will assess each of your business touchpoints, allowing
            us to provide actionable recommendations that propel your growth and
            improve your bottom line.
          </Typography>
        )}
      </Grid>
        </Grid>
        <Grid
          item
          container
          justify="flex-start"
          xs={12}
          md={8}
          lg={8}
          xl={8}
          className={classes.heading + " " + "kg_content_row"}
        >
          
      {post?.typesOfServicesAppratoHelp.map((item, index) => {
        return (
          <>
            {item ? (
              <Grid
                item
                container
                justify="flex-start"
                xs={12}
                md={4}
                lg={4}
                xl={4}
                className={classes.heading + " " + "kg_content_blogs"}
              >
                <div className={classes.kghelparea}>
                {item.serviceAppratoHelpImage?.mediaItemUrl ? (
                  <>
                    <figure className={classes.figure}>
                      <img
                        src={item.serviceAppratoHelpImage.mediaItemUrl}
                        className={classes.consultant}
                        alt="Jordan Pearce"
                      />
                    </figure>
                  </>
                ) : (
                  <>
                    <figure className={classes.imageContainer}>
                      <img
                        src="/images/placeholder.png"
                        className={classes.consultant}
                        alt="Jordan Pearce"
                      />
                    </figure>
                  </>
                )}

                {item?.nameOfServiceAppratoHelp ? (
                  <Typography
                    variant="h4"
                    display="block"
                    className={classes.headingh4}
                  >
                    {item.nameOfServiceAppratoHelp}
                  </Typography>
                ) : (
                  ""
                )}

                {item?.serviceAppratoHelpInfo ? (
                  <Typography
                    variant="h5"
                    display="block"
                    className={classes.headingh5}
                  >
                    <div
                      className="page-content"
                      dangerouslySetInnerHTML={{
                        __html: item.serviceAppratoHelpInfo,
                      }}
                    ></div>
                  </Typography>
                ) : (
                  ""
                )}
                </div>
              </Grid>
            ) : (
              ""
            )}
          </>
        );
      })}
        </Grid>
      </Grid>
     

    </div>
  );
};

AuditHelp.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default AuditHelp;
