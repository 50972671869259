import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Box,
  TextField,
  Button,
  Snackbar,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import { useForm } from "react-hook-form";
import Axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputLabel-formControl": {
      color: "#0e44ff",
      padding: "0 30px",
      [theme.breakpoints.down("sm")]: {
        padding: 0,
      },
      // [theme.breakpoints.down("md")]: {
      //   color: "#0e44ff",
      //   padding: "0 30px",
      // },
    },
    "& .MuiInputLabel-formControl": {
      color: "#0e44ff",
      padding: "0 30px",
      [theme.breakpoints.down("sm")]: {
        padding: 0,
      },
    },

    "& .MuiInputBase-root.MuiInput-root.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-multiline.MuiInput-multiline": {
      borderBottom: "2px solid #0e44ff",
    },
  },
  messageForm: {
    color: "#0e44ff",
    paddingLeft: "120px",
    paddingRight: "120px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  },
  kgSendMessageButton: {
    color: "#0e44ff",
    padding: "0 30px",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  messageButton: {
    display: "block",
    marginLeft: "auto",
    marginTop: "50px",
    backgroundColor: "transparent",
    padding: "10px 36px",
    color: "#0e44ff",
    border: "2px solid #0e44ff",
    borderRadius: "unset",
    textTransform: "capitalize",
    "&:hover": {
      boxShadow: "inset 0 0 0 2em #0e44ff",
      color: "#fff",
      transition: ".6s",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "auto",
      marginTop: "10px",
      padding: "6px 18px",
    },
  },
  textfiled: {
    width: "33.33%",
    marginRight: 0,
    padding: "0 30px",
    "& .MuiInputLabel-formControl": {
      "@media (max-width:1440px)": {
        transform: "translate(0, 8px) scale(1)",
      },
    },
    "& #outlined-textarea-label": {},
    "& .MuiOutlinedInput-root .MuiInput-underline": {
      borderColor: "green",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: 0,
      padding: 0,
      marginBottom: "40px",
      padding: 0,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginRight: 0,
      padding: 0,
      marginBottom: "40px",
      padding: 0,
    },
  },
  div: {
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
  title: {
    color: "#0e44ff",
    marginBottom: "20px",
    padding: "0 30px",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  form: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  // messageForms: {
  //   paddingLeft: "90px",
  //   paddingRight: "90px",
  //   [theme.breakpoints.down("sm")]: {
  //     paddingLeft: "20px",
  //     paddingRight: "20px",
  //   },
  // },
}));

const SubscribeBottom = (props) => {
  const { className, ...rest } = props;
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState();
  const handleClose = (event, reason) => {
    if ("clickaway" == reason) return;
    setOpen(false);
  };
  const classes = useStyles();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const onSubmit = (data) => {
    const post_data = JSON.stringify({
      properties: [
        {
          property: "email",
          value: data.email,
        },
        {
          property: "firstname",
          value: data.first_name,
        },
        {
          property: "lastname",
          value: data.last_name,
        },
        {
          property: "phone",
          value: data.phone,
        },
        {
          property: "company",
          value: data.company,
        },
        {
          property: "budget",
          value: data.budget,
        },
        {
          property: "project_details",
          value: data.project_details,
        },
      ],
    });
     Axios.post(
      "https://backend.apprato.com.au/Ses.php",
      post_data,
      {
        headers: {
          "accept": "application/json",
        },
        mode:"cors"
      }
    )
      .then((res) => {
        if(res.data.success){
          setOpen(true);
          setMessage(res.data.response);
          document.querySelector(".first_name .MuiInputBase-input").value = "";
          document.querySelector(".last_name .MuiInputBase-input").value = "";
          document.querySelector(".company .MuiInputBase-formControl  .MuiInputBase-input").value = "";
          document.querySelector(".email .MuiInputBase-formControl  .MuiInputBase-input").value = "";
          document.querySelector(".phone .MuiInputBase-formControl  .MuiInputBase-input").value = "";
          document.querySelector(".company .MuiInputBase-formControl .MuiInputBase-input").value = "";
          document.querySelector(".budget .MuiInputBase-formControl .MuiInputBase-input").value = "";
          document.querySelector(".project-details .MuiInputBase-formControl .MuiInputBase-input").value = "";
        }else{
          setOpen(true);
          setMessage(res.data.response);
        }
      })
      .catch((error) => {
        setMessage(error);
        console.error(error);
      });

  };
  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
      name="messageForm"
      id="messageForm"
    >
      <Grid container justify="space-between" className={classes.messageForms}>
        <Grid item md={8} sx={12} className={classes.title}>
          <Typography variant="h4">
            Ready to grow your on-line business?
          </Typography>
          <Typography variant="h4">Let's talk.</Typography>
        </Grid>
        <Grid item md={12} className={classes.form}>
          <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.div}>
              {errors.first_name?.type === "required" ? (
                <TextField
                  id="standard-required"
                  label="First name*"
                  placeholder="First name required*"
                  multiline
                  className={classes.textfiled + " first_name"}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  {...register("first_name", { required: true })}
                />
              ) : (
                <TextField
                  id="standard-required"
                  label="First name*"
                  placeholder="First name"
                  multiline
                  className={classes.textfiled + " first_name"}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  {...register("first_name", { required: true })}
                />
              )}

              {errors.last_name?.type === "required" ? (
                <TextField
                  id="standard-required"
                  label="Last name required*"
                  placeholder="Last name"
                  multiline
                  className={classes.textfiled + " last_name"}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  {...register("last_name", { required: true })}
                />
              ) : (
                <TextField
                  id="standard-required"
                  label="Last name*"
                  placeholder="Last name"
                  multiline
                  className={classes.textfiled + " last_name"}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  {...register("last_name", { required: true })}
                />
              )}

              {errors.company_name?.type === "required" ? (
                <TextField
                  id="standard-required"
                  label="Company name*"
                  placeholder="Company name required*"
                  multiline
                  className={classes.textfiled + " company_name"}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  {...register("company_name", { required: true })}
                />
              ) : (
                <TextField
                  id="standard-required"
                  label="Company name*"
                  placeholder="Company name"
                  multiline
                  className={classes.textfiled + " company_name"}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  {...register("company_name", { required: true })}
                />
              )}
            </div>
            <div className={classes.div}>
              {errors.email?.type ? (
                <TextField
                  id="standard-required"
                  label="Enter Valid Email*"
                  placeholder="Email Required*"
                  multiline
                  className={classes.textfiled + " email"}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  {...register("email", {
                    required: true,
                    pattern: /^\S+@\S+$/i,
                  })}
                />
              ) : (
                <TextField
                  id="standard-required"
                  label="Email*"
                  placeholder="Email"
                  multiline
                  className={classes.textfiled + " email"}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  {...register("email", {
                    required: true,
                    pattern: /^\S+@\S+$/i,
                  })}
                />
              )}

              <TextField
                id="standard-required"
                label="Telephone number (optional)"
                placeholder="Telephone number"
                multiline
                className={classes.textfiled + " phone"}
                InputProps={{
                  disableUnderline: true,
                }}
                {...register("phone")}
              />

              <TextField
                id="standard-required"
                label="Project details (optional)"
                placeholder="Project details"
                multiline
                className={classes.textfiled + " company"}
                InputProps={{
                  disableUnderline: true,
                }}
                {...register("company")}
              />
            </div>
            <div className={classes.kgSendMessageButton}>
              <Button
                type="submit"
                className={classes.messageButton}
                style={{ width: "202px" }}
              >
                send message
              </Button>
            </div>
          </Box>
        </Grid>
      </Grid>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Thanks for your enquiry, it has been sent to the Apprato team.
        </Alert>
      </Snackbar>
    </div>
  );
};

SubscribeBottom.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default SubscribeBottom;
