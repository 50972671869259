import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "&": {
      background: "#fff",
      padding: "120px 120px !important",
      "@media only screen and (max-width:1023px)": {
        padding: "0 20px !important",
      },
    },
  },
  titleh4: {
    marginBottom: "50px",
    "@media only screen and (max-width:767px)": {
      marginBottom: "35px",
      textAlign: "center",
      fontSize: "36px",
      lineHeight: 1.6
    },
    "& h2": {
      fontSize: "3.75rem",
      "@media only screen and (max-width:1023px)": {
        fontSize: "2.25rem",
      },
    }

    
  },
  kgaccodian: {
    background: "transparent",
    boxShadow: "none !important",
    "&::before": {
      backgroundColor: "#1b4bf1 !important",
      height: "2px !important",
      display: "block !important",
      opacity: "1 !important",
    },
    "@media only screen and (min-width:1921px)": {
      fontSize: "5rem",
    },
  },
  kgquestion: {
    "& .MuiAccordionSummary-content": {
      color: "#1b4bf1",
      fontWeight: "600",
      margin: "18px 35px 33px 0",
      fontSize: "20px",
      fontFamily: "AktivGrotesk",
      "@media only screen and (min-width:1921px)": {
        fontSize: "1.5rem",
      },
    },
    "&::after": {
      content: '"+"',
      fontSize: "40px",
      color: "#1b4bf1",
    },
    "&.Mui-expanded::after": {
      content: '"-"',
      color: "#1b4bf1",
      fontWeight: 600,
      fontSize: "40px",
    },
  },
  kganswer: {
    fontWeight: 600,
    lineHeight: 1.8,
    padding: "10px 50px 10px 16px",
    fontSize: "18px",
    fontFamily: "AktivGrotesk",
    "@media only screen and (min-width:1921px)": {
      fontSize: "1.2rem",
    },
    "@media only screen and (max-width:767px)": {
      lineHeight: 1.6,
      padding: "10px 16px 10px",
    },
   
  },
}));

const AuditFaq = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const post = props.pageContent?.node?.servicefaq;
  return (
    <div className={clsx(classes.root, className, "kg_faq")} {...rest}>
      <Grid
        container
        direction="row"
        justify="center"
        className={classes.titleh4}
      >
        <Typography variant="h2" display="block" className={classes}>
          {post?.faqTitle}
        </Typography>
      </Grid>

      {post?.servicesFaq.map((item, index) => {
        return (
          <>
            {item?.faqAnswer ? (
              <Accordion className={classes.kgaccodian}>
                <AccordionSummary
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className={classes.kgquestion}
                >
                  {item.faqQuestion}
                </AccordionSummary>
                <AccordionDetails className={classes.kganswer}>
                  <div
                    className="page-content"
                    dangerouslySetInnerHTML={{
                      __html: item.faqAnswer,
                    }}
                  ></div>
                </AccordionDetails>
              </Accordion>
            ) : (
              ""
            )}
          </>
        );
      })}
    </div>
  );
};

AuditFaq.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default AuditFaq;
