import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, TextField, Button } from "@material-ui/core";
import { SectionHeader } from "../../../../components/molecules";
import { Description } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    // "& input#filled-search": {
    //   color: "#0e44ff",
    //   padding: "0 30px",
    //   [theme.breakpoints.down("sm")]: {
    //     padding: 0,
    //   },
    // },
    "& .MuiInputBase-root.MuiInput-root.makeStyles-textfield-57.MuiInputBase-fullWidth.MuiInput-fullWidth.MuiInputBase-formControl.MuiInput-formControl": {
      borderBottom: "2px solid #0e44ff",
    },
    "& .MuiInputBase-root.MuiInput-root.MuiInputBase-fullWidth.MuiInput-fullWidth.MuiInputBase-formControl.MuiInput-formControl ": {
      borderBottom: "2px solid",
    },
    "& .kg-what-we-area": {
      marginTop: 0,
      paddingBottom: "400px",
      paddingBottom: "160px",
      margin: "0",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "20px",
        paddingRight: "20px",
        marginTop: 0,
        paddingBottom: "20px",
      },
    },
    "& .what-we-are-grid-container": {
      [theme.breakpoints.up("md")]: {
        paddingLeft: "100px",
        paddingRight: "100px",
      }
    },    
    "& .kg-main-area .kg-left-area h2": {
      marginTop: "0",
      fontSize: "34px",
      lineHeight: "40px",
      marginBottom: "36px",
    },
    "& .kg-main-area .kg-left-area p": {
      fontSize: "18px",
      fontWeight: "600",
      lineHeight: "24px",
      margin: "0",
      [theme.breakpoints.down("xs")]: {
        fontSize: "16px",
        lineHeight: "22px",
        marginBottom: "30px",
      },
    },
    "& .kg-what-we-area .kg-main-area": {
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        flexWrap: "wrap",
      },
    },
    "& .kg-what-we-area .kg-left-area": {
      width: "35%",
      float: "left",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    "& .kg-what-we-area .kg-right-content": {
      width: "65%",
      float: "left",
    },
    "& .kg-what-we-area .kg-right-content ul li ": {
      fontSize: "18px",
      marginBottom: "12px",
      lineHeight: "24px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "16px",
        marginBottom: "10px",
        lineHeight: "22px",
      },
    },
    "& .kg-what-we-area .kg-right-content ul": {
      margin: 0,
    },
    "& .kg-what-we-area .kg-top-title p": {
      marginTop: "0",
      fontSize: "22px",
      lineHeight: "36px",
      fontWeight: "600",
      marginBottom: "44px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
        marginTop: "0",
        lineHeight: "30px",
        marginBottom: "30px",
      },
      "@media (min-width:1024px) and (max-width:1400px)": {
        fontSize: "20px",
        lineHeight: "32px",
      },
    },
    "& .kg-platforms-list ul": {
      paddingLeft: "24px",
    },
    "& .kg-partner .kg-platforms-list li": {
      fontSize: "22px",
      fontWeight: "600",
      marginBottom: "12px",
      lineHeight: "28px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
        lineHeight: "30px",
        marginBottom: "10px",
      },
      "@media (min-width:1024px) and (max-width:1400px)": {
        fontSize: "20px",
        lineHeight: "26px",
      },
    },
    "& .kg-partner .kg-top-title": {
      maxwidth: "700px",
    },
  },
  reversemob: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  divImage: {
    display: "block",
    //height: "100%",
    //paddingRight: "120px",
    marginTop: "118px",
    objectFit: "cover",
    position: "relative",
    verticalAlign: "middle",
    textAlign: "center",
    marginLeft: "auto !important",
    "&:after": {
      paddingBottom: "60%",
      content: '""',
      position: "relative",
      display: "nopne",
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
      marginTop: 0,
    },
  },
  bannerGrid: {
    maxWidth: "1000px",
    width: "50%",
    float: "left",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  imageBody: {
    paddingTop: "120px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column-reverse",
    },
  },
  imageContainer: {
    width: "50%",
    display: "inline-block",
    //float: "left",
    margin: "auto",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "44px",
      width: "100%",
    },
  },
  consultantTitle: {
    color: "#FFFFFF !important",
    marginBottom: "44px",
    fontSize: "60px",
    lineHeight: "68px",
    paddingTop: 0,
    marginBottom: "50px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "38px",
      lineHeight: "44px",
      paddingLeft: "20px",
      marginBottom: "30px",
    },
    "@media (min-width:1024px) and (max-width:1400px)": {
      fontSize: "48px",
      lineHeight: "58px",
    },
  },
  kgImage: {
    display: "block",
    maxWidth: "100%",
    height: "100%",
    objectFit: "cover",
    position: "relative",
    margin: "0 !important",
    width: "100%",
    "&:after": {
      paddingBottom: "75%",
      content: '""',
      position: "relative",
      display: "block",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "86%",
      },
    },
  },
  banner: {
    position: "absolute",
    top: "0",
    right: "0",
    left: "0",
    bottom: "0",
    objectFit: "contain",
    objectPosition: "center",
    width: "100%",
    height: "100%",
    display: "inline-block",
  },
  subline: {
    fontSize: "22px",
    lineHeight: "28px",
    marginTop: "44px",
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "24px",
    },
    "@media (min-width:1024px) and (max-width:1400px)": {
      fontSize: "20px",
      lineHeight: "26px",
    },
    "@media (min-width:1024px)": {
      marginRight: "20px"
    }
  },
}));

const Partner = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  if (props.loading) return <p>Loading Platforms...</p>;
  const post = props?.posts?.node?.platforms;
  const bgColor = '#1b4bf1'
  const textColor = '#FFFFFF'

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {/* <Grid container justify="space-between"></Grid> */}
      <Grid
        container
        justify="space-between"
        style={{ backgroundColor: bgColor }}
        className="what-we-are-grid-container"
      >
        <Grid
          item
          alignItems="center"
          xs={12}
          data-aos={"fade-up"}
          className={classes.imageBody}
        >
          <Typography
            className={classes.consultantTitle}
            variant="h2"
            style={{ color: textColor }}
          >
            {post?.whyTitle}
          </Typography>
        </Grid>

        <Grid
            item
            alignItems="center"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            data-aos={"fade-up"}
            style={{ color: textColor }}
          >                  
          <Typography variant="body1" className={classes.subline}>
            <div className="kg-what-we-area">
              <div
                dangerouslySetInnerHTML={{
                  __html: post?.whyColumn1,
                }}
              />
            </div>
            </Typography>
        </Grid>
        
        <Grid
            item
            alignItems="center"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            data-aos={"fade-up"}
            style={{ color: textColor }}
        >
          <Typography variant="body1" className={classes.subline}>
            <div className="kg-what-we-area">
              <div
                dangerouslySetInnerHTML={{
                  __html: post?.whyColumn2,
                }}
              />
            </div>
            </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

Partner.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Partner;
