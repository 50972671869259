import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
} from "@material-ui/core";
import "react-multi-carousel/lib/styles.css";
import { gql } from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { Link } from "react-router-dom";
import Slider from "react-slick";

const useStyles = makeStyles((theme) => ({
  root: {
    "& a": {
      textDecoration: "none",
      "& .react-multi-carousel-dot button": {
        borderColor: "#ebf0ff",
        background: "#ebf0ff",
      },
    },
    "& h5": {
      fontSize: "44px",
      lineHeight: "normal",
      textOverflow: "ellipsis",
      overflow: "hidden",
      display: "-webkit-box !important",
      WebkitLineClamp: "2",
      WebkitBoxOrient: "vertical",
      whiteSpace: "normal",
      [theme.breakpoints.down("sm")]: {
        fontSize: "25px",
        marginBottom: "20px",
      },
    },
    "& p": {
      color: "#000",
      [theme.breakpoints.down("xs")]: {
        WebkitLineClamp: "4",
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        fontSize: "18px",
        color: "#000",
      },
      [theme.breakpoints.down("sm")]: {
        display: "-webkit-box !important",
        overflow: "hidden",
        whiteSpace: "normal",
        textOverflow: "ellipsis",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: "3",
        fontSize: "18px",
      },
    },
    "& .slick-slide": {
      padding: "0 15px",
      "@media (max-width: 1023.95px)": {
        padding: "0 7.5px",
      },
    },
    "& ul.slick-dots button ": {
      background: "#ebf0ff",
      display: "inline-block",
      width: "12px",
      height: "12px",
      borderRadius: "50%",
      opacity: 1,
      boxShadow: "none",
      transition: "background .5s",
      border: "2px solid #ebf0ff",
      padding: "0",
      margin: "0 6px 0 0",
      outline: "0",
      cursor: "pointer",
    },
    "& ul.slick-dots li.slick-active button": {
      background: "#3377ff",
      borderColor: "#3377ff",
    },
    "& ul.slick-dots li button:before": {
      display: "none",
    },
    "& ul.slick-dots li": {
      width: "auto",
      height: "auto",
      margin: 0,
      padding: 0,
    },
  },
  heading: {
    "& h5": {
      fontSize: "44px",
      lineHeight: "normal",
    },
    "& h2": {
      [theme.breakpoints.down("md")]: {
        margin: "1.5em 0 0",
      },
      [theme.breakpoints.down("sm")]: {
        margin: "1em 0 0",
      },
    },
    "& h6": {
      margin: "1.5em 0",
      [theme.breakpoints.down("sm")]: {
        margin: "1em 0 0",
      },
    },
    [theme.breakpoints.up("xs")]: {
      paddingRight: "3rem",
      paddingBottom: "3rem",
    },
    [theme.breakpoints.up("md")]: {
      paddingRight: "3rem",
      paddingBottom: "3rem",
    },
    [theme.breakpoints.up("lg")]: {
      paddingRight: "7rem",
      paddingBottom: "3rem",
    },
    [theme.breakpoints.up("xl")]: {
      paddingRight: "3rem",
      paddingBottom: "3rem",
    },
  },
  imgContainer: {
    display: "block",
    height: "100%",
    objectFit: "cover",
    position: "relative",
    verticalAlign: "middle",
    marginLeft: "auto !important",
    "&:after": {
      paddingBottom: "50%",
      content: '""',
      position: "relative",
      display: "block",
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      "&:after": {
        paddingBottom: "70%",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "&:after": {
        paddingBottom: "70%",
      },
    },
  },
  CardMedia: {
    position: "absolute",
    top: "0",
    right: "0",
    left: "0",
    bottom: "0",
    objectFit: "cover",
    objectPosition: "center",
    width: "100%",
    height: "100%",
    display: "inline-block",
  },
  items: {
    backgroundColor: "#FFFFFF",
  },
  titlesContainer: {
    margin: "0",
    paddingTop: "20px",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      textTransform: "uppercase",
    },
    [theme.breakpoints.down("sm")]: {
      textTransform: "uppercase",
    },
  },
  consultantTitle: {
    paddingBottom: "20px",
    paddingLeft: "15px",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "20px",
      paddingLeft: "0",
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "20px",
      paddingLeft: "0",
    },
  },
  carouselItem: {
    padding: "0 15px",
    // [theme.breakpoints.down("xs")]: {
    //   padding: "0",
    // },
    [theme.breakpoints.down("sm")]: {
      padding: "0 7.5px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
  },
  CarouselSlider: {
    paddingBottom: "0",
  },
  subTitle: {
    // marginLeft: "24px",
    color: "#202f43",
    fontWeight: 700,
    [theme.breakpoints.down("xs")]: {
      paddingTop: "30px",
      width: "70%",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "30px",
      width: "70%",
    },
  },
  carouselDot: {
    justifyContent: "center",
    "& .react-multi-carousel-dot button": {
      background: "#ebf0ff",
      borderColor: "#ebf0ff",
    },
    "& .react-multi-carousel-dot.react-multi-carousel-dot--active button": {
      background: "#3377ff",
      borderColor: "#3377ff",
    },
    // [theme.breakpoints.up("lg")]: {
    //   display: "none",
    // },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      position: "absolute",
      bottom: "0 !important",
    },
  },
  expertbox: {
    paddingLeft: "120px",
    paddingRight: "120px",
    paddingTop: "80px",
    paddingBottom: "80px",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingBottom: "40px",
      paddingTop: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "12.5px",
      paddingRight: "12.5px",
      paddingBottom: "40px",
      paddingTop: "40px",
    },
  },
  button: {
    borderRadius: "0",
    padding: "10px 20px",
    color: "#fff",
    borderColor: "#fff",
    borderWidth: "2px",
    backgroundColor: "transparent",
    marginTop: "34px",
    [theme.breakpoints.down("xs")]: {
      padding: "6px 8px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "6px 8px",
    },
  },
  buttonContainer: {
    marginTop: "34px",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "end",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "end",
    },
  },
  cardContainer: {
    width: "100%",
    maxWidth: "100%",
    display: "block",
    // padding: "0 8px",
    [theme.breakpoints.down("md")]: {
      padding: 0,
      marginBottom: "30px",
      justifyContent: "center !important",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "30px",
      justifyContent: "center !important",
    },
  },
  card: {
    backgroundColor: "#f2f3f8",
  },
  breadcrumbsa: {
    color: "#202f43",
  },
  breadContainer: {
    marginBottom: "7px",
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "15px",
    },
  },
  CardActions: {
    padding: "16px",
  },
  a: {
    color: "#a8acb1",
  },
  title1Container: {
    maxWidth: "50%",
    flexBasis: "50%",
    padding: "0 10px",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  title2Container: {
    maxWidth: "50%",
    flexBasis: "50%",
    padding: "0 10px",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  title1: {
    marginBottom: "0",
    fontSize: "50px",
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: "35px",
      fontWeight: "600",
    },
  },
  link: {
    "& :hover": {
      background: "#f2f3f8",
    },
  },
  titleContainer: {
    alignItems: "center",
    padding: "0 8px",
    marginBottom: "35px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 7.5px",
      marginBottom: "24px",
    },
    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
      padding: "0",
      marginBottom: "24px",
    },
  },
  title2: {
    color: "#0e44ff",
    textDecoration: "none",
    fontWeight: "700",
    fontSize: "18px",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      color: "#202f43",
    },
  },
  consultantTitle: {
    marginBottom: "0",
    textAlign: "right",
  },
  breadcrumbsBottom: {
    "& .MuiBreadcrumbs-separator": {
      color: "#a8acb1",
    },
  },
  breadcrumbTypography: {
    fontWeight: "600",
    color: "#202f43",
  },
  gridcontainer: {
    display: "block",
    width: "100%",
  },
  imageroot: {
    display: "block",
    maxWidth: "100%",
    flexBasis: "100%",
    height: "100%",
    objectFit: "cover",
    position: "relative",
    verticalAlign: "middle",
    marginLeft: "auto !important",
    "&:after": {
      content: '""',
      paddingBottom: "68.5%",
      position: "relative",
      display: "block",
      width: "100%",
    },
    // [theme.breakpoints.down("md")]: {
    //   "&:after": {
    //     paddingBottom: "63.5%",
    //   },
    // },
    [theme.breakpoints.down("xs")]: {
      "&:after": {
        paddingBottom: "63.5%",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "&:after": {
        paddingBottom: "63.5%",
      },
    },
  },
  slider: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "30px",
    },
  },
  images: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "0",
    right: "0",
    left: "0",
    bottom: "0",
    objectFit: "cover",
    objectPosition: "center",
    display: "inline-block",
    [theme.breakpoints.down("xs")]: {
      borderRadius: "4px",
    },
    [theme.breakpoints.down("sm")]: {
      borderRadius: "4px",
    },
  },
}));

const MostRecent = (props) => {
  var settings = {
    // autoplaySpeed: 5000,
    speed: 500,
    autoplay: true,
    infinite: true,
    arrows: false,
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 786,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  };
  const { className, ...rest } = props;
  const classes = useStyles();
  const GET_POSTS = gql`
    {
      posts(where: { orderby: { field: DATE, order: DESC } }, first: 6) {
        nodes {
          title
          uri
          slug
          featuredImage {
            node {
              id
              mediaDetails {
                file
              }
            }
          }
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_POSTS);
  if (loading) return <p>Loading Posts...</p>;
  if (error) return <p>An error occured!</p>;
  const rowMarkup = data.posts?.nodes.map((item, index) => {
    return (
      <>
        {item ? (
          <Grid container justifyContent="space-between" key={index}>
            <Link to={`/${item.slug}`} className={classes.gridcontainer}>
              <Grid
                item
                container
                justifyContent="flex-start"
                alignItems="center"
                xs={12}
                md={6}
                lg={6}
                xl={6}
                className={classes.imageroot}
              >
                {item.featuredImage?.node?.mediaDetails.file ? (
                  <>
                    <img
                      src={
                        "https://backend.apprato.com.au/wp-content/uploads/" +
                        item.featuredImage?.node?.mediaDetails.file
                      }
                      alt=""
                      className={classes.images}
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="2000"
                    />
                  </>
                ) : (
                  <>
                    <img
                      src="/images/placeholder.png"
                      className={classes.images}
                      alt="Jordan Pearce"
                    />
                  </>
                )}
              </Grid>
            </Link>
            <Grid
              item
              container
              spacing={2}
              className={classes.titlesContainer}
            >
              <Link to={`/${item.slug}`} className={classes.gridcontainer}>
                <Typography variant="body1" className={classes.subTitle}>
                  {item.title ? item.title : ""}
                </Typography>
              </Link>
            </Grid>
          </Grid>
        ) : (
          ""
        )}
      </>
    );
  });
  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
      name="about"
      id="about"
    >
      <div className={classes.expertbox}>
        <Grid
          item
          container
          justify="space-between"
          xs={12}
          className={classes.titleContainer}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            className={classes.title1Container}
          >
            <Typography
              variant="h6"
              component="div"
              className={classes.title1}
              gutterBottom
            >
              Most recent
            </Typography>
          </Grid>
        </Grid>
        {rowMarkup ? <Slider {...settings}>{rowMarkup}</Slider> : ""}
      </div>
    </div>
  );
};

MostRecent.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default MostRecent;
