import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {  Grid } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { gql } from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 10000, min: 2880 },
    items: 2,
    slidesToSlide: 2,
    partialVisibilityGutter: 0,
  },
  desktop: {
    breakpoint: { max: 2880, min: 1400 },
    items: 3,
    slidesToSlide: 2,
    partialVisibilityGutter: 0,
  },
  // mobile: {
  //   breakpoint: { max: 1024, min: 0 },
  //   items: 1,
  //   slidesToSlide: 1,
  //   partialVisibilityGutter: 0,
  // },
  // desktop: {
  //   breakpoint: { max: 3000, min: 1024 },
  //   items: 3,
  //   partialVisibilityGutter: 40 // this is needed to tell the amount of px that should be visible.
  // },
  tablet: {
    breakpoint: { max: 1400, min: 786 },
    items: 2,
    slidesToSlide: 2,
    partialVisibilityGutter: 30, // this is needed to tell the amount of px that should be visible.
  },
  mobile: {
    breakpoint: { max: 786, min: 0 },
    items: 1,
    partialVisibilityGutter: 30, // this is needed to tell the amount of px that should be visible.
  },
};
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingLeft: "112px",
    paddingRight: "112px",
    "& a": {
      textDecoration: "none",
    },
    "& .kg_related_case_studies": {
      marginBottom: "0",
    },
    "& a:hover": {
      textDecoration: "none",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    "& h6": {
      // margin: "1.5em 0",
      fontSize: "32px",
      fontWeight: 700,
      paddingBottom: "50px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "24px !important",
        // WebkitLineClamp: "4",
        // WebkitBoxOrient: "vertical",
        // overflow: "hidden",
        // textOverflow: "ellipsis",
        // display: "-webkit-box",
      },
      // [theme.breakpoints.down("sm")]: {
      //   fontSize: "26px !important",
      //   // display: "-webkit-box !important",
      //   // overflow: "hidden",
      //   // whiteSpace: "normal",
      //   // textOverflow: "ellipsis",
      //   // WebkitBoxOrient: "vertical",
      //   // WebkitLineClamp: "3",
      // },
      // [theme.breakpoints.down("md")]: {
      //   fontSize: "28px",
      //   display: "-webkit-box !important",
      //   overflow: "hidden",
      //   whiteSpace: "normal",
      //   textOverflow: "ellipsis",
      //   WebkitBoxOrient: "vertical",
      //   WebkitLineClamp: "2",
      // },
      // [theme.breakpoints.down("lg")]: {
      //   fontSize: "28px",
      //   display: "-webkit-box !important",
      //   overflow: "hidden",
      //   whiteSpace: "normal",
      //   textOverflow: "ellipsis",
      //   WebkitBoxOrient: "vertical",
      //   WebkitLineClamp: "1",
      // },
    },
  },
  items: {
    backgroundColor: "#FFFFFF",
    boxShadow: "none",
  },
  cardContainer: {
    padding: "0 8px",
    // [theme.breakpoints.down("md")]: {
    //   flexGrow: "0",
    //   maxWidth: "49%",
    //   flexBasis: "49%",
    // },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "30px",
      // justifyContent: "center !important",
      flexGrow: "0",
      maxWidth: "100% !important",
      flexBasis: "100% !important",
    },
    // [theme.breakpoints.down("sm")]: {
    //   flexGrow: "0",
    //   padding: 0,
    //   marginBottom: "15px",
    //   maxWidth: "49%",
    //   flexBasis: "49%",
    // },
  },
  reversemob: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
    },
  },
  title1: {
    fontWeight: "600",

    marginBottom: "0",
    [theme.breakpoints.down("lg")]: {
      fontSize: "2rem",
      padding: "0 8px",
    },
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  link: {
    width: "100%",
    "& :hover": {
      background: "#f2f3f8",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    // [theme.breakpoints.down("s")]: {
    //   width: "100%",
    // },
  },
  titleContainer: {
    alignItems: "center",
    // padding: "0 8px",
    marginBottom: "35px",
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
      padding: "0",
      marginBottom: "24px",
      padding: "0 8px",
    },
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      padding: "0",
      marginBottom: "24px",
    },
  },
  imgContainer: {
    display: "block",
    height: "100%",
    objectFit: "cover",
    position: "relative",
    verticalAlign: "middle",
    marginLeft: "auto !important",
    "&:after": {
      paddingBottom: "50%",
      content: '""',
      position: "relative",
      display: "block",
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      "&:after": {
        paddingBottom: "70%",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "&:after": {
        paddingBottom: "70%",
      },
    },
  },
  CardMedia: {
    position: "absolute",
    top: "0",
    right: "0",
    left: "0",
    bottom: "0",
    objectFit: "cover",
    objectPosition: "center",
    width: "100%",
    height: "100%",
    display: "inline-block",
  },
  title1Container: {
    maxWidth: "50%",
    flexBasis: "50%",
    "@media (max-width:785px)": {
      maxWidth: "100%",
      flexBasis: "100%",
    },
  },
  sliderCarousel: {
    width: "100%",
  },
  carousel: {
    // "& .react-multi-carousel-dot-list": {
    //   display:"none",
    //   "@media (max-width:1024px)": {
    //     display:"block",
    //   },
    //   "& .react-multi-carousel-track ": {
    //     "@media (max-width:1024px)": {
    //     paddingBottom: "4vw",
    //     },
    //   },
    // },
  },
  CardMainTitle: {
    padding: "34px 20px 100px 20px !important",
  },
  cardtitle: {
    fontSize: "30px",
    fontWeight: "600",
  },
  carouselDot: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      "& .react-multi-carousel-dot button": {
        background: "#ebf0ff",
        borderColor: "#ebf0ff",
      },
      "& .react-multi-carousel-dot.react-multi-carousel-dot--active button": {
        background: "#3377ff",
        borderColor: "#3377ff",
      },
    },
  },
}));

const RelatedCaseStudies = (props) => {
  const GET_MENU = gql`
    {
      menuItem(id: "2122", idType: DATABASE_ID) {
        id
        label
        childItems {
          edges {
            node {
              id
              label
              path
              databaseId
              connectedNode {
                node {
                  ... on Page {
                    id
                    featuredImage {
                      node {
                        mediaItemUrl
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_MENU);
  const casestudies = data?.menuItem.childItems.edges;
  const { className, ...rest } = props;
  const classes = useStyles();
  const rowMarkup = casestudies?.map((item, index) => {
    return (
      <>
        {item ? (
          <Grid
            item
            id={item.node.label}
            key={item.node.label}
            position={index}
            container
            justify="flex-start"
            data-aos={"fade-up"}
            className={classes.cardContainer}
          >
            <Link to={item.node.path} className={classes.link}>
              <Card variant="outlined" className={classes.items}>
                <div className={classes.imgContainer}>
                  {item?.node?.connectedNode?.node?.featuredImage ? (
                    <CardMedia
                      className={classes.CardMedia}
                      component="img"
                      height="194"
                      image={
                        item?.node?.connectedNode?.node?.featuredImage?.node
                          ?.mediaItemUrl
                      }
                      alt={item.node.label}
                    />
                  ) : (
                    <CardMedia
                      className={classes.CardMedia}
                      component="img"
                      height="194"
                      image={"/images/placeholder.png"}
                      alt={item.node.label}
                    />
                  )}
                </div>
                <CardContent className={classes.CardMainTitle}>
                  {/* <Link href="/" className={classes.breadcrumbsa}> */}
                  <Typography className={classes.cardtitle}>
                    {item.node.label}
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        ) : (
          ""
        )}
      </>
    );
  });

  return (
    <div className={clsx(classes.root)}>
      <Grid
        container
        justify="space-between"
        xs={12}
        className={classes.titleContainer + " kg_related_case_studies"}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          className={classes.title1Container}
        >
          <Typography variant="h6" className={classes.title1} gutterBottom>
            Related Case Studies
          </Typography>
        </Grid>
      </Grid>

      {rowMarkup ? (
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={true}
          infinite={true}
          autoPlay={false}
          autoPlaySpeed={5000}
          dotListClass={classes.carouselDot}
          keyBoardControl={true}
          transitionDuration={500}
          removeArrowOnDeviceType={[]}
          responsive={responsive}
          centerMode={false}
          ssr={false}
          containerClass={classes.CarouselSlider}
          itemClass={classes.carouselItem}
          partialVisible={false}
          renderButtonOutside={true}
          className={classes.carousel}
        >
          {rowMarkup}
        </Carousel>
      ) : (
        ""
      )}
    </div>
  );
};
RelatedCaseStudies.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default RelatedCaseStudies;
//export default graphql(getPosts)(Items) // Bind the query to the compoent to have access to the variables in the return.
