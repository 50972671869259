import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: "100px",
    paddingRight: "100px",
    "@media (max-width:1023px)": {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
    "& .kg-what-we-area": {
      marginTop: 0,
      // paddingBottom: "360px",
      // [theme.breakpoints.down("sm")]: {
      //   fontSize: "16px",
      //   marginTop: "80px",
      //   // paddingBottom: "20px",
      // },
      "@media (max-width:785px)": {
        marginTop: "0px",
      },
    },
    "& .kg-main-area .kg-left-area h2": {
      marginTop: "0",
      fontSize: "34px",
      lineHeight: "40px",
      marginBottom: "50px",
      "@media (max-width:1023px)": {
        marginBottom: "30px",
      },
    },
    "& .kg-main-area .kg-left-area p": {
      fontSize: "23px",
      lineHeight: "34px",
      color: "#202f43",
      fontWeight: 600,
      [theme.breakpoints.down("xs")]: {
        fontSize: "18px",
        lineHeight: "22px",
        marginBottom: "30px",
      },
      "@media (max-width:1024px)": {
        fontSize: "18px",
      },
      "@media (max-width:767px)": {
        fontSize: "16px",
      },
    },
    "& .kg-what-we-area .kg-main-area": {
      display: "unset !important",
      [theme.breakpoints.down("xs")]: {
        flexWrap: "wrap",
      },
    },
    "& .kg-what-we-area .kg-left-area": {
      width: "100%",
      maxWidth: "415px",
      float: "left",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
      "@media (max-width:785px)": {
        width: "100%",
        maxWidth: "100%",
      },
    },
    "& .kg-what-we-area .kg-right-content": {
      width: "65%",
      float: "left",
      paddingLeft: "50px",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 0,
        width: "100%",
      },
    },
    "& .kg-what-we-area .kg-right-content ul li ": {
      fontSize: "18px",
      marginBottom: "12px",
      lineHeight: "24px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "16px",
        marginBottom: "10px",
        lineHeight: "22px",
      },
    },
    "& .kg-what-we-area .kg-right-content ul": {
      margin: 0,
    },
    "&  .kg-body-image ": {
      display: "block",
      maxWidth: "100%",
      height: "100%",
      objectFit: "cover",
      position: "relative",
      margin: "auto",
      width: "100%",
    },
    "& .kg-body-image img": {
      position: "absolute",
      top: "0",
      right: "0",
      left: "0",
      bottom: "0",
      objectFit: "cover",
      objectPosition: "center",
      width: "100%",
      height: "100%",
      display: "inline-block",
    },
    "& .kg-body-image:after": {
      paddingBottom: "65%",
      content: '""',
      position: "relative",
      display: "block",
      width: "100%",
    },

    "& .kg-tcc-banner-area:after": {
      paddingBottom: "65%",
      content: '""',
      position: "relative",
      display: "block",
      width: "100%",
    },
    "& .kg-tcc-banner-area img": {
      position: "absolute",
      top: "0",
      right: "0",
      left: "0",
      bottom: "0",
      objectFit: "cover",
      objectPosition: "center",
      width: "100%",
      height: "100%",
      display: "inline-block",
    },
    "& .kg-tcc-banner-area": {
      display: "block",
      maxWidth: "100%",
      height: "100%",
      objectFit: "cover",
      position: "relative",
      margin: "auto",
      width: "100%",
    },
    "& .kg-content": {
      padding: "0 20px",
      "@media (max-width:1023px)": {
        padding: "0 10px",
      },
    },
    "& .kg-image": {
      padding: "0 20px",
      "@media (max-width:1023px)": {
        padding: "0 10px",
      },
    },
  },
  reversemob: {
    rowGap: "40px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      rowGap: "20px",
    },
  },
  workTogether: {
    backgroundColor: "#f2f3f8",
    padding: "2rem",
  },
  body: {
    marginTop: "60px",
    display: "flex",
    // margin: 0,
  },
  right: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  subscribeRight: {
    marginLeft: "20px",
    display: "inline-block",
    padding: "2rem",
    backgroundColor: "#f2f3f8",
  },
  subscribeRightText: {
    color: "#FFF",
  },
  content: {
    paddingTop: "1rem",
    paddingLeft: 0,
    paddingRight: 0,
  },
  divImage: {
    display: "block",
    height: "auto",
    objectFit: "cover",
    position: "relative",
    verticalAlign: "middle",
    marginLeft: "auto !important",
    "& img": {
      "@media (max-width:1024px)": {
        objectFit: "cover",
      },
    },
    "&:after": {
      "@media (max-width:767px)": {
        paddingBottom: "65%",
      },
      paddingBottom: "70%",
      content: '""',
      position: "relative",
      display: "block",
      width: "100%",
    },
    // [theme.breakpoints.down("md")]: {
    //   "&:after": {
    //     paddingBottom: "52%",
    //   },
    // },
    // [theme.breakpoints.down("xs")]: {
    //   "&:after": {
    //     paddingBottom: "52%",
    //   },
    // },
    // [theme.breakpoints.down("sm")]: {
    //   "&:after": {
    //     paddingBottom: "54%",
    //   },
    // },
  },
  banner: {
    height: "100%",
    width: "100%",
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    objectFit: "cover",
    objectPosition: "center",
    width: "100%",
    height: "100%",
    display: "inline-block",
  },
  bannerGrid: {
    maxWidth: "1000px",
  },
  contentGrid: {},
  imageBody: {
    padding: "0 20px",
    [theme.breakpoints.down("sm")]: {
      flexGrow: 0,
      maxWidth: "41.666667%",
      flexBasis: "41.666667%",
      margin: "0",
      padding: "0 10px",
    },
    "@media (max-width:785px)": {
      maxWidth: "100%",
      flexBasis: "100%",
    },
  },
  bodycontent: {},
  mainImageDiv: {},
  imageBody2: {
    padding: "0 20px",
    "@media (max-width:1024px)": {
      padding: "0 10px",
    },
  },
}));

const TccBody = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const post = props.post;
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Grid container justify="space-between" className={classes.reversemob}>
        <Grid
          item
          alignItems="center"
          xs={12}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          data-aos={"fade-up"}
          className={classes.imageBody}
        >
          <div className={classes.divImage}>
            {/* {post?.bodyimage?.mediaItemUrl ? ( */}
            <img
              className={classes.banner}
              src="/images/Main_image.jpg"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
            />
            {/* ) : (
                  <Skeleton
                    variant="rectangular"
                    className={classes.banner}
                    animation="wave"
                  />
                )} */}
          </div>
        </Grid>
        <Grid
          container
          item
          alignItems="center"
          xs={12}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          data-aos={"fade-up"}
          className={classes.imageBody2}
        >
          <div className={classes.mainImageDiv + " " + "kg-tcc-banner-area"}>
            <img
              className={classes.banner1}
              src="/images/Thumb_TCC.jpg"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
            />
          </div>
        </Grid>
        <Grid
          item
          alignItems="center"
          xs={12}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          data-aos={"fade-up"}
          className={`kg-content`}
        >
          <div class="kg-what-we-area">
            <div
              dangerouslySetInnerHTML={{
                __html: post?.bodycontent,
              }}
            />
          </div>
        </Grid>
        <Grid
          container
          item
          alignItems="center"
          xs={12}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          data-aos={"fade-up"}
          className={`kg-image`}
        >
          <div className="kg-body-image">
            <img
              className={classes.banner1}
              src="/images/Ad_Mockup.jpg"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

TccBody.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default TccBody;
