import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Section } from "../../components/organisms";
import {
  Hero,
  Experts,
  Plans,
  Services,
  ServicesMobile,
  MessageForm,
  Work,
  Insights,
  OurClients,
} from "./components";
import { gql } from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

import { Hidden } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { description, thumbnail, title } from "../../Context";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    backgroundColor:"#fff"
  },
  sectionPaddingTop: {
    paddingTop: "40px",
    paddingBottom: "40px",
    paddingLeft: 0,
    paddingRight: 0,
  },
  pagePaddingTop: {
    paddingTop: 0,
    paddingLeft: 0,
    paddingBottom: "40px",
    paddingRight: 0,
    paddingLeft: theme.spacing(0),
    [theme.breakpoints.up("md")]: {
      padding: 0,
      paddingTop: 0,
      paddingLeft: 0,
      paddingBottom: "40px",
    },
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  sectionNoPaddingBottom: {
    paddingBottom: 0,
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  sectionMarginBottom: {
    marginBottom: "20px",
    paddingTop: "80px",
  },
  shape: {},
}));

const Home = () => {
  const GET_MENU = gql`
    {
      menuItem(id: "1612", idType: DATABASE_ID) {
        id
        label
        childItems {
          edges {
            node {
              id
              label
              connectedNode {
                node {
                  ... on Page {
                    content(format: RENDERED)
                    featuredImage {
                      node {
                        id
                        mediaItemUrl
                      }
                    }
                    services {
                      homePageContent
                      backgroundColor
                      fieldGroupName
                      homePageContent
                      backgroundColor
                      color
                      typesOfServices {
                        color
                        nameOfService
                        fieldGroupName
                        serviceInfo
                        serviceImageAfterTitle
                        serviceImage {
                          mediaItemUrl
                        }
                      }
                    }
                    uri
                  }
                }
              }
              databaseId
            }
          }
        }
      }
    }
  `;
  const { loading, error, data } = useQuery(GET_MENU);
  const servicesName = data?.menuItem.childItems.edges;
  const classes = useStyles();

  return (
    <div className={classes.root}>
       <Helmet>
        <title>{title}</title>
        <meta name="description" content={`${description}`} />
        <meta name="title" property="og:title" content={`${title}`} />
        <meta name="description" property="og:description" content={`${description}`} />
        <meta name="image" property="og:image" content={`${thumbnail}`} />
      </Helmet>
      <Section className={classes.pagePaddingTop}>
        <Hero />
      </Section>
      <Section className={classes.sectionPaddingTop}>
        <Plans />
      </Section>
      <Hidden smDown implementation="css">
      <Section className={classes.sectionPaddingTop}>
          <Services servicesName={servicesName} />
        </Section>
      </Hidden>
      <Hidden mdUp implementation="css">
      <Section className={classes.sectionPaddingTop}>
          <ServicesMobile servicesName={servicesName} />
        </Section>
      </Hidden>
      <Section className={classes.sectionPaddingTop}>
        <Experts />
      </Section>
      <Section className={classes.sectionNoPaddingBottom}>
        <Work />
      </Section>
      <Section className={classes.sectionNoPaddingTop}>
        <Insights />
      </Section>
      <Section className={classes.sectionNoPaddingTop}>
        <OurClients />
      </Section>
      <Section>
         <MessageForm />
      </Section>
    </div>
  );
};

export default Home;
