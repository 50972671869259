import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Section } from "../../components/organisms";
import { Hero } from "./component";
import { gql } from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { Context, description, thumbnail, title } from "../../Context";
import { Helmet } from "react-helmet";
//import { sas1, sas2, sas3, sas4, sas5 } from "./data";

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 *
 */

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    backgroundColor: "#ffffff"
  },
 
  headerPaddingTopCustom: {
    paddingTop: 0,
    paddingLeft: 0,
    paddingBottom: "40px",
    paddingRight: 0,
    background: "#f2f3f8",
    paddingLeft: theme.spacing(0),
    [theme.breakpoints.up("md")]: {
      padding: 0,
      paddingTop: 0,
      paddingLeft: 0,
      paddingBottom: "40px",
    },
  },
}));

const SoftwareDevelopment = () => {
  var GET_PAGE = gql`
    {
      page(id: "2668", idType: DATABASE_ID) {
        id
        featuredImage {
          node {
            mediaItemUrl
          }
        }
        title
        metaDetails {
          customMetaDescription
          customMetaTitle
        }
        content(format: RENDERED)
      }
    }
  `;
  const classes = useStyles();
  const { loading, error, data } = useQuery(GET_PAGE);
  if (loading) return <p>Loading Posts...</p>;
  if (error) return <p>An error occured!</p>;
  const metaDetails =data?.page?.metaetails;
  const featuredImage =data?.page?.featuredImage?.node?.mediaItemUrl;
  var pageTitle = data?.page?.title;
  // var pageContent = data?.menuItem.connectedNode;
  const helemetMeta = (
    <Helmet>
    <title>{pageTitle}</title>
    <meta name="description" content={`${metaDetails && metaDetails.customMetaDescription !== null ? metaDetails.customMetaDescription : description  }`} />
    <meta name="title" property="og:title" content={`${metaDetails && metaDetails.customMetaTitle !== null ? metaDetails.customMetaTitle : title  }`} />
    <meta name="description" property="og:description" content={`${metaDetails && metaDetails.customMetaDescription !== null ? metaDetails.customMetaDescription : description  }`} />
    <meta name="image" property="og:image" content={`${featuredImage && featuredImage !== null ? featuredImage : thumbnail  }`} />
  </Helmet>
  )
  return (
    <div className={classes.root}>  
      {helemetMeta}
      <Section className={classes.headerPaddingTopCustom}>
        <Hero pageContent={data} pageTitle={pageTitle} />
      </Section>
    </div>
  );
};

export default SoftwareDevelopment;
