import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, TextField, Button, Hidden } from "@material-ui/core";
import { SectionHeader } from "../../../../components/molecules";
import { Description } from "@material-ui/icons";
import { Link, useLocation } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    // "& input#filled-search": {
    //   color: "#0e44ff",
    //   padding: "0 30px",
    //   [theme.breakpoints.down("sm")]: {
    //     padding: 0,
    //   },
    // },
    "& .MuiInputBase-root.MuiInput-root.makeStyles-textfield-57.MuiInputBase-fullWidth.MuiInput-fullWidth.MuiInputBase-formControl.MuiInput-formControl": {
      borderBottom: "2px solid #0e44ff",
    },
    "& .MuiInputBase-root.MuiInput-root.MuiInputBase-fullWidth.MuiInput-fullWidth.MuiInputBase-formControl.MuiInput-formControl ": {
      borderBottom: "2px solid",
    },
    "& .kg-what-we-area": {
      marginTop: "110px",
      // paddingBottom: "360px",
      "@media screen and (max-width:767px)": {
        textAlign: "center",
        marginTop: "45px",
      }
      // [theme.breakpoints.down("sm")]: {
      //   fontSize: "16px",
      //   marginTop: "80px",
      //   // paddingBottom: "20px",
      // },
    },
    "& .kg-main-area .kg-left-area h2": {
      marginTop: "0",
      fontSize: "34px",
      lineHeight: "40px",
      marginBottom: "50px",
      "@media screen and (min-width:768px) and (max-width:991px)": {
        fontSize: "28px",
        lineHeight: "40px",
        marginBottom: "20px",
      },
      " @media screen and (max-width:767px)": {
        fontSize: "28px",
        lineHeight: "30px",
        marginBottom: "30px",
      },
    },
    "& .kg-main-area .kg-left-area p": {
      fontSize: "24px",
      fontWeight: "600",
      lineHeight: "34px",
      color: "#202f43",
      margin: "0",
      [theme.breakpoints.down("xs")]: {
        fontSize: "16px",
        lineHeight: "22px",
        marginBottom: "30px",
      },
      "@media screen and (min-width:768px) and (max-width:991px)": {
        fontSize: "16px",
        lineHeight: "22px",
        marginBottom: "50px",
      },
    },
    "& .kg-what-we-area .kg-main-area": {
      display: "unset !important",
      [theme.breakpoints.down("xs")]: {
        flexWrap: "wrap",
      },
    },
    // "& .kg-what-we-area .kg-left-area": {
    //   width: "50%",
    //   float: "left",
    //   [theme.breakpoints.down("xs")]: {
    //     width: "100%",
    //   },
    // },
    "& .kg-what-we-area .kg-right-content": {
      width: "65%",
      float: "left",
      paddingLeft: "50px",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 0,
        width: "100%",
      },
    },
    "& .kg-what-we-area .kg-right-content ul li ": {
      fontSize: "18px",
      marginBottom: "12px",
      lineHeight: "24px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "16px",
        marginBottom: "10px",
        lineHeight: "22px",
      },
    },
    "& .kg-what-we-area .kg-right-content ul": {
      margin: 0,
    },
    "& .kg-what-we-inner .kg-main-area .kg-contant-image figure": {
      display: "block",
      maxWidth: "100%",
      height: "100%",
      width: "100%",
      objectFit: "contain",
      position: "relative",
      margin: "auto",
    },
    "&  .kg-what-we-inner .kg-main-area .kg-contant-image figure img": {
      position: "absolute",
      top: "0",
      bottom: "0",
      left: "0",
      right: "0",
      objectFit: "contain",
      objectPosition: "center",
      width: "100%",
      height: "100%",
      display: "inline-block",
    },
    "& .kg-what-we-inner .kg-main-area .kg-contant-image figure::after": {
      paddingBottom: "175%",
      content: "''",
      position: "relative",
      width: "100%",
      display: "block",
    },
    "& .kg-what-we-inner .kg-main-area .kg-contant-image": {
      width: "35%",
      display: "inline-block",
    },
    "& .kg-what-we-area .kg-body-images": {
      width: "50%",
      textAlign: "end",
      float: "right",
      "@media screen and (max-width:767px)": {
        width: "100%",
        textAlign: "center",
        marginBottom: "40px",
      },
    },
    "& .kg-what-we-area .kg-body-images .kg-contant-image": {
      width: "45%",
      display: "inline-block",
    },
    "& .kg-what-we-area .kg-body-images .kg-contant-image figure": {
      display: "block",
      maxWidth: "100%",
      height: "100%",
      width: "100%",
      objectFit: "contain",
      position: "relative",
      margin: "auto",
    },
    "& .kg-what-we-area .kg-body-images .kg-contant-image figure img": {
      position: "absolute",
      top: "0",
      bottom: "0",
      left: "0",
      right: "0",
      objectFit: "contain",
      objectPosition: "center",
      width: "100%",
      height: "100%",
      display: "inline-block",
      " @media screen and (max-width:991px) ":{
        objectPosition: "right",
      }
    },
    "&  .kg-what-we-area .kg-body-images .kg-contant-image figure::after": {
      paddingBottom: "150%",
      content: "''",
      position: "relative",
      width: "100%",
      display: "block",
      "@media screen and (max-width:1299px) ":{
        paddingBottom: "180%"
      }
    },
    "& .kg-what-we-area > div": {
      width: "50%",
      display: "inline-block",
      "@media screen and (max-width:767px)": {
        width: "100%",
      }
    },
  },
  reversemob: {
    display: "block",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  divImage: {
    display: "block",
    height: "100%",
    objectFit: "cover",
    position: "relative",
    verticalAlign: "middle",
    marginLeft: "auto !important",
    "& img": {
      "@media (max-width:1024px)": {
        objectFit: "contain",
      },
    },
    "&:after": {
      "@media (max-width:767px)": {
        paddingBottom: "600px",
      },
      paddingBottom: "60%",
      content: '""',
      position: "relative",
      display: "block",
      width: "100%",
    },
    // [theme.breakpoints.down("md")]: {
    //   "&:after": {
    //     paddingBottom: "52%",
    //   },
    // },
    // [theme.breakpoints.down("xs")]: {
    //   "&:after": {
    //     paddingBottom: "52%",
    //   },
    // },
    // [theme.breakpoints.down("sm")]: {
    //   "&:after": {
    //     paddingBottom: "54%",
    //   },
    // },
  },
  banner: {
    height: "100%",
    width: "100%",
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    objectFit: "cover",
    objectPosition: "center",
    width: "100%",
    height: "100%",
    display: "inline-block",
  },
  bannerGrid: {
    maxWidth: "1000px",
  },
  contentGrid: {},
  listItemButton: {
    float: "right",
    marginBottom: "15px",
    borderRadius: "unset",
    color: "#0e44ff",
    borderColor: "#0e44ff",
  },
  description: {
    fontWeight: 600,
  },
  typography: {
    marginLeft: "20px",
    color: "#0e44ff",
  },
  imageBody: {
    width: "100%",
    maxWidth: "100%",
    // [theme.breakpoints.down("sm")]: {
    //   maxWidth: "100% !important",
    //   width: "100%",
    //   margin: "auto",
    // },
    "@media (min-width:1884px)": {
      maxWidth: "100% !important",
    },
  },
}));

const ProvidusBody = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const post = props.post;
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Grid container justify="space-between" className={classes.reversemob}>
        <Grid
          item
          alignItems="center"
          xs={12}
          sm={9}
          md={9}
          lg={9}
          xl={9}
          data-aos={"fade-up"}
          className={classes.imageBody}
        >
          <Grid
            item
            alignItems="center"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            data-aos={"fade-up"}
            className={classes.bannerGrid}
          >
            <Hidden smDown>
              <div className={classes.divImage}>
                {post?.bodyimage?.mediaItemUrl ? (
                  <img
                    className={classes.banner}
                    src={post?.bodyimage?.mediaItemUrl}
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="2000"
                  />
                ) : (
                  <Skeleton
                    variant="rectangular"
                    className={classes.banner}
                    animation="wave"
                  />
                )}
              </div>
            </Hidden>
            <Hidden mdUp>
              <div className={classes.divImage}>
                {post?.mobileimage?.mediaItemUrl ? (
                  <img
                    className={classes.banner}
                    src={post?.mobileimage?.mediaItemUrl}
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="2000"
                  />
                ) : (
                  <Skeleton
                    variant="rectangular"
                    className={classes.banner}
                    animation="wave"
                  />
                )}
              </div>
            </Hidden>
          </Grid>
          <div class="kg-what-we-area">
            <div
              dangerouslySetInnerHTML={{
                __html: post?.bodycontent,
              }}
            />
            <div className="kg-body-images">
              <div className="kg-contant-image">
                <figure>
                  <img
                    className={`kg-image-1`}
                    src={`/images/mobile-5.png`}
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="2000"
                  />
                </figure>
              </div>
              <div className="kg-contant-image">
                <figure>
                  <img
                    className={`kg-image-2`}
                    src={`/images/mobile-3.png`}
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="2000"
                  />
                </figure>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

ProvidusBody.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default ProvidusBody;
