import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import "./Services.css";
import { useMediaQuery } from "@mui/material";
import { Carousel } from "./components";

const useStyles = makeStyles((theme) => ({
  consultantTitle: {
    paddingLeft: "120px",
    paddingRight: "105px",
    paddingBottom: "40px",
    paddingTop: "105px",
    color: "#202f43",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "20px",
      paddingLeft: "0",
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "20px",
      paddingLeft: "0",
    },
  },
  servicesbox: {
    background: "#f2f3f8",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingTop: "60px",
      paddingBottom: "60px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingTop: "60px",
      paddingBottom: "60px",
    },
  },
}));

const Services = (props) => {
  const isXxtraLargeScreen = useMediaQuery("(min-width:2400px)");
  const isXlxtraLargeScreen = useMediaQuery("(min-width:1980px)");
  const isxtraLargeScreen = useMediaQuery("(max-width:1800px)");
  const isLargeScreen = useMediaQuery("(max-width:1440px)");

  const classes = useStyles();

  const { classNameName, ...rest } = props;
  const navigate = useNavigate();

  const post = props.servicesName;

  if(isXxtraLargeScreen){
    var style = { width: "36.8%", height: 600 };
  }else if(isXlxtraLargeScreen){
    var style = { width: 900, height: 600 }; 
  }else{
    var style = { width: 700, height: 600 };
  }

  const styless = { width: 500, height: 500 };
  const rowMarkup = post?.map((item, index) => {
    var backgroundColor =
      item?.node?.connectedNode?.node?.services?.backgroundColor === null
        ? "#f9f9f9"
        : item?.node?.connectedNode?.node?.services?.backgroundColor;
    var color =
      item?.node?.connectedNode?.node?.services?.backgroundColor === null
        ? "#000"
        : "";
    var contentcolor =
      item?.node?.connectedNode?.node?.services?.backgroundColor === null
        ? "#000"
        : "#fff";
    var image = (image =
      item?.node?.connectedNode?.node?.featuredImage?.node?.mediaItemUrl);
    var content = item?.node?.connectedNode?.node?.services?.homePageContent;
    var btnclr =
      item?.node?.connectedNode?.node?.services?.backgroundColor === null
        ? "#1B4BF1"
        : "#fff";

    if (item?.node?.label == "Custom App") {
      image =
        item?.node?.connectedNode?.node?.services?.typesOfServices[0]
          ?.serviceImage?.mediaItemUrl;
    }

    if (item?.node?.label == "Creative") {
      backgroundColor = "#f9f9f9";
      contentcolor = "#000";
      color = "#000";
      btnclr = "#1B4BF1";
      image =
        item?.node?.connectedNode?.node?.services?.typesOfServices[0]
          ?.serviceImage?.mediaItemUrl;
    }
    return (
      <div className="kg-slider-wrap-area" key={index} style={style}>
        <div className="kg-inner-slider">
          <div className="kg-row" style={{ backgroundColor: backgroundColor }}>
            <div className="kg-left-area">
              <div className="kg-inner-left">
                <h2 style={{ color: color }}>{item?.node?.label}</h2>
                <p>
                  <Typography
                    dangerouslySetInnerHTML={{ __html: content }}
                    style={{ fontSize: "1rem", color: contentcolor }}
                  ></Typography>
                </p>
                <a
                  href="#"
                  style={{ border: "2px solid " + btnclr, color: btnclr }}
                  className="kg-leran-more-btn"
                  onClick={() =>
                    navigate(`${item?.node?.connectedNode?.node?.uri}`)
                  }
                >
                  learn more
                </a>
              </div>
            </div>
            <div className="kg-right-area">
              <div className="kg-inner-right">
                <figure>
                  <img src={image} alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className={classes.servicesbox}>
      <div className="kg-our_client_area">
        <Grid container justify="space-between">
          <Typography className={classes.consultantTitle} variant="h4">
            Our Services.
          </Typography>
        </Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {isXxtraLargeScreen ? (
            rowMarkup ? (
              <Carousel
                height={600}
                width={`100%`}
                xOrigin={1150}
                yOrigin={50}
                xRadius={1000}
                yRadius={50}
                autoPlay={true}
              >
                {rowMarkup}
              </Carousel>
            ) : (
              ""
            )
          ) : 
          isXlxtraLargeScreen ? (
            rowMarkup ? (
              <Carousel
              height={600}
              width={`100%`}
              xOrigin={1000}
              yOrigin={50}
              xRadius={800}
              yRadius={50}
              autoPlay={true}
              >
                {rowMarkup}
              </Carousel>
            ) : (
              ""
            )
          ):(
          isxtraLargeScreen ? (
            isLargeScreen ? (
              rowMarkup ? (
                <Carousel
                  height={600}
                  width={1100}
                  //xOrigin={650}
                  yOrigin={50}
                  //xRadius={600}
                  yRadius={50}
                  autoPlay={true}
                >
                  {rowMarkup}
                </Carousel>
              ) : (
                ""
              )
            ) : rowMarkup ? (
              <Carousel
                height={600}
                width={`100%`}
                //xOrigin={900}
                yOrigin={50}
                xRadius={600}
                yRadius={50}
                autoPlay={true}
              >
                {rowMarkup}
              </Carousel>
            ) : (
              ""
            )
          ) : rowMarkup ? (
            <Carousel
              height={600}
              width={`100%`}
              xOrigin={900}
              yOrigin={50}
              xRadius={750}
              yRadius={50}
              autoPlay={true}
            >
              {rowMarkup}
            </Carousel>
          ) : (
            ""
          )
          )
          }
        </div>
        <div style={{ height: "80px" }}></div>
      </div>
    </div>
  );
};

Services.propTypes = {
  /**
   * External classNamees
   */
  classNameName: PropTypes.string,
};

export default Services;
