import React from "react";
import {
  useQuery,
  gql,
} from "@apollo/client";
import {
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { Paper, CssBaseline } from "@material-ui/core";
import { Main as MainLayout, Minimal as MinimalLayout } from "./layouts";

import {
  Home as HomeView,
  About as AboutView,
  Blog as BlogView,
  BlogListing as BlogListingView,
  BlogListingCategory as BlogListingCategoryView,
  Services as ServicesView,
  Team as TeamView,
  CaseStudies as CaseStudiesView,
  Contact as ContactView,
  PlatForms as PlatFormsView,
  OurWork as OurWorkView,
  ThankYou as ThankYouView,
  SoftwareDevelopment as SoftwareDevelopmentView,
} from "./views";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import AOS from "aos";

import theme from "./theme";
import { Context } from "./Context.js";
// import Routes from "./Routes";

import "./theme/AktivGrotesk/fonts.css";
import "react-lazy-load-image-component/src/effects/opacity.css";
import PageNotFound from "./404";
// import "aos/dist/aos.css";

/* Google Tag Manager */
//import TagManager from "react-gtm-module";

// Set up Google Analytics inside our project, GA needs to be initialized. This needs to be done before any of the other tracking functions will record any data.
// import ReactGA from "react-ga4";
// // const TRACKING_ID = "G-3SNKMGKWLV"; // OUR_TRACKING_ID
// ReactGA.initialize(TRACKING_ID);
// const tagManagerArgs = {
//    gtmId: "GTM-T5TMCGC",
//    //gtmId: "G-3SNKMGKWLV"
//   };
// TagManager.initialize(tagManagerArgs);

// const browserHistory = createBrowserHistory();

// browserHistory.listen((location) => {
//   const hash = window.location.hash

//   // Use setTimeout to make sure this runs after React Router's own listener
//   setTimeout(() => {
//     // Keep default behavior of restoring scroll position when user:
//     // - clicked back button
//     // - clicked on a link that programmatically calls `history.goBack()`
//     // - manually changed the URL in the address bar (here we might want
//     // to scroll to top, but we can't differentiate it from the others)
//     if (window.location.action === "POP") {
//       return
//     }
//     // In all other cases, scroll to top
//     window.scrollTo(0, 0)

//     // Scroll to from other pages to home page ids
//     if (window.location.hash) {
//       // Fragment exists
//       setTimeout(() => {
//         const id = hash.replace("#", "")
//         const element = document.getElementById(id)
//         const options = {
//           behavior: "smooth",
//           duration: 2300,
//         }
//         if (element) {
//           element.scrollIntoView(options)
//         }
//       }, 1500)
//     } else {
//       // Issue with Safari subscribe form not loading on /blog pages when hitting the back button, so just refresh the browser in this case.
//       if (window.location.href.indexOf("blog") > -1) {
//         //alert("..")
//         window.location.reload(false)
//       }
//     }
//   })
// })

const App = () => {
  // useEffect(() => {
  //   ReactGA.send("pageview");
  //   ReactGA.send({
  //     hitType: "pageview",
  //     page: window.location.pathname + window.location.search,
  //   });
  // }, []);
  // const [context, setContext] = useState();
  var menuId = null;
  const getKeyByValue = (object, value) => {
    return Object.keys(object).find((key) => object[key] === value);
  };

  const location = useLocation();
  var current_menu_id = "";
  var GET_MENU = gql`
    {
      menu(id: "dGVybTozNg==") {
        menuItems(first: 100) {
          nodes {
            id
            label
            path
            parentDatabaseId
            databaseId
            childItems {
              edges {
                node {
                  id
                  label
                  path
                  databaseId
                }
              }
            }
          }
        }
      }
    }
  `;

  const { loading, errors, data } = useQuery(GET_MENU);
  var menuItems = data?.menu?.menuItems;

  // console.log(menuItems, "menuItems");
  var CurrentMenuArray = [];
  {
    menuItems?.nodes.map(function (menu) {
      CurrentMenuArray[menu.databaseId] = menu.path;
    });
  }

  // console.log(location, "location");
  var current_url = location.pathname;
  if (current_url.substr(-1) != "/") {
    current_url = current_url + "/";
  }
  current_menu_id = getKeyByValue(CurrentMenuArray, current_url);
  if (current_menu_id == "1595") {
    current_menu_id = "1625";
  }
  if (current_menu_id) {
    menuId = current_menu_id;
    //setContext(current_menu_id);
    // localStorage.setItem("menuId", JSON.stringify(current_menu_id));
  }

  return (
    <Context.Provider value={menuId}>
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <Paper>
        <Routes>
          <Route path="" element={<MainLayout />}>
            <Route path="/" element={<HomeView />} />
            <Route path="/ourwork" element={<OurWorkView />} />
            <Route path="/about" element={<AboutView />} />
            <Route path="/blog" element={<BlogView />} />
            <Route path="/:slug" element={<BlogListingView />} />
            <Route
              path="/blog/:category"
              element={<BlogListingCategoryView />}
            />
            <Route path="/services/:category" element={<ServicesView />} />
            <Route path="/ourwork/:category" element={<CaseStudiesView />} />
            <Route path="/platforms/:category" element={<PlatFormsView />} />
            <Route path="/thank-you" element={<ThankYouView />} />
            <Route path="notfound" element={<PageNotFound />} />
            <Route path="*" element={<Navigate to="/notfound" replace />} />
            <Route path="/standard-software-development-agreement" element={<SoftwareDevelopmentView />} />
          </Route>
          <Route path="/team" element={<MinimalLayout><TeamView /></MinimalLayout>} />
          <Route path="/contact" element={<MinimalLayout><ContactView /></MinimalLayout>} />
        </Routes>
      </Paper>
    </ThemeProvider>
    </Context.Provider>
  );
};

export default App;
