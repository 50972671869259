import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "60px",
    "& .MuiGrid-root.section-header__subtitle-wrapper.MuiGrid-item.MuiGrid-grid-xs-12": {
      marginBottom: "24px",
    },
    "&  .kg-hero-content-area .kg-inner-hero-content h1": {
      fontSize: "48px",
      lineHeight: "56px",
      marginTop: "0",
      marginBottom: "30px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "38px",
        marginTop: "0",
        marginBottom: "24px",
        lineHeight: "46px",
      },
    },
    "& .kg-inner-hero-content p": { 
      maxWidth: "650px" 
    },
    "& .kg-hero-content-area .kg-inner-hero-content p.kg-full-content": {
      fontSize: "18px",
      fontWeight: "600",
      marginBottom: "0",
      marginTop: "24px",
      display: "inline-block",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
        marginTop: "22px",
      },
    },
    "& .kg-hero-content-area": {
      maxWidth: "1000px",
      marginTop: "40px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "0",
      },
    },
    "& .kg-hero-content-area .kg-inner-hero-content h2": {
      "@media (max-width:1024px)": {
        fontSize: "1.5rem",
      },
      fontSize: "3.75rem",
      marginBottom: "0.35em",
      marginTop: 0,
    },
    "& .kg-hero-content-area .kg-inner-hero-content p.kg-full-content ": {
      fontSize: "23px",
    },
  },
  CreativeWorkHeader: {
    position: "relative",
    top: "-100px",
  },
}));

const TccHeader = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const post = props.post;
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Grid container justify="space-between">
        <Grid
          item
          container
          alignItems="center"
          xs={12}
          md={9}
          lg={9}
          xl={9}
          className={classes.CreativeWorkHeader}
          data-aos={"fade-up"}
        >
          <div class="kg-hero-content-area">
            <div class="kg-inner-hero-content">
              <div
                dangerouslySetInnerHTML={{
                  __html: post?.headercontent,
                }}
              ></div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

TccHeader.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default TccHeader;
