import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .kg-software-development-agreement-wrap .container": {
      // maxWidth: "1200px",
      margin: "auto",
      padding: "0 15px",
      background: "#fff",
      marginTop: "10px",
      marginBottom: "10px",
      paddingTop: "3px",
      paddingBottom: "30px",
      maxWidth: "calc(100% - 240px)",
      "@media (max-width: 1180px)": {
        maxWidth: "calc(100% - 60px)",
      },
    },
    "& .kg-software-development-agreement-wrap .kg-term-condition-wrap .kg-inside-term-wrap":
      {
        overflowX: "hidden",
        // backgroundColor: "rgb(246, 246, 246)",
        backgroundColor: "#f2f3f8",

      },
    "& .kg-software-development-agreement-wrap": {
      position: "relative",
      padding: "170px 0 60px",
      background: "#f2f3f8",
      "@media (max-width: 1180px)": {
        padding: "100px 0 60px",
      },
    },

    "& .kg-software-development-agreement-wrap .kg-term-condition-wrap .kg-inside-term-wrap .kg-main-term-wrap .kg-box-wrap-area":
      {
        padding: "6px",
        border: "0.75pt solid #000000",
        display: "inline-block",
        maxWidth: "700px",
      },
    "& .kg-software-development-agreement-wrap .kg-term-condition-wrap .kg-inside-term-wrap .kg-main-term-wrap .kg-box-wrap-area span":
      {
        fontWeight: 700,
        fontSize: "14px",
      },
    "& .kg-software-development-agreement-wrap .kg-term-condition-wrap .kg-inside-term-wrap .kg-main-term-wrap ol.kg-main-content":
      {
        paddingLeft: "46px",
        "@media only screen and (max-width:767px)": {
          paddingLeft: "20px",
        },
      },
    "& .kg-software-development-agreement-wrap .kg-term-condition-wrap .kg-inside-term-wrap .kg-main-term-wrap ol.kg-main-content > li > b":
      {
        fontSize: "18px",
      },
    "& .kg-software-development-agreement-wrap .kg-term-condition-wrap .kg-inside-term-wrap .kg-main-term-wrap ol.kg-main-content > li::before":
      {
        fontSize: "18px",
        fontWeight: 700,
        " @media only screen and (max-width:767px)": {
          fontSize: "16px",
          marginLeft: "-20px",
        },
      },
    "& .kg-software-development-agreement-wrap .kg-term-condition-wrap .kg-inside-term-wrap .kg-main-term-wrap ol.kg-main-content > li > ol > li::before":
      {
        fontWeight: 700,
      },
    "& .kg-software-development-agreement-wrap .kg-term-condition-wrap .kg-inside-term-wrap .kg-main-term-wrap .kg-box-wrap-area p":
      {
        margin: "0",
        lineHeight: "normal",
        fontSize: "14px",
      },
    "& .kg-software-development-agreement-wrap .kg-term-condition-wrap .kg-inside-term-wrap .kg-main-term-wrap ol":
      {
        counterReset: "item",
      },
    "& .kg-software-development-agreement-wrap .kg-term-condition-wrap .kg-inside-term-wrap .kg-main-term-wrap .kg-sub-content-wraps":
      {
        counterReset: "none",
      },
    "& .kg-software-development-agreement-wrap .kg-term-condition-wrap .kg-main-term-wrap ol li:before":
      {
        position: "absolute",
        top: "0",
        marginLeft: "-46px",
        content: 'counters(item, ".") " "',
        counterIncrement: "item",
        "@media only screen and (max-width:767px)": {
          marginLeft: "-32px",
        },
      },
    "& .kg-software-development-agreement-wrap .kg-term-condition-wrap .kg-inside-term-wrap .kg-main-term-wrap ol li":
      {
        display: "block",
        position: "relative",
        fontSize: "16px",
        marginTop: "12px",
        marginBottom: "12px",
      },
    "& .kg-software-development-agreement-wrap .kg-term-condition-wrap .kg-inside-term-wrap .kg-main-term-wrap ol ol":
      {
        padding: "0",
        paddingLeft: "45px",
        margin: "0 !important",
        "@media only screen and (max-width:767px)": {
          paddingLeft: "20px",
        },
      },
    "& .kg-software-development-agreement-wrap .kg-term-condition-wrap .kg-inside-term-wrap .kg-main-term-wrap ol.kg-lower-alpa li:before":
      {
        display: "none",
      },
    "& .kg-software-development-agreement-wrap .kg-term-condition-wrap .kg-inside-term-wrap .kg-main-term-wrap ol.kg-lower-alpa li":
      {
        marginLeft: "24px",
      },
    "& .kg-software-development-agreement-wrap .kg-term-condition-wrap .kg-inside-term-wrap .kg-main-term-wrap ol.kg-lower-alpa":
      {
        listStyle: "none",
        counterReset: "awlistcounter7_2",
        marginLeft: "20px",
      },
    "& .kg-software-development-agreement-wrap .kg-term-condition-wrap .kg-inside-term-wrap .kg-main-term-wrap li .kg-lower-alpa > li:before":
      {
        content: '"(" counter(awlistcounter7_2, lower-latin) ")"',
        counterIncrement: "awlistcounter7_2",
        display: "block",
      },
    "& .kg-software-development-agreement-wrap .kg-term-condition-wrap .kg-inside-term-wrap .kg-main-term-wrap .kg-main-content  li ol.kg-number-bullet > li:before":
      {
        content: '"(" counter(awlistcounter7_3, lower-roman) ")"',
        counterIncrement: "awlistcounter7_3",
        display: "block",
      },
    "& .kg-software-development-agreement-wrap .kg-term-condition-wrap .kg-inside-term-wrap .kg-main-term-wrap .kg-main-content  li ol.kg-number-bullet":
      {
        listStyle: "none",
        counterReset: "awlistcounter7_3",
        marginLeft: "20px",
      },
    "& .kg-software-development-agreement-wrap .kg-term-condition-wrap .kg-inside-term-wrap .kg-main-term-wrap .kg-main-content  ol.kg-upper-alpha > li:before":
      {
        content: '"(" counter(awlistcounter7_4, upper-latin) ")"',
        counterIncrement: "awlistcounter7_4",
        display: "block",
      },
    "& .kg-software-development-agreement-wrap .kg-term-condition-wrap .kg-inside-term-wrap .kg-main-term-wrap .kg-main-content  ol.kg-upper-alpha":
      {
        listStyle: "none",
        counterReset: "awlistcounter7_4",
        marginLeft: "20px",
      },
    "& .kg-software-development-agreement-wrap .kg-term-condition-wrap .kg-inside-term-wrap .kg-main-term-wrap .kg-main-heading-wrap":
      {
        fontSize: "18px",
        fontWeight: "normal",
        borderBottom: "0.75pt solid #000000",
        marginTop: "6px",
        marginBottom: "12px",
      },
    "& .kg-software-development-agreement-wrap .kg-sub-content-wrap": {
      paddingLeft: "65px",
    },
    "& .kg-software-development-agreement-wrap .kg-inside-term-wrap .kg-top-content h1":
      {
        textAlign: "center",
        marginBottom: "36px",

        // fontSize: "78px",
        // marginBottom: "24px",
        // textTransform: "capitalize",
        // fontWeight: "bold",
        // color: "#202f43",
        // textAlign: "left",
        "@media only screen and (max-width:767px)": {
          fontSize: "20px",
        },
      },
    "& .kg-software-development-agreement-wrap .kg-inside-term-wrap .kg-top-content p":
      {
        marginBottom: "20px",
      },
    // "& .kg-software-development-agreement-wrap .kg-term-condition-wrap .kg-inside-term-wrap": {
    //   backgroundColor: "rgb(246, 246, 246)"
    // },
    // "& .kg-software-development-agreement-wrap .container": {
    //   background: "#fff",
    //   marginTop: "10px",
    //   marginBottom: "10px",
    //   paddingTop: "3px",
    //   paddingBottom: "30px"
    // }
  },
}));

const Hero = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const post = props?.pageContent?.page;
  const pageTitle = props?.pageTitle;
  return (
    <div className={clsx(classes.root, className, "kg_home_hero")} {...rest}>
      {/* <h1>SOFTWARE DEVELOPMENT AGREEMENT</h1> */}
      <div class="kg-software-development-agreement-wrap">
        <div class="kg-inner-wrap">
          <div class="kg-main-page-container">
            <div class="kg-term-condition-wrap">
              <div class="kg-inside-term-wrap">
                <div class="container">
                  {post?.content && post?.content !== null ? (
                    <div dangerouslySetInnerHTML={{ __html: post.content }} />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Hero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Hero;
