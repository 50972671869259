import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Section } from "../../components/organisms";
import { Header,  Items, SubscribeBottom } from "./components";
import { gql } from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { blogDescription, thumbnail } from "../../Context";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "hsl(230deg 30% 96%)",
    height: "100%",
    width: "100%",
  },
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(5),
    },
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
  shape: {},
  subscribeBottomPaddingTop: {
    backgroundColor: "#f9f9f9",
    paddingLeft: "90px",
    paddingRight: "90px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  },
  pageHeaderPaddingTop: {
    paddingTop: "122px",
    paddingLeft: "120px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
    },
  },
  pageItemsPaddingTop: {
    paddingLeft: "120px",
    paddingTop: 0,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
    },
  },
}));

const BlogListingCategory = () => {
  const classes = useStyles();
  const location = useLocation();
  const { category } = useParams();
  const GET_CATEGORY_POSTS = gql`
    {
      posts(where: {categoryName: "${category}"}) {
        nodes {
          id
          title
          uri
          slug
          date
          content
          author {
            node {
              name
            }
          }
          featuredImage {
            node {
              altText
              sourceUrl
            }
          }
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_CATEGORY_POSTS);

  if (loading) return <p>Loading Posts...</p>;
  // if (error) return <p>An error occured!</p>;
  // document.title = `${category}`
  return (
    <div className={classes.root}>
      <Helmet>
        <title>{category}</title>
        <meta name="description" content={`${blogDescription}`} />
        <meta name="title" property="og:title" content={`${category}`} />
        <meta name="description" property="og:description" content={`${blogDescription}`} />
        <meta name="image" property="og:image" content={`${thumbnail}`} />
      </Helmet>
      <Section className={classes.pageHeaderPaddingTop }>
        <Header />
      </Section>
      <Section className={classes.pageItemsPaddingTop}>
        <Items post={data.posts} />
      </Section>
      <Section className={classes.subscribeBottomPaddingTop}>
        <SubscribeBottom />
      </Section>
    </div>
  );
};

export default BlogListingCategory;
