import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Box,
  TextField,
  Button,
  Snackbar,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import Axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    "&": {
      padding: "110px 120px",
      "@media only screen and (max-width:1023px)": {
        padding:"60px 0px",
        paddingBottom:"0",
      },
    },
  },
  heading :{
    fontSize: "66px",
    lineHeight: "72px",
    marginBottom: "44px",
    fontWeight: 500,
    color: "#fff",
    marginTop: "200px",
    height: "max-content",
    "@media only screen and (max-width:1023px)": {
      padding: "0 20px",
      marginTop: "50px"
    },
   
  },
  headingh5 :{
   fontSize: "66px",
    lineHeight: "72px",
    marginBottom: "44px",
    fontWeight: 500,
    color: "#fff",
    "@media only screen and (min-width:1921px)": {
      fontSize: "5rem",
    },
    "@media only screen and (max-width:1023px)": {
        marginTop: "50px",
        fontSize: "38px",
        lineHeight: "44px",
        marginBottom: "30px"
    },
  },
  subline:{
    fontSize: "20px",
    maxWidth: "750px",
    letterSpacing: "1px",
    color: "#fff",
    fontWeight: 600,
    lineHeight: "36px",
    "@media only screen and (min-width:1921px)": {
      fontSize: "1.5rem",
    },
    "@media only screen and (max-width:1023px)": {
      fontSize: "18px",
      lineHeight: "32px",
      maxWidth: "100%"
    },
  },
  form:{
    padding: "6% 10%",
    background: "#fff",
    borderRadius: "24px",
    height: "100%",
    position: "relative",
    top: "180px",
    maxWidth:"80%",
    marginLeft:"auto",
    zIndex: 1,
    border: "1px solid #1B4BF1",
    "@media only screen and (max-width:1440px)": {

      maxWidth:"90%"
    },
    "@media only screen and (max-width:1023px)": {
      top: 0,
      padding: "5% 4%",
      borderRadius: 0,
      width: "100%",
      maxWidth:"100%",
      border: 0,
      paddingBottom:"50px",
    },
  },
  textfiled:{
    width: "100%",
    marginBottom: "20px",
    "& label":{
      color: "#0e44ff",
      fontWeight:700,
    },
    "& .MuiInput-formControl":{
      borderBottom: "2px solid #0e44ff",
    } 
  },
  messageButton:{
    color: "#0e44ff",
    border: "2px solid #0e44ff",
    display: "block",
    padding: "10px 36px",
    marginTop: "35px",
    textTransform: "capitalize",
    backgroundColor: "transparent",
    borderRadius: 0,
    "&:hover":{
      color: "#fff",
      boxShadow: "inset 0 0 0 2em #0e44ff",
      transition: ".6s",
    }
  }
}));

const AuditBody = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const post = props.pageContent?.node?.services.typesOfServices;

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState();
  const [color, setColor] = useState();
  const navigate = useNavigate();
  const handleClose = (event, reason) => {
    if ("clickaway" == reason) return;
    setOpen(false);
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const onSubmit = (data) => {
    const post_data = JSON.stringify({
      properties: [
        {
          property: "email",
          value: data.email,
        },
        {
          property: "firstname",
          value: data.first_name,
        },
        {
          property: "lastname",
          value: data.last_name,
        },
        {
          property: "phone",
          value: data.phone,
        },
        {
          property: "company",
          value: data.company,
        },
        {
          property: "budget",
          value: ''
        },
        {
          property: "project_details",
          value: data.project_details,
        },
      ],
    });

    Axios.post("https://backend.apprato.com.au/Ses.php", post_data, {
      headers: {
        accept: "application/json"
      },
      mode: "cors"
    })
      .then(res => {
        if (res.data.success) {
          reset();
          setOpen(true);
          setMessage(res.data.response);
          setColor("success");
          navigate("/thank-you");
        } else {
          setOpen(true);
          setColor("error");
          setMessage(res.data.response);
        }
      })
      .catch(error => {
        setMessage(error);
        console.error(error);
      });

  };

  return (
    <>
      <div
        className={clsx(classes.root, className, "kg_audit_container")}
        {...rest}
        style={{ backgroundColor: `#1B4BF1` }}
      >
        <Grid
          container
          direction="row"
          justify="space-between"
        >
          <Grid
            item
            container
            justify="flex-start"
            xs={12}
            md={6}
            lg={6}
            xl={6}
            className={classes.heading + " " + "kg_content_hero"}
          >
            <Typography variant="h5" display="block" className={classes.headingh5}>
              Ready to Take The First Step?
            </Typography>
            <Typography variant="body1" className={classes.subline}>
              <div
                className="page-content"
                dangerouslySetInnerHTML={{
                  __html:
                    "Apprato is more than an eCommerce agency. We’re your full-stack developer, project manager, designer, practitioner, and software architect all rolled into one. We’ll provide you with every insight you need to improve the customer experience and gain a razor-sharp edge over the competition. Take the first step toward eCommerce growth today. ",
                }}
              ></div>
            </Typography>
          </Grid>
          <Grid
            item
            container
            alignItems="center"
            xs={12}
            md={6}
            lg={6}
            xl={6}
            className={classes.messageform}
          >
            <Grid item md={12} className={classes.form}>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className={classes.div}>
                  <TextField
                    id="standard-required"
                    label="First name*"
                    placeholder="First name"
                    multiline
                    className={classes.textfiled + " first_name"}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    {...register("first_name", { required: true })}
                    helperText={
                      errors.first_name && errors.first_name.type === "required"
                        ? "First name is required *"
                        : ""
                    }
                  />

                  <TextField
                    id="standard-required"
                    label="Last name*"
                    placeholder="Last name"
                    multiline
                    className={classes.textfiled + " last_name"}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    {...register("last_name", { required: true })}
                    helperText={
                      errors.last_name && errors.last_name.type === "required"
                        ? "Last name is required *"
                        : ""
                    }
                  />

                  <TextField
                    id="standard-required"
                    label="Company name*"
                    placeholder="Company name "
                    multiline
                    className={classes.textfiled + " company"}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    {...register("company", { required: true })}
                    helperText={
                      errors.company && errors.company.type === "required"
                        ? "Company is required *"
                        : ""
                    }
                  />
                </div>

                <div className={classes.div}>
                  <TextField
                    id="standard-required"
                    label="Email*"
                    placeholder="Email"
                    multiline
                    className={classes.textfiled + " email"}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    {...register("email", {
                      required: true,
                      pattern: /^\S+@\S+$/i,
                    })}
                    helperText={
                      errors.email && errors.email.type === "required"
                        ? "Email is required *"
                        : ""
                    }
                  />

                  {errors.email && errors.email.type === "pattern" && (
                    <span className={classes.errorMEssage}>
                      Enter a valid e-mail address
                    </span>
                  )}

                  <TextField
                    id="standard-required"
                    label="Telephone number (optional)"
                    placeholder="Telephone number"
                    multiline
                    className={classes.textfiled + " phone"}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    {...register("phone", { required: false })}
                  />
                 
                </div>
                <div className={classes.div}>
                  <TextField
                    id="standard-required"
                    label="Project details (optional)"
                    placeholder="Project details"
                    multiline
                    className={classes.textfiled + " project-details"}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    {...register("project_details", { required: false })}
                  />
                </div>
                <div className={classes.kgSendMessageButton}>
                  <Button
                    type="submit"
                    className={classes.messageButton}
                    style={{ width: "202px" }}
                  >
                    send message
                  </Button>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={color} sx={{ width: "100%" }}>
            {message && message != "" ? message.message : ""}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

AuditBody.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default AuditBody;
