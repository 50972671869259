import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { Section } from "../../components/organisms";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    width: "100%",
    backgroundColor:"#fff",
    "& .kg-top-title h1": {
      fontSize: "3.75rem",
      marginBottom: "0.7em",
      lineHeight: "normal",
      marginTop: 0,
      "@media (max-width: 1023px)": {
        fontSize: "1.5rem"
      }
    },
    "& .kg-thank-you p": {
      fontSize: "1.1667rem",
      marginTop: 0,
      marginBottom: "1.3em",
      maxWidth: "700px",
      "@media (max-width: 1023px)": {
        maxWidth: "100%",
        fontSize: "1rem",
        marginBottom: " 1.1em"
      }
    },
    " & .kg-top-title h1 br": {
      "@media (max-width: 1023px)": {
        display: "none"
      }
    }
  },

  headerPaddingTop: {
    backgroundColor: "#f9f9f9",
    paddingTop: "122.23px",
    paddingBottom: "140px",
    paddingTop: "210px",
    paddingLeft: "120px",
    paddingRight: "120px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingBottom: "20px",
      paddingTop: "170px"
    }
  },
  images: {
    position: "absolute",
    top: "0",
    right: "0",
    left: "0",
    bottom: "0",
    objectFit: "cover",
    objectPosition: "center",
    width: "100%",
    height: "100%",
    display: "inline-block",
    maxWidth: "100%",
    objectFit: "contain"
  },
  imageroot: {
    display: "block",
    height: "100%",
    objectFit: "cover",
    position: "relative",
    margin: "auto",
    overflow: "hidden",
    "&:after": {
      paddingBottom: "70%",
      content: '""',
      position: "relative",
      display: "block",
      width: "100%",
      "@media (max-width: 1023px)": {
        paddingBottom: "65% !important"
      },
      "@media only screen and (max-width:1920px)": {
        paddingBottom: "485px"
      }
    },
    "@media (max-width: 959.95px)": {
      marginBottom: "20px"
    },
    "@media (max-width: 1023px)": {
      order: 1,
      marginBottom: "50px"
    }
  },
  mainContent: {
    "@media (max-width: 1023px)": {
      order: 2
    }
  }
}));

const ThankYou = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Section className={classes.headerPaddingTop}>
        <div className={classes.ThankYou}>
          <Grid
            container
            justify="space-between"
            className={classes.reversemob}
          >
            <Grid
              item
              alignItems="center"
              md={7}
              className={classes.mainContent}
              data-aos={"fade-up"}
            >
              <div className={classes.contentArea}>
                <div class="kg-thank-you">
                  <div class="kg-top-title">
                    <h1>
                      THANK YOU <br /> FOR YOUR ENQUIRY
                    </h1>
                  </div>
                  <p>
                    One of our team members will be in contact shortly to offer
                    solutions for your project
                  </p>
                  <p>We look forward to working with you.</p>
                  <p>Apprato Team</p>
                </div>
              </div>
            </Grid>
            <Grid
              item
              container
              justify="flex-start"
              alignItems="center"
              xs={12}
              md={5}
              lg={5}
              xl={5}
              className={classes.imageroot}
            >
              <img
                src={`/images/thankyou.png`}
                alt=""
                className={classes.images}
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
              />
            </Grid>
          </Grid>
        </div>
      </Section>
    </div>
  );
};

export default ThankYou;
