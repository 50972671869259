import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { gql } from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { Link, useNavigate } from "react-router-dom";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingLeft: "112px",
    paddingRight: "112px",
    backgroundColor: "hsl(230deg 30% 96%)",
    paddingTop: "80px",
    paddingBottom: "80px",
    "& a": {
      textDecoration: "none",
    },
    "& a:hover": {
      textDecoration: "none",
    },

    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    "& h6": {
      // margin: "1.5em 0",
      fontSize: "32px",
      fontWeight: 700,
      paddingBottom: "50px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "24px !important",
      },
    },
  },
  items: {
    backgroundColor: "#FFFFFF",
    boxShadow: "none",
  },
  card: {
    backgroundColor: "#f2f3f8",
  },
  cardContainer: {
    padding: "0 15px",
    // [theme.breakpoints.down("md")]: {
    //   flexGrow: "0",
    //   maxWidth: "49%",
    //   flexBasis: "49%",
    // },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "30px",
      // justifyContent: "center !important",
      flexGrow: "0",
      maxWidth: "100% !important",
      flexBasis: "100% !important",
    },
    [theme.breakpoints.down("sm")]: {
      flexGrow: "0",
      padding: 0,
      marginBottom: "15px",
      maxWidth: "49%",
      flexBasis: "49%",
    },
  },
  heading: {
    "& h2": {
      [theme.breakpoints.down("md")]: {
        margin: "1.5em 0 0",
      },
      [theme.breakpoints.down("sm")]: {
        margin: "1em 0 0",
      },
    },
    // "& h6": {
    //   fontWeight: 700,
    //   margin: "1.5em 0",
    //   [theme.breakpoints.down("sm")]: {
    //     margin: "1em 0 0",
    //   },
    // },
    [theme.breakpoints.up("xs")]: {
      paddingRight: "3rem",
      paddingBottom: "3rem",
    },
    [theme.breakpoints.up("md")]: {
      paddingRight: "3rem",
      paddingBottom: "3rem",
    },
    [theme.breakpoints.up("lg")]: {
      paddingRight: "7rem",
      paddingBottom: "3rem",
    },
    [theme.breakpoints.up("xl")]: {
      paddingRight: "3rem",
      paddingBottom: "3rem",
    },
  },
  image: {
    maxWidth: "80%",
    height: "auto",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "4rem",
    },
    [theme.breakpoints.down("lg")]: {
      paddingLeft: "2rem",
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "0",
      margin: "0 auto",
    },
  },
  reversemob: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
    },
  },
  categories: {
    margin: "5rem",
    backgroundColor: "#000000",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
      margin: "5rem",
      backgroundColor: "#000000",
    },
  },
  title2: {
    color: "#0e44ff",
    textDecoration: "none",
    fontWeight: "600",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "1rem",
    },
  },
  title1: {
    fontWeight: "600",
    paddingBottom: "25px !important",
    fontSize: "58px !important",
    marginBottom: "0",
    [theme.breakpoints.down("lg")]: {
      fontSize: "2rem",
      padding: "0 8px",
    },
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "38px !important",
    },
  },
  link: {
    width: "100%",
    "& :hover": {
      background: "#f2f3f8",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    // [theme.breakpoints.down("s")]: {
    //   width: "100%",
    // },
  },
  titleContainer: {
    alignItems: "center",
    // padding: "0 8px",
    marginBottom: "35px",
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
      padding: "0",
      marginBottom: "24px",
      padding: "0 8px",
    },
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      padding: "0",
      marginBottom: "24px",
    },
  },
  typography: {
    marginBottom: "0",
    textAlign: "right",
  },
  breadcrumbsa: {
    color: "#202f43",
  },
  breadContainer: {
    marginBottom: "7px",
  },
  CardActions: {
    padding: "16px",
  },
  imgContainer: {
    display: "block",
    height: "100%",
    objectFit: "cover",
    position: "relative",
    verticalAlign: "middle",
    marginLeft: "auto !important",
    "&:after": {
      paddingBottom: "50%",
      content: '""',
      position: "relative",
      display: "block",
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      "&:after": {
        paddingBottom: "50%",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "&:after": {
        paddingBottom: "50%",
      },
    },
  },
  CardMedia: {
    position: "absolute",
    top: "0",
    right: "0",
    left: "0",
    bottom: "0",
    objectFit: "cover",
    objectPosition: "center",
    width: "100%",
    height: "100%",
    display: "inline-block",
  },
  a: {
    color: "#202f43",
  },
  title1Container: {
    maxWidth: "50%",
    flexBasis: "50%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      flexBasis: "100%",
    },
  },
  title2Container: {
    maxWidth: "50%",
    flexBasis: "50%",
  },
}));

const Platforms = (props) => {
  const GET_PLATEFORMS = gql`
    {
      menuItem(id: "1595", idType: DATABASE_ID) {
        id
        label
        childItems {
          edges {
            node {
              id
              label
              databaseId
              connectedNode {
                node {
                  ... on Page {
                    id
                    featuredImage {
                      node {
                        mediaItemUrl
                      }
                    }
                    uri
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
  const classes = useStyles();
  const { loading, error, data } = useQuery(GET_PLATEFORMS);
  const platforms = data?.menuItem.childItems.edges;

  const navigate = useNavigate();
  if (loading) return <p>Loading Posts...</p>;
  if (error) return <p>An error occured!</p>;

  const rowMarkup = platforms?.map((item, index) => {
    return (
      <>
        {item ? (
          <Grid
            item
            container
            justify="flex-start"
            xs={12}
            md={4}
            lg={4}
            xl={4}
            data-aos={"fade-up"}
            className={classes.cardContainer}
          >
            <Link
              to={`${item.node.connectedNode.node.uri}`}
              className={classes.link}
            >
              <Card
                sx={{ maxWidth: 345 }}
                variant="outlined"
                className={classes.items}
              >
                <div className={classes.imgContainer}>
                  {item?.node?.connectedNode?.node?.featuredImage ? (
                    <>
                      <CardMedia
                        className={classes.CardMedia}
                        component="img"
                        height="194"
                        image={
                          item?.node?.connectedNode?.node?.featuredImage?.node
                            ?.mediaItemUrl
                        }
                        alt={item.node.label}
                      />
                    </>
                  ) : (
                    <>
                      <CardMedia
                        className={classes.CardMedia}
                        component="img"
                        height="194"
                        image="/images/placeholder.png"
                        alt={item.node.label}
                      />
                    </>
                  )}
                </div>
              </Card>
            </Link>
          </Grid>
        ) : (
          ""
        )}
      </>
    );
  });

  return (
    <div className={clsx(classes.root)}>
      <Grid container xs={12} className={classes.titleContainer}>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          className={classes.title1Container}
        >
          <Typography variant="h6" className={classes.title1} gutterBottom>
            Our Platforms.
          </Typography>
        </Grid>
      </Grid>

      {rowMarkup ? (
        <Grid container justify="space-between" className={classes.reversemob}>
          {rowMarkup}
        </Grid>
      ) : (
        ""
      )}
    </div>
  );
};

Platforms.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Platforms;
//export default graphql(getPosts)(Items) // Bind the query to the compoent to have access to the variables in the return.
