import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 10000, min: 2880 },
    items: 2,
    slidesToSlide: 2,
    partialVisibilityGutter: 0,
  },
  desktop: {
    breakpoint: { max: 2880, min: 1024 },
    items: 2,
    slidesToSlide: 2,
    partialVisibilityGutter: 0,
  },
  mobile: {
    breakpoint: { max: 1024, min: 0 },
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 0,
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& h6": {
      "@media (min-width:1280px)": {
        fontSize: "1.5rem",
      },
      "@media (min-width: 960px)": {
        fontSize: "22px",
      },
    },
    "& .slick-initialized .slick-slide": { padding: "0 15px" },
    "& .slick-dots li.slick-active button": {
      background: "#35363a",
      borderColor: "#35363a"
    },
    "& .slick-dots li:hover button": {
      background: "#35363a",
      borderColor: "#35363a"
    },
    "& .slick-dots li button": {
      background: "#b3b3b3",
      display: "inline-block",
      width: "12px",
      height: "12px",
      borderRadius: "50%",
      opacity: 1,
      boxShadow: "none",
      transition: "background .5s",
      border: "2px solid #b3b3b3",
      padding: "0",
      margin: "0 6px 0 0",
      outline: "0",
      cursor: "pointer"
    },
    "& .slick-slider ul.slick-dots": { bottom: "0" },
    "& .slick-slider ul.slick-dots li": {
      width: "auto",
      height: "auto",
      margin: "0",
      padding: "0"
    },
    "& .slick-slider ul.slick-dots li button:before": { display: "none" },
    "& .slick-slider .slick-list": { paddingBottom: "3em" }
  },
  imageroot: {
    display: "block",
    cursor: "pointer",
    maxWidth: "100%",
    flexBasis: "100%",
    height: "100%",
    objectFit: "cover",
    position: "relative",
    verticalAlign: "middle",
    marginLeft: "auto !important",
    "&:after": {
      content: '""',
      paddingBottom: "68.5%",
      position: "relative",
      display: "block",
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      "&:after": {
        paddingBottom: "90.5%",
      },
    },
    [theme.breakpoints.down("xs")]: {
      "&:after": {
        paddingBottom: "90.5%",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "&:after": {
        paddingBottom: "90.5%",
      },
    },
  },
  images: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "0",
    right: "0",
    left: "0",
    bottom: "0",
    objectFit: "cover",
    objectPosition: "center",
    display: "inline-block",
    [theme.breakpoints.down("xs")]: {
      borderRadius: "4px",
    },
    [theme.breakpoints.down("sm")]: {
      borderRadius: "4px",
    },
  },
  titleContainer: {
    margin: "0",
    paddingTop: "20px",
    cursor: "pointer",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      textTransform: "uppercase",
    },
    [theme.breakpoints.down("sm")]: {
      textTransform: "uppercase",
    },
  },
  consultantTitle: {
    fontSize: "3.125rem !important",
    // color:"#091d35",
    paddingBottom: "20px",
    paddingLeft: "15px",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "20px",
      paddingLeft: "0",
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "20px",
      paddingLeft: "0",
    },
    "@media (min-width: 960px)": {
      fontSize: "2.5625rem",
      paddingBottom: "50px !important",
    },
  },
  carouselItem: {
    padding: "0 15px",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  CarouselSlider: {
    paddingBottom: 0,
  },
  slider: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "30px",
    },
  },
  subTitle: {
    color: "#202f43 !important",
    marginLeft: "24px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "14px",
    },
  },
  carouselDot: {
    // [theme.breakpoints.up("xs")]: {
    //   display: "flex",
    //   justifyContent: "center",
    //   position: "absolute",
    //   bottom: 0,
    //   marginBottom: "30px",
    // },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      position: "absolute",
      bottom: "0 !important",
    },
  },
  expertbox: {
    // background: "#4b69d6",
    paddingLeft: "105px",
    paddingRight: "105px",
    paddingTop: "80px",
    paddingBottom: "80px",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
      backgroundColor: "#1948e8",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
      // backgroundColor: "#1948e8",
    },
  },
  button: {
    borderRadius: "0",
    padding: "10px 20px",
    color: "#fff",
    borderColor: "#fff",
    borderWidth: "2px",
    backgroundColor: "transparent",
    marginTop: "34px",
    [theme.breakpoints.down("xs")]: {
      padding: "6px 8px",
      margin: 0,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "6px 8px",
      margin: 0,
    },
    "&:hover": {
      boxShadow: "inset 0 0 0 2em #1948e8",
      color: "#fff",
      transition: ".6s",
    },
  },
  buttonContainer: {
    marginTop: "34px",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  typoh6: {
    color: "#202f43 !important",
    fontWeight: 600,
  },
  // image: {
  //   padding: 0,
  //   textAlign: "center",
  //   display: "unset",
  //   [theme.breakpoints.down("md")]: {
  //     padding: "3em 1em 1em",
  //   },
  //   [theme.breakpoints.down("sm")]: {
  //     padding: "1.5em 0.5em 0.5em",
  //   },
  // },
  // )
}));

const DevelopmentWork = (props) => {
  var settings = {
    // autoplaySpeed: 5000,
    speed: 500,
    autoplay: true,
    infinite: true,
    arrows: false,
    dots: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 786,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  };
  const navigate = useNavigate();
  const { className, ...rest } = props;
  const casestudies = props.casestudies;
  const classes = useStyles();
  var developedData = [];
   casestudies?.map((item, index) => {
    const item_work = item.node.connectedNode.node.caseStudies.workType;
    if (item_work === "Development Work") {
      developedData.push(item);
    }
  });
  const rowMarkup = developedData?.map((item, index) => {
    return (
      <>
        {item ? (
          <Grid container justify="space-between">
            <Grid
              item
              container
              justify="flex-start"
              alignItems="center"
              xs={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.imageroot + " " + "hello"}
              onClick={() => navigate(item.node.connectedNode.node.uri)}
            >
              {item?.node?.connectedNode?.node?.featuredImage ? (
                <>
                  <img
                    src={
                      item?.node?.connectedNode?.node?.featuredImage?.node
                        ?.mediaItemUrl
                    }
                    alt=""
                    className={classes.images}
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="2000"
                  />
                </>
              ) : (
                <>
                  <img
                    src="/images/placeholder.png"
                    className={classes.images}
                    alt="Jordan Pearce"
                  />
                </>
              )}
            </Grid>

            <Grid
              item
              container
              spacing={2}
              className={classes.titleContainer}
              onClick={() => navigate(item.node.connectedNode.node.uri)}
            >
              <Typography
                variant="h6"
                style={{ color: "#fff" }}
                className={classes.typoh6}
              >
                {item.node.label}
              </Typography>
              <Typography
                variant="body1"
                className={classes.subTitle}
                style={{ color: "#fff" }}
              >
                {item.node.connectedNode.node.caseStudies.framework}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          ""
        )}
      </>
    );
  });

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
      name="about"
      id="about"
    >
      <div className={classes.expertbox}>
        <Grid container justify="space-between">
          <Typography className={classes.consultantTitle} variant="h4">
            Development Work
          </Typography>
        </Grid>

        {rowMarkup ? (
          <Slider {...settings}>
            {rowMarkup}
          </Slider>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

DevelopmentWork.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default DevelopmentWork;
