import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Box,
  TextField,
  Button,
  Snackbar
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import { Image } from "../../../../components/atoms";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { useForm } from "react-hook-form";
import Axios from "axios";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiInputLabel-formControl": {
      color: "#0e44ff",
      padding: "0 30px",
      [theme.breakpoints.down("sm")]: {
        padding: 0
      }
      // [theme.breakpoints.down("md")]: {
      //   color: "#0e44ff",
      //   padding: "0 30px",
      // },
    },
    "& .MuiInputLabel-formControl": {
      color: "#0e44ff",
      padding: "0 30px",
      [theme.breakpoints.down("sm")]: {
        padding: 0
      }
    },

    "& .MuiInputBase-root.MuiInput-root.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-multiline.MuiInput-multiline": {
      borderBottom: "2px solid #0e44ff"
    },
    "& .project-details": {
      width: "66.66%",
      marginTop: "10px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        marginTop: 0
      }
    },
    "& p#standard-required-helper-text": {
      fontSize: "1rem",
      color: "#D8000C"
    }
  },
  messageForm: {
    color: "#0e44ff",
    paddingLeft: "120px",
    paddingRight: "120px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px"
    }
  },
  kgSendMessageButton: {
    color: "#0e44ff",
    padding: "0 30px",
    [theme.breakpoints.down("sm")]: {
      padding: 0
    }
  },
  messageButton: {
    display: "block",
    marginLeft: "auto",
    marginTop: "50px",
    backgroundColor: "transparent",
    padding: "10px 36px",
    color: "#0e44ff",
    border: "2px solid #0e44ff",
    borderRadius: "unset",
    textTransform: "capitalize",
    "&:hover": {
      boxShadow: "inset 0 0 0 2em #0e44ff",
      color: "#fff",
      transition: ".6s"
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "auto",
      marginTop: "10px",
      padding: "6px 18px"
    }
  },
  textfiled: {
    width: "33.33%",
    marginRight: 0,
    padding: "0 30px",
    "& .MuiInputLabel-formControl": {
      "@media (max-width:1440px)": {
        transform: "translate(0, 8px) scale(1)"
      }
    },
    "& #outlined-textarea-label": {},
    "& .MuiOutlinedInput-root .MuiInput-underline": {
      borderColor: "green"
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: 0,
      padding: 0,
      marginBottom: "40px",
      padding: 0
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginRight: 0,
      padding: 0,
      marginBottom: "40px",
      padding: 0
    }
  },
  div: {
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      marginTop: 0
    },
    position: "relative",
    "& span": {
      position: "absolute",
      left: "30px",
      top: "60px",
      color: "#D8000C",
      [theme.breakpoints.down("sm")]: {
        left: 0
      }
    }
  },
  title: {
    color: "#0e44ff",
    marginBottom: "20px",
    padding: "0 30px",
    [theme.breakpoints.down("sm")]: {
      padding: 0
    }
  },
  form: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  messageForms: {
    paddingLeft: "90px",
    paddingRight: "90px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px"
    }
  }
}));

const MessageForm = props => {
  const { className, ...rest } = props;
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState();
  const [color, setColor] = useState();
  const navigate = useNavigate();
  const handleClose = (event, reason) => {
    if ("clickaway" == reason) return;
    setOpen(false);
  };
  const classes = useStyles();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm();
  const onSubmit = data => {
    const post_data = JSON.stringify({
      properties: [
        {
          property: "email",
          value: data.email
        },
        {
          property: "firstname",
          value: data.first_name
        },
        {
          property: "lastname",
          value: data.last_name
        },
        {
          property: "phone",
          value: data.phone
        },
        {
          property: "company",
          value: data.company
        },
        {
          property: "budget",
          value: data.budget
        },
        {
          property: "project_details",
          value: data.project_details
        }
      ]
    });

    fetch("https://backend.apprato.com.au/Hubspot.php", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer pat-na1-49d476c7-aa94-4d5f-81bb-e38d1e8892aa",
      },
      body: post_data,
    })
    .then(response => response.json())
    .then(data => {
      // Handle the response data here
    })
    .catch(error => {
      console.error('Error:', error);
    });


    Axios.post("https://backend.apprato.com.au/Ses.php", post_data, {
      headers: {
        accept: "application/json"
      },
      mode: "cors"
    })
      .then(res => {
        if (res.data.success) {
          reset();
          setOpen(true);
          setMessage(res.data.response);
          setColor("success");
          navigate("/thank-you");
        } else {
          setOpen(true);
          setColor("error");
          setMessage(res.data.response);
        }
      })
      .catch(error => {
        setMessage(error);
        console.error(error);
      });
  };

  const email = {};
  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
      name="messageForm"
      id="messageForm"
    >
      <Grid container justify="space-between" className={classes.messageForms}>
        <Grid item md={8} sx={12} className={classes.title}>
          <Typography variant="h4">
            Ready to grow your on-line business?
          </Typography>
          <Typography variant="h4">Let's talk.</Typography>
        </Grid>
        <Grid item md={12} className={classes.form}>
          <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.div}>
              <TextField
                id="standard-required"
                label="First name"
                placeholder="First name"
                multiline
                className={classes.textfiled + " first_name"}
                InputProps={{
                  disableUnderline: true
                }}
                {...register("first_name", { required: true })}
                helperText={
                  errors.first_name && errors.first_name.type === "required"
                    ? "First name is required *"
                    : ""
                }
              />

              <TextField
                id="standard-required"
                label="Last name"
                placeholder="Last name"
                multiline
                className={classes.textfiled + " last_name"}
                InputProps={{
                  disableUnderline: true
                }}
                {...register("last_name", { required: true })}
                helperText={
                  errors.last_name && errors.last_name.type === "required"
                    ? "Last name is required *"
                    : ""
                }
              />

              <TextField
                id="standard-required"
                label="Company name"
                placeholder="Company name "
                multiline
                className={classes.textfiled + " company"}
                InputProps={{
                  disableUnderline: true
                }}
                {...register("company", { required: true })}
                helperText={
                  errors.company && errors.company.type === "required"
                    ? "Company is required *"
                    : ""
                }
              />
            </div>

            <div className={classes.div}>
              <TextField
                id="standard-required"
                label="Email"
                placeholder="Email"
                multiline
                className={classes.textfiled + " email"}
                InputProps={{
                  disableUnderline: true
                }}
                {...register("email", {
                  required: true,
                  pattern: /^\S+@\S+$/i
                })}
                helperText={
                  errors.email && errors.email.type === "required"
                    ? "Email is required *"
                    : ""
                }
              />

              {errors.email && errors.email.type === "pattern" && (
                <span className={classes.errorMEssage}>
                  Enter a valid e-mail address
                </span>
              )}

              <TextField
                id="standard-required"
                label="Telephone number"
                placeholder="Telephone number"
                multiline
                className={classes.textfiled + " phone"}
                InputProps={{
                  disableUnderline: true
                }}
                {...register("phone", { required: true })}
                helperText={
                  errors.phone && errors.phone.type === "required"
                    ? "Phone is required *"
                    : ""
                }
              />

              <TextField
                id="standard-required"
                label="Budget"
                placeholder="Budget"
                multiline
                className={classes.textfiled + " budget"}
                InputProps={{
                  disableUnderline: true
                }}
                {...register("budget", { required: true })}
                helperText={
                  errors.budget && errors.budget.type === "required"
                    ? "Budget is required *"
                    : ""
                }
              />
            </div>
            <div className={classes.div}>
              <TextField
                id="standard-required"
                label="Tell us a bit about your project "
                placeholder="Project details"
                multiline
                className={classes.textfiled + " project-details"}
                InputProps={{
                  disableUnderline: true
                }}
                {...register("project_details", { required: true })}
                helperText={
                  errors.project_details &&
                  errors.project_details.type === "required"
                    ? "Project details is required *"
                    : ""
                }
              />
            </div>
            <div className={classes.kgSendMessageButton}>
              <Button
                type="submit"
                className={classes.messageButton}
                style={{ width: "202px" }}
              >
                send message
              </Button>
            </div>
          </Box>
        </Grid>
      </Grid>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={color} sx={{ width: "100%" }}>
          {message && message != "" ? message.message : ""}
        </Alert>
      </Snackbar>
    </div>
  );
};

MessageForm.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string
};

export default MessageForm;
