import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, TextField, Button } from "@material-ui/core";
import { SectionHeader } from "../../../../components/molecules";
import { Description } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    // "& input#filled-search": {
    //   color: "#0e44ff",
    //   padding: "0 30px",
    //   [theme.breakpoints.down("sm")]: {
    //     padding: 0,
    //   },
    // },
    "& .makeStyles-toolbar-101": {
      display: "none",
    },
    "& .makeStyles-gridContainer-192": {
      display: "flex",
    },
    "& .kg-audit-methodology .kg-audit-methodology-list ul": {
      listStyle: "none",
      padding: "0",
      margin: "0",
      display: "flex",
      flexWrap: "wrap",
    },
    "& .kg-audit-methodology .kg-audit-methodology-list ul li": {
      fontWeight: "600",
      lineHeight: "1.43",
      marginBottom: "0.35em",
      width: "50%",
      paddingRight: "10px",
    },
    "& .kg-audit-methodology  h1": {
      "@media (max-width:1440px)": {
        fontSize: "2.75rem",
      },
      fontSize: "3.75rem",
      marginBottom: "0.35em",
      marginTop: "0",
    },
    "& .kg-audit-methodology p": {
      "@media (max-width:1180px)": {
        fontSize: "1rem",
      },
      "@media (max-width: 959.95px)": {
        fontWeight: "400",
        lineHeight: "24px",
      },
      fontSize: "1.1667rem",
      maxWidth: "610px",
      fontFamily: "AktivGrotesk",
      marginTop: "0",
      lineHeight: "33px",
    },
    "& .kg-audit-methodology .kg-action-btn": {
      marginTop: "40px",
      justifyContent: "flex-start",
      display: "flex",
      fontFamily: "AktivGrotesk-Medium",
      fontWeight: "500",
      lineHeight: "1.75",
    },
    "& .kg-audit-methodology .kg-action-btn  a.kg-view-our-work-btn": {
      fontFamily: "AktivGrotesk-Medium",
      fontWeight: "500",
      lineHeight: "1.75",
      fontSize: "1rem",
      minWidth: "64px",
      color: "#0257ff",
      border: "2px solid #0257ff",
      padding: "10px 30px",
      borderRadius: "0",
      textDecoration: "none",
    },
    "& .kg-audit-methodology .kg-top-title h1": {
      "@media (max-width:1180px)": {
        fontSize: "2.75rem",
      },
    },
    "& .kg-audit-methodology .kg-top-title h1": {
      "@media (max-width:991px)": {
        fontSize: "2rem",
      },
    },
    "&  .kg-audit-methodology .kg-audit-methodology-list ul li": {
      "@media (max-width:767px)": {
        width: "100%",
      },
    },
    "& .MuiInputBase-root.MuiInput-root.makeStyles-textfield-57.MuiInputBase-fullWidth.MuiInput-fullWidth.MuiInputBase-formControl.MuiInput-formControl": {
      borderBottom: "2px solid #0e44ff",
    },
    "& .MuiInputBase-root.MuiInput-root.MuiInputBase-fullWidth.MuiInput-fullWidth.MuiInputBase-formControl.MuiInput-formControl ": {
      borderBottom: "2px solid",
    },
    "& .MuiGrid-root.MuiGrid-item.MuiGrid-align-items-xs-center.MuiGrid-grid-md-6.aos-init.aos-animate": {
      width: "100%",
      maxWidth: "100%",
    },
    // "& .MuiGrid-root.jss51.MuiGrid-item.MuiGrid-align-items-xs-center": {
    //   display: "flex",
    // },
    "& .MuiTypography-root.MuiTypography-body1.MuiTypography-gutterBottom": {
      marginBottom: "40px",
    },
    "& .MuiGrid-root.MuiGrid-container.MuiGrid-justify-content-xs-center": {
      justifyContent: "flex-start",
      marginTop: "40px",
      [theme.breakpoints.down("xs")]: {
        paddingBottom: "40px",
      },
    },
    "& a.MuiButtonBase-root.MuiButton-root.MuiButton-outlined ": {
      border: "2px solid #0257ff",
      color: "#0257ff",
      padding: "10px 30px",
      borderRadius: 0,
    },
    "& .MuiTypography-root.MuiTypography-body1.MuiTypography-gutterBottom": {
      maxWidth: "610px",
      fontWeight: 600,
    },
    "& .MuiTypography-root.MuiTypography-body2.MuiTypography-gutterBottom": {
      fontWeight: 600,
    },
    "& .kg_audit_content": {
      display: "block",
    },
    "& h6": {
      display: "block",
      width: "100%",
      fontSize: "24px",
      margin: "0",
      color: "#202f43",
      marginTop: "30px",
      marginBottom: "25px",
    },
    "& .kg_audit ul": {
      display: "flex",
      flexWrap: "wrap",
      paddingLeft: "15px",
      marginBottom: "75px",
    },
    "& .kg_audit ul li": {
      width: "50%",
      paddingRight: "30px",
      fontWeight: "600",
      marginBottom: "6px",
      "@media (max-width:767px)": {
        width: "100%",
      },
    },
  },
  textfield: {
    marginBottom: "1rem",
    color: "#0e44ff",
    // background: "#FFF",
    // backgroundColor: "#FFF",
    input: {
      // color: "#0e44ff",
      // background: "##0e44ff",
      borderBottomColor: "#0e44ff",
    },
    // "&:hover": {
    //   background: "#FFF",
    // },
    // "&:visited": {
    //   background: "#FFF",
    // },
    // "&:active": {
    //   background: "#FFF",
    // },
  },
  heading: {
    "& h2": {
      [theme.breakpoints.down("md")]: {
        margin: "1.5em 0 0",
      },
      [theme.breakpoints.down("sm")]: {
        margin: "1em 0 0",
      },
    },
    "& h6": {
      margin: "1.5em 0",
      [theme.breakpoints.down("sm")]: {
        margin: "1em 0 0",
      },
    },
    [theme.breakpoints.up("md")]: {
      paddingRight: "3rem",
    },
    [theme.breakpoints.up("lg")]: {
      paddingRight: "12rem",
    },
    [theme.breakpoints.up("xl")]: {
      paddingRight: "23rem",
    },
  },
  images: {
    position: "absolute",
    top: "0",
    right: "0",
    left: "0",
    bottom: "0",
    objectFit: "cover",
    objectPosition: "center",
    width: "100%",
    height: "100%",
    display: "inline-block",
    maxWidth: "100%",
    objectFit: "contain",
    // height: "auto",
    // [theme.breakpoints.up("lg")]: {
    //   paddingLeft: "4rem",
    // },
    // [theme.breakpoints.down("lg")]: {
    //   paddingLeft: "2rem",
    // },
    // [theme.breakpoints.down("md")]: {
    //   paddingLeft: "0",
    //   margin: "0 auto",
    // },
  },
  imageroot: {
    display: "block",
    height: "100%",
    objectFit: "cover",
    position: "relative",
    margin: "auto",
    overflow: "hidden",
    "&:after": {
      paddingBottom: "85%",
      content: '""',
      position: "relative",
      display: "block",
      width: "100%",
    },
    "@media (max-width: 959.95px)": {
      marginBottom: "20px",
    },
  },
  reversemob: {
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  subscription: {
    backgroundColor: "#000",
    "& h2": {
      [theme.breakpoints.down("md")]: {
        margin: "1.5em 0 0",
      },
      [theme.breakpoints.down("sm")]: {
        margin: "1em 0 0",
      },
    },
    "& h6": {
      margin: "1.5em 0",
      [theme.breakpoints.down("sm")]: {
        margin: "1em 0 0",
      },
    },
    [theme.breakpoints.up("xs")]: {
      paddingRight: "3rem",
      paddingBottom: "3rem",
    },
    [theme.breakpoints.up("md")]: {
      paddingRight: "3rem",
      paddingBottom: "3rem",
    },
    [theme.breakpoints.up("lg")]: {
      paddingRight: "7rem",
      paddingBottom: "3rem",
    },
    [theme.breakpoints.up("xl")]: {
      paddingRight: "3rem",
      paddingBottom: "3rem",
    },
  },
  workTogether: {
    backgroundColor: "#f2f3f8",
    padding: "2rem",
  },
  body: {
    marginTop: "60px",
    display: "flex",
    // margin: 0,
  },
  right: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  subscribeRight: {
    marginLeft: "20px",
    display: "inline-block",
    padding: "2rem",
    backgroundColor: "#f2f3f8",
  },
  subscribeRightText: {
    color: "#FFF",
  },
  content: {
    paddingTop: "1rem",
    paddingLeft: 0,
    paddingRight: 0,
  },
  divImage: {
    display: "block",
    height: "100%",
    objectFit: "cover",
    position: "relative",
    verticalAlign: "middle",
    marginLeft: "auto !important",
    "&:after": {
      paddingBottom: "40%",
      content: '""',
      position: "relative",
      display: "block",
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      "&:after": {
        paddingBottom: "70%",
      },
    },
    [theme.breakpoints.down("xs")]: {
      "&:after": {
        paddingBottom: "70%",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "&:after": {
        paddingBottom: "70%",
      },
    },
  },
  banner: {
    height: "100%",
    width: "100%",
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    objectFit: "cover",
    objectPosition: "center",
    width: "100%",
    height: "100%",
    display: "inline-block",
  },
  contentGrid: {},
  listItemButton: {
    float: "right",
    marginBottom: "15px",
    borderRadius: "unset",
    color: "#0e44ff",
    borderColor: "#0e44ff",
  },
  description: {
    fontWeight: 600,
  },
  typography: {
    marginLeft: "20px",
    color: "#0e44ff",
  },
  imageBody: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      width: "100%",
    },
  },
  gridContainer: {
    display: "flex",
    marginTop: "20px",
  },
  contentArea: {
    "& .kg-audit-methodology": {
      color: "#fff",
    },
  },
}));

const Body = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  // const post = props.post;

  // const menuId = useSelector((state) => state.menuId.menuid);
  // console.log(menuId , 'menuId');
  const pageContent = props.pageContent;
  if (props.loading) return <p>Loading Platforms...</p>;
  var contentTextColor = "";
  if (pageContent?.node?.services?.color) {
    contentTextColor = pageContent?.node?.services?.color;
  }
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {pageContent ? (
        <Grid container justify="space-between" className={classes.reversemob}>
          {/* <Grid
              item
              alignItems="center"
              xs={12}
              data-aos={"fade-up"}
              className={classes.imageBody}
            > */}
          <Grid item alignItems="center" md={6} data-aos={"fade-up"}>
            {contentTextColor ? (
              <div
                className={classes.contentArea}
                dangerouslySetInnerHTML={{ __html: pageContent?.node.content }}
              ></div>
            ) : (
              <div
                className="page-content"
                dangerouslySetInnerHTML={{ __html: pageContent?.node.content }}
              ></div>
            )}
          </Grid>
          <Grid
            item
            container
            justify="flex-start"
            alignItems="center"
            xs={12}
            md={6}
            lg={6}
            xl={6}
            className={classes.imageroot}
          >
            {pageContent?.node?.featuredImage ? (
              <img
                src={pageContent?.node?.featuredImage?.node.mediaItemUrl}
                alt=""
                className={classes.images}
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
              />
            ) : (
              <Skeleton
                variant="rectangular"
                className={classes.images}
                animation="wave"
              />
            )}
          </Grid>
        </Grid>
      ) : (
        ""
      )}

      {/* </Grid> */}
      {/* </Grid> */}
    </div>
  );
};

Body.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Body;
