import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Section } from "../../components/organisms";
import {
  Hero,
  Partner,
  Why,
  ContactForm,
  Platforms as PlatformsView,
  Blogs,
  Testimonials
} from "./components";
import { gql } from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import Cookies from "js-cookie";
import { Context, description, thumbnail, title } from "../../Context";
import { Helmet } from "react-helmet";
// import { useCookies } from 'react-cookie';

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    backgroundColor: "#fff",
  },
  sectionPaddingTop: {
    paddingTop: "40px",
    paddingBottom: "40px",
    paddingLeft: 0,
    paddingRight: 0,
  },
  pagePaddingBottom : {
    paddingTop: 0,
    paddingLeft: 0,
    paddingBottom: "40px",
    paddingRight: 0,
    paddingLeft: theme.spacing(0),
    [theme.breakpoints.up("md")]: {
      //paddingTop: theme.spacing(5)
      padding: 0,
      paddingTop: 0,
      paddingLeft: 0,
      paddingBottom:"40px" ,
      //paddingLeft: theme.spacing(16)
    },
  },
  // TestimonialsPaddingTop:{
  //   paddingTop :0
  // },
  pagePaddingTop: {
    //paddingTop: theme.spacing(3),
    paddingTop: 0,
    paddingLeft: 0,
    paddingBottom: 0,
    paddingRight: 0,
    paddingLeft: theme.spacing(0),
    [theme.breakpoints.up("md")]: {
      //paddingTop: theme.spacing(5)
      padding: 0,
      paddingTop: 0,
      paddingLeft: 0,
      paddingBottom: 0    ,
      //paddingLeft: theme.spacing(16)
    },
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  sectionNoPaddingBottom: {
    paddingBottom: 0,
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  sectionMarginBottom: {
    marginBottom: "20px",
    paddingTop: "80px",
  },
  shape: {},
}));

const PlatForms = () => {
  // var menuId = JSON.parse(localStorage.getItem("menuId"));
  // const menuId = useSelector(state => state.storagedata.menuId);

  const menuId = useContext(Context);
  // console.log(menuId ,"menuId");

  // const menuId = Cookies.get('menuId')
  const GET_MENU = gql`
  {
    menuItem(id: ${menuId}, idType: DATABASE_ID) {
      id
      label
      connectedNode {
        node {
          ... on Page {
            id
            platforms {
              color
              descPlatform
              titleOfPlatform
              typesOfPlateforms {
                backColor
                fieldGroupName
                nameOfPlatform
                platformInfo
                platformImage {
                  mediaItemUrl
                }
              }
              whatTitle
              whatColumn1
              whatColumn2
              whyTitle
              whyColumn1
              whyColumn2
              imageOfPlatform {
                imagePlatform {
                  mediaItemUrl
                  mediaDetails {
                    file
                    height
                    width
                  }
                }
                fieldGroupName
                nameOfPlatform
              }
              typesOfTestimonials {
                name
                profession
                reviewed
                testimonialsImage {
                  mediaItemUrl
                }
              }
            }
            metaDetails {
              customMetaDescription
              customMetaTitle
            }
            featuredImage {
              node {
                mediaItemUrl
              }
            }
          }
        }
      }
    }
  }
  
  `;
  const { loading, error, data } = useQuery(GET_MENU);
  const platforms = data?.menuItem.connectedNode;
  const typesOfTestimonials = data?.menuItem.connectedNode.node?.platforms?.typesOfTestimonials

  const classes = useStyles();
  const [width, setWidth] = useState(0);

  const metaDetails =data?.menuItem.connectedNode?.node?.metaDetails;
  const featuredImage =data?.menuItem.connectedNode?.node?.featuredImage;
  var pageTitle = data?.menuItem?.label;

  const helemetMeta = (
    <Helmet>
    <title>{pageTitle}</title>
    <meta name="description" content={`${metaDetails && metaDetails.customMetaDescription !== null ? metaDetails.customMetaDescription : description  }`} />
    <meta name="title" property="og:title" content={`${metaDetails && metaDetails.customMetaTitle !== null ? metaDetails.customMetaTitle : title  }`} />
    <meta name="description" property="og:description" content={`${metaDetails && metaDetails.customMetaDescription !== null ? metaDetails.customMetaDescription : description  }`} />
    <meta name="image" property="og:image" content={`${featuredImage && featuredImage !== null ? featuredImage.node.mediaItemUrl : thumbnail  }`} />
  </Helmet>
  )

  // const useWindowWide = (size) => {
  //   const [width, setWidth] = useState(0);

  //   useEffect(() => {
  //     function handleResize() {
  //       if (window.innerWidth <= size) {
  //         setWidth(true);
  //       } else {
  //         setWidth(false);
  //       }
  //     }

  //     window.addEventListener("resize", handleResize);

  //     handleResize();

  //     return () => {
  //       window.removeEventListener("resize", handleResize);
  //     };
  //   }, [setWidth]);

  //   return width;
  // };
  // const wide = useWindowWide(1020);
  // console.log(platforms);
  return (
    <div className={classes.root}>
      {helemetMeta}
      <Section className={typesOfTestimonials != null ? classes.pagePaddingTop : classes.pagePaddingBottom }>
        <Hero loading={loading} posts={platforms} />
      </Section>
      {
        typesOfTestimonials != null &&
        <Section className={classes.pagePaddingTop}>
            <Testimonials loading={loading} posts={platforms} />
        </Section>
      }
      <Section className={classes.sectionNoPaddingBottom}>
        <Partner loading={loading} posts={platforms} />
      </Section>
      <Section className={classes.sectionNoPaddingBottom}>
        <Why loading={loading} posts={platforms} />
      </Section>
      <Section className={classes.sectionNoPaddingBottom}>
        <PlatformsView />
      </Section>
      <Section className={classes.sectionMarginBottom}>
        <Blogs />
      </Section>
      <Section className={classes.sectionMarginBottom}>
        <ContactForm />
      </Section>
    </div>
  );
};

export default PlatForms;
