import React ,{useEffect , useLayoutEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import Slider from "react-slick";

const useStyles = makeStyles((theme) => ({
  root: {
    "& h6": {
      "@media (min-width:1280px)": {
        fontSize: "1.5rem",
      },
      "@media (min-width: 960px)": {
        fontSize: "22px",
      },
    },
    "& .slick-initialized .slick-slide": {
      padding: "0 15px",
    //   "@media (max-width:876px)": {
    //     padding: 0,
    //   },
    //   "@media (max-width:1023px)": {
    //     padding: 0,
    //   },
    },
    "& .slick-dots li.slick-active button": {
      background: "#37f",
      borderColor: "#37f",
    },
    "& .slick-dots li:hover button": {
      background: "#37f",
      borderColor: "#37f",
    },
    "& .slick-dots li button": {
      background: "#fff",
      display: "inline-block",
      width: "12px",
      height: "12px",
      borderRadius: "50%",
      opacity: 1,
      boxShadow: "none",
      transition: "background .5s",
      border: "2px solid #fff",
      padding: "0",
      margin: "0 6px 0 0",
      outline: "0",
      cursor: "pointer",
    },
    "& .slick-slider ul.slick-dots": { bottom: "0" },
    "& .slick-slider ul.slick-dots li": {
      width: "auto",
      height: "auto",
      margin: "0",
      padding: "0",
    },
    "& .slick-slider ul.slick-dots li button:before": { display: "none" },
    "& .slick-slider .slick-list": {
      paddingBottom: "3em",
      "@media (max-width: 1023.95px)": {
        paddingBottom: "30px",
      },
    },
    "& .kg-testimonial-content": {
      background: "#1c78f8",
      width: "100%",
      borderRadius: "30px",
      padding: "40px 30px 30px 45px",
      "@media screen and (max-width:1023px)": {
        padding: "30px 22px 22px 30px",
      },
      "@media screen and (max-width:767px)": {
        padding: "22px",
      },
    },
    "& .kg-img-wrap": {
      maxWidth: "56px",
      marginBottom: "30px",
      "@media screen and (max-width:767px)": {
        display: "flex",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "24px",
      },
    },
    "& .kg-img-wrap img": { width: "100%" },
    "& .kg-middle-content p": {
      fontSize: "1.4rem",
      color: "#fff",
      lineHeight: "36px",
      margin: "0",
      "@media screen and (max-width:1023px)": {
        fontSize: "1.125rem",
        lineHeight: "30px",
      },
      "@media screen and (max-width:767px)": {
        fontSize: "1rem",
        lineHeight: "24px",
        textAlign: "center",
      },
    },
    "& .kg-bottom-content": {
      display: "flex",
    //   flexWrap: "wrap",
      justifyContent: "space-between",
      "@media screen and (max-width:767px)": {
        justifyContent: "center",
        flexWrap: "wrap",
      },
    },
    "& .kg-left-content p": {
      color: "#75c4f0",
      fontSize: "1.2rem",
      fontWeight: 700,
      marginTop: "0",
      marginBottom: "10px",
      "@media (max-width: 1023px)": {
        fontSize: "1rem",
      },
    },
    "& .kg-middle-content": {
      marginBottom: "30px",
      height: "250px",
      paddingRight: "50px",
      "@media screen and (max-width:1023px)": {
        paddingRight: 0,
      },
      "@media screen and (max-width:767px)": {
        paddingRight: 0,
        height: "170px",
      },
    },
    "& .kg-right-profile figure": {
      margin: "0",
      maxWidth: "90px",
      "@media screen and (max-width:767px)": {
        maxWidth: "70px",
        margin: "auto",
      },
    },
    "& .kg-left-content": {
      "@media screen and (max-width:767px)": {
        textAlign: "center",
        order: 2,
        width: "100%",
      },
    },
    "&  .kg-right-profile": {
      "@media screen and (max-width:767px)": {
        order: 1,
        marginBottom: "15px",
        width: "70px",
      },
    },
    "& .kg-reviewed":{
        display: "-webkit-box",
        WebkitLineClamp: "5",
        WebkitBoxOrient: "vertical",
        overflow: "hidden"
    },
    "& .kg-right-profile figure img": {
      top: "0",
      left: "0",
      right: "0",
      width: "100%",
      bottom: "0",
      height: "100%",
      display: "inline-block",
      position: "absolute",
      objectFit: "cover",
      objectPosition: "center"
    },
    "& .kg-right-profile figure:after": {
      width: "100%",
      content: '""',
      display: "block",
      position: "relative",
      paddingBottom: "100%"
    },
    "& .kg-right-profile figure": {
      width: "100%",
      height: "auto",
      margin: "0",
      display: "block",
      position: "relative",
      objectFit: "cover",
      verticalAlign: "middle",
      borderRadius: "90px",
      overflow: "hidden"
    },
    "& .kg-right-profile": { display: "block", width: "90px" }
    
  },
  kgimages:{

  },
  images: {
    width: "100%",
  },
  consultantTitle: {
    paddingBottom: "20px",
    paddingLeft: "15px",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "20px",
      paddingLeft: "0",
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "20px",
      paddingLeft: "15px",
    },
    "@media (min-width: 960px)": {
      fontSize: "2.5625rem",
      paddingBottom: "50px !important",
    },
  },
  slider: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "30px",
    },
  },
  expertbox: {
    background: "#1b4bf1 ",
    paddingLeft: "105px",
    paddingRight: "105px",
    paddingTop: "80px",
    paddingBottom: "80px",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
      backgroundColor: "#1948e8",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "5px",
      paddingRight: "5px",
      backgroundColor: "#1948e8",
    },
    "@media (max-width: 767px)": {
      paddingTop: "40px",
      paddingBottom: "40px",
    },
  },
  button: {
    borderRadius: "0",
    padding: "10px 20px",
    color: "#fff",
    borderColor: "#fff",
    borderWidth: "2px",
    backgroundColor: "transparent",
    marginTop: "34px",
    [theme.breakpoints.down("xs")]: {
      padding: "6px 8px",
      margin: 0,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "6px 8px",
      margin: 0,
    },
    "&:hover": {
      boxShadow: "inset 0 0 0 2em #1948e8",
      color: "#fff",
      transition: ".6s",
    },
  },
  buttonContainer: {
    marginTop: "34px",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  
  typoh6: {},
}));

const Testimonials = (props) => {

  

  const post = props?.posts?.node?.platforms?.typesOfTestimonials;
  var slideNum = 2;
  if(post.length == 1){
    slideNum = 1;
  }

  var settings = {
    autoplaySpeed: 7000,
    focusOnSelect: true,
    speed: 500,
    autoplay: true,
    infinite: true,
    arrows: false,
    dots: true,
    slidesToShow: slideNum,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: slideNum,
          dots: true,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: slideNum,
          dots: true,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: slideNum,
          dots: true,
        },
      },
      {
        breakpoint: 876,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,   
        },
      },
    ],
  };

  const { className, ...rest } = props;
  const classes = useStyles();
  const rowMarkup = post?.map((item, index) => {
    return (
      <>
        {item ? (
          <Grid  container justify="space-between"  >
            <div className="kg-testimonial-content" >
              <div className="kg-inside-content">
                <div className="kg-img-wrap">
                  <img src="/images/quote.png" alt="" />
                </div>
                <div className="kg-middle-content">
                  <p className="kg-reviewed" >{item.reviewed != null ? item.reviewed : ""}</p>
                </div>
                <div className="kg-bottom-content">
                  <div className="kg-left-content">
                    <p>{item.name != null ? item.name : ""}</p>
                    <p>{item.profession != null ? item.profession : ""}</p>
                  </div>
                  <div className="kg-right-profile">
                    <figure className={classes.kgimages}>
                      {item?.testimonialsImage != null ? (
                        <>
                          <img
                            src={item?.testimonialsImage?.mediaItemUrl}
                            alt=""
                            className={classes.images}
                            data-aos-easing="ease-out-cubic"
                            data-aos-duration="2000"
                          />
                        </>
                      ) : (
                        <>
                          <img
                            src="/images/placeholder3.png"
                            className={classes.images}
                            alt="Jordan Pearce"
                          />
                        </>
                      )}
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        ) : (
          ""
        )}
      </>
    );
  });

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
      name="about"
      id="about"
    >
      <div className={classes.expertbox}>
        <Grid container justify="space-between">
          <Typography
            className={classes.consultantTitle}
            variant="h4"
            style={{ color: "#fff" }}
          >
            Testimonials
          </Typography>
        </Grid>
        {rowMarkup ? <Slider {...settings}>{rowMarkup}</Slider> : ""}
      </div>
    </div>
  );
};

Testimonials.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Testimonials;
