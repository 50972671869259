import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .kg-development-services-list": {
      paddingLeft: "20px",
      margin: "0",
      display: "flex",
      flexWrap: "wrap",
    },
    "& .kg_home_hero0": {
      padding: "230px 120px;",
      alignItems: "center",
      paddingBottom: "0",
      [theme.breakpoints.down("sm")]: {
        padding: "60px 20px !important",
      },
    },
    "& .kg_home_hero0 .kg_content_hero": {
      "@media (max-width:1023px)": {
        order: "1",
      },
      paddingBottom: "30px",
    },
    "& .kg_home_hero1 figure:after": {
      paddingBottom: "68.3%",
    },
    "& .kg_home_hero2": {
      padding: "100px 120px",
      [theme.breakpoints.down("sm")]: {
        padding: "60px 20px !important",
      },
    },
    "& .kg_home_hero3 h5": {
      "@media (max-width:1023px)": {
        marginBottom: "15px",
      },
    },
    "& .kg_home_hero4 h5": {
      fontSize: "58px",
      lineHeight: "65px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "38px",
        lineHeight: "44px",
      },
    },
    "& .kg_home_hero4 figure:after": {
      paddingBottom: "270px",
    },
    "& .kg_home_hero p": {
      fontSize: "20px",
      letterSpacing: "1px",
      maxWidth: "670px",
    },
    "&  ul li": {
      width: "50%",
      fontSize: "22px",
      fontWeight: "600",
      lineHeight: "36px",
      paddingRight: "25px",
    },
    "& ul li": {
      "@media (max-width:767px)": {
        fontSize: "18px",
        fontWeight: "600",
        lineHeight: "30px",
        width: "100%",
      },
    },
    position: "relative",
    "& .kg_home_hero:nth-child(even) .kg_content_hero ": {
      order: 2,
      // [theme.breakpoints.down("sm")]: {
      //   order: 2,
      // },
    },
    "& .kg_home_hero .kg_content_hero ": {
      [theme.breakpoints.down("sm")]: {
        order: 2,
      },
    },
    "& .kg_home_hero:first-child": {
      color: " #fff",
    },
  },
  heading: {
    "& h5": {
      fontSize: "66px",
      lineHeight: "72px",
      marginBottom: "44px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "38px",
        lineHeight: "44px",
        marginBottom: "40px",
        marginTop: "70px",
      },
      "@media (min-width:1024px) and (max-width:1400px)": {
        fontSize: "48px",
        lineHeight: "58px",
        marginBottom: "44px",
      },
    },
    "&  p": {
      fontSize: "22px",
      lineHeight: "36px",
      fontWeight: 600,
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px", 
        fontWeight: "600",
        lineHeight: "30px",
      },
      "@media (min-width:1024px) and (max-width:1400px)": {
        fontSize: "20px",
        lineHeight: "34px",
      },
    },
  },
  consultantCard: {
    alignItems: "center",
    padding: "150px 120px",
    [theme.breakpoints.down("sm")]: {
      padding: "60px 20px",
    },
  },
  imageroot: {
    justifyContent: "center !important",
    [theme.breakpoints.down("sm")]: {
      order: 1,
    },
  },
  imageContainer: {
    display: "block",
    maxWidth: "570px",
    height: "100%",
    objectFit: "cover",
    position: "relative",
    verticalAlign: "middle",
    margin: "0",
    width: "100%",
    "&:after": {
      paddingBottom: "91.3%",
      content: '""',
      position: "relative",
      display: "block",
      width: "100%",
    },
  },
  consultant: {
    position: "absolute",
    top: "0",
    right: "0",
    left: "0",
    bottom: "0",
    objectFit: "contain",
    objectPosition: "center",
    width: "100%",
    height: "100%",
    display: "inline-block",
  },
  sublines: {
    display: "block",
    width: "100%",
    textAlign: "center",
    marginTop: "60px",
  },
}));

const Hero = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const post = props.pageContent?.node?.services.typesOfServices;

  return (
    <div className={clsx(classes.root, className, "kg_home_hero")} {...rest}>
  
      {post?.map((item, index) => {
        return (
          <>
            {item ? (
              <Grid
                container
                direction="row"
                justify="space-between"
                className={
                  classes.consultantCard +
                  " " +
                  "kg_home_hero kg_home_hero" +
                  index
                }
                style={{ backgroundColor: `#${item.color}` }}
              >
                <Grid
                  item
                  container
                  justify="flex-start"
                  xs={12}
                  md={8}
                  lg={6}
                  xl={6}
                  className={classes.heading + " " + "kg_content_hero"}
                >
                  <Typography variant="h5" display="block">
                    {item.nameOfService}
                  </Typography>
                  <Typography variant="body1" className={classes.subline}>
                    <div
                      className="page-content"
                      dangerouslySetInnerHTML={{ __html: item.serviceInfo }}
                    ></div>
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  alignItems="center"
                  xs={12}
                  md={4}
                  lg={6}
                  xl={6}
                  className={classes.imageroot}
                >
                  {item.serviceImage?.mediaItemUrl ? (
                    <>
                      <figure className={classes.imageContainer}>
                        <img
                          src={item.serviceImage.mediaItemUrl}
                          className={classes.consultant}
                          alt="Jordan Pearce"
                        />
                      </figure>
                    </>
                  ) : (
                    <>
                      <figure className={classes.imageContainer}>
                        <img
                          src="/images/placeholder.png"
                          className={classes.consultant}
                          alt="Jordan Pearce"
                        />
                      </figure>
                    </>
                  )}
                  {item?.serviceImageAfterTitle ? (
                    <Typography variant="body1" className={classes.sublines}>
                      {item.serviceImageAfterTitle}
                    </Typography>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            ) : (
              ""
            )}
          </>
        );
      })}
    </div>
  );
};

Hero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Hero;
