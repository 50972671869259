import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, TextField, Button, Hidden } from "@material-ui/core";
import { SectionHeader } from "../../../../components/molecules";
import { Description } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    // "& input#filled-search": {
    //   color: "#0e44ff",
    //   padding: "0 30px",
    //   [theme.breakpoints.down("sm")]: {
    //     padding: 0,
    //   },
    // },
    "& .MuiInputBase-root.MuiInput-root.makeStyles-textfield-57.MuiInputBase-fullWidth.MuiInput-fullWidth.MuiInputBase-formControl.MuiInput-formControl": {
      borderBottom: "2px solid #0e44ff",
    },
    "& .MuiInputBase-root.MuiInput-root.MuiInputBase-fullWidth.MuiInput-fullWidth.MuiInputBase-formControl.MuiInput-formControl ": {
      borderBottom: "2px solid",
    },
    "& .kg_image_div:last-child": {
      "@media (max-width:785px)": {
        marginBottom: 0,
      },
    },
  },
  reversemob: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  divImage: {
    marginTop: 0,
    marginRigth: 0,
    display: "block",
    height: "100%",
    objectFit: "cover",
    position: "center",
    verticalAlign: "middle",
    marginLeft: "auto !important",
    "& img": {
      "@media (max-width:1024px)": {
        objectFit: "contain",
      },
    },
    "&:after": {
      "@media (max-width:767px)": {
        paddingBottom: "70%",
      },
      "@media (max-width:1024px)": {
        paddingBottom: "60%",
      },
      paddingBottom: "100%",
      content: '""',
      position: "relative",
      display: "block",
      width: "100%",
    },
  },
  banner: {
    position: "absolute",
    right: 0,
    bottom: 0,
    objectFit: "cover",
    objectPosition: "center",
    width: "100%",
    height: "100%",
    display: "inline-block",
    top: "50%",
    left: "50%",
    WebkitTransform: "translate(-52%,-77%)",
    msTransform: "translate(-52%,-77%)",
    transform: "translate(-52%,-77%)",
  },
  bannerGrid: {
    maxWidth: "1000px",
  },
  contentGrid: {},
  listItemButton: {
    float: "right",
    marginBottom: "15px",
    borderRadius: "unset",
    color: "#0e44ff",
    borderColor: "#0e44ff",
  },
  description: {
    fontWeight: 600,
  },
  imageBody: {
    "@media (max-width:1024px)": {
      order: 1,
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      width: "100%",
      margin: "auto",
    },
  },
  mainImage: {
    "@media (max-width:1024px)": {
      marginBottom: "24px",
      textAlign: "center",
    },
    "@media (max-width:785px)": {
      flexGrow: 0,
      maxWidth: "50%",
      flexBasis: "50%",
    },
  },
  mainImageDiv: {
    display: "block",
    height: "100%",
    objectFit: "cover",
    position: "relative",
    margin: "0",
    maxWidth: "480px",
    marginTop: "-80px",
    "&:after": {
      paddingBottom: "80%",
      content: '""',
      position: "relative",
      display: "block",
      width: "100%",
      "@media (max-width:1024px)": {
        paddingBottom: "56%",
      },
      "@media (max-width:785px)": {
        paddingBottom: "80%",
      },
    },

    "@media (max-width:1024px)": {
      marginTop: 0,
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: "24px",
    },
  
    "@media (max-width:1400px)": {
      marginTop: "0px",
    },
  },
  contentImg: {},
  imageBody2: {
    paddingLeft: "60px",
    alignItems: "flex-start",
    paddingTop: "180px",
    "@media (max-width:1024px)": {
      maxWidth: "100%",
      paddingLeft: 0,
      paddingTop: "0 !important",
    },
    "@media (max-width:1400px)": {
      paddingTop: "90px",
    },
  },
  banner1: {
    position: "absolute",
    top: "0",
    right: "0",
    left: "0",
    bottom: "0",
    objectFit: "contain",
    objectPosition: "center",
    width: "100%",
    height: "100%",
    display: "inline-block",
  },
  maincontainer: {
    "@media (max-width:785px)": {
      flexGrow: 0,
      maxWidth: "50%",
      flexBasis: "50%",
    },
  },
  banner2: {
    maxWidth: "430px",
    width: "100%",
    "@media (max-width:767px)": {
      maxWidth: "300px !important",
    },
    "@media (max-width:1024px)": {
      maxWidth: "100%",
    },
  },
  mainImageDiv2: {
    "@media (max-width:785px)": {
      marginBottom: "30px",
    },
    marginBottom: "50px",
  },
}));

const CreativeWorkBody = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const post = props.post;
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Grid container justify="space-between" className={classes.reversemob}>
        <Grid
          item
          alignItems="center"
          xs={12}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          data-aos={"fade-up"}
          className={classes.imageBody}
        >
          <Grid
            item
            alignItems="center"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            data-aos={"fade-up"}
            className={classes.bannerGrid}
          >
            <Hidden smDown>
              <div className={classes.divImage}>
                {post?.bodyimage?.mediaItemUrl ? (
                  <img
                    className={classes.banner}
                    // src="/images/Package-Box-Mockup.png"
                    src={post?.bodyimage?.mediaItemUrl}
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="2000"
                  />
                ) : (
                  <Skeleton
                    variant="rectangular"
                    className={classes.banner}
                    animation="wave"
                  />
                )}
              </div>
            </Hidden>
            <Hidden mdUp>
              <div className={classes.divImage}>
                {post?.mobileimage?.mediaItemUrl ? (
                  <img
                    className={classes.banner}
                    src={post?.mobileimage?.mediaItemUrl}
                    // src="/images/Package-Box-Mockup.png"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="2000"
                  />
                ) : (
                  <Skeleton
                    variant="rectangular"
                    className={classes.banner}
                    animation="wave"
                  />
                )}
              </div>
            </Hidden>
          </Grid>
        </Grid>
        <Grid
          container
          item
          alignItems="center"
          xs={12}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          data-aos={"fade-up"}
          className={classes.imageBody2}
        >
          <Grid
            item
            alignItems="center"
            xs={12}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            data-aos={"fade-up"}
            className={classes.maincontainer}
          >
            <div className={classes.mainImageDiv}>
              <img
                className={classes.banner1}
                src="/images/Layer88.png"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
              />
            </div>
          </Grid>
          <Grid
            item
            alignItems="center"
            xs={12}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            data-aos={"fade-up"}
            className={classes.mainImage}
          >
            <div className={classes.mainImageDiv2 + " " + "kg_image_div"}>
              <img
                className={classes.banner2}
                src="/images/Layer190.png"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
              />
            </div>
            <div className={classes.mainImageDiv2}>
              <img
                className={classes.banner2}
                src="/images/Layer189.png"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

CreativeWorkBody.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default CreativeWorkBody;
