import React, { useState, useEffect, useRef, useCallback, cloneElement } from 'react';

function Carousel(props) {
  const { children, height, width, autoPlay } = props;
  const boxRef = useRef(null);
  const playTimer = useRef(0);
  const rotation = useRef(Math.PI / 2);
  const destRotation = useRef(Math.PI / 2);
  const farScale = 0.5;
  const speed = 4;

  const [hovered, setHovered] = useState(false);
  const [touched, setTouched] = useState(false);

  const xy = (box, data) => {
    const boxWidth = box.offsetWidth || 0;
    const boxHeight = box.offsetHeight || 0;
    const xOrigin = data.xOrigin || boxWidth * 0.5;
    const yOrigin = data.yOrigin || boxHeight * 0.1;
    const xRadius = data.xRadius || boxWidth / 2.3;
    const yRadius = data.yRadius || boxHeight / 6;

    return { xOrigin, yOrigin, xRadius, yRadius };
  };

  const cancelFrame = typeof window !== 'undefined' ? window.cancelAnimationFrame : null;
  const requestFrame = typeof window !== 'undefined' ? window.requestAnimationFrame : null;


  function time() {
    return performance.now();
  }

  const renderItem = (item, { rotation, farScale, xOrigin = 0 , yOrigin = 0 , xRadius, yRadius }) => {
    
    const sin = Math.sin(rotation);
    const scale = farScale + (1 - farScale) * (sin + 1) * 0.5;

    const moveTo = (item, x, y, scale) => {
      const style = item.style;
      style.zIndex = `${(scale * 100) | 0}`;
      style.transform = 'translate(' + x + 'px, ' + y + 'px) scale(' + scale + ')';
    };

    moveTo(
      item,
      xOrigin + scale * (Math.cos(rotation) * xRadius - item.offsetHeight * 0.5),
      yOrigin + scale * sin * yRadius,
      scale
    );
  };

  const renderItems = (items, { rotation, farScale, xOrigin, yOrigin, xRadius, yRadius }) => {
    const count = items.length;
    const spacing = (2 * Math.PI) / count;
    let radians = rotation;

    for (let i = 0; i < count; i++) {
      renderItem(items[i], {
        rotation: radians,
        farScale,
        xOrigin,
        yOrigin,
        xRadius,
        yRadius,
      });
      radians += spacing;
    }
  };

  const itemsRotated = (itemCount, rotation) => {
    return (itemCount * (Math.PI / 2 - rotation)) / (2 * Math.PI);
  };

  const floatIndex = (itemCount, rotation) => {
    const count = itemCount;
    let floatIndex = itemsRotated(itemCount, rotation) % count;

    return floatIndex < 0 ? floatIndex + count : floatIndex;
  };

  const go = (count, destRotation, itemCount) => {
    return (destRotation -= ((2 * Math.PI) / itemCount) * count);
  };

  const play = useCallback((destRotation, rotation, speed, fn) => {
    let timer = 0;
    let lastTime = 0;

    if (timer === 0) scheduleNextFrame();

    function playFrame() {
      const rem = destRotation - rotation;
      const now = time();
      const dt = (now - lastTime) * 0.002;
      lastTime = now;

      if (Math.abs(rem) < 0.003) {
        rotation = destRotation;
        cancelFrame(timer);
        timer = 0;
      } else {
        rotation = destRotation - rem / (1 + speed * dt);
        scheduleNextFrame();
      }

      fn(rotation, timer);
    }

    function scheduleNextFrame() {
      lastTime = time();
      timer = requestFrame(playFrame);
    }
  }, []);

  const carousel = useCallback((index) => {
    console.log(index, "im index");
    const itemCount = children.length;
    const currentPosition = itemsRotated(itemCount, rotation.current);
    const newPosition = currentPosition + index;
    const diff = Math.round(newPosition) - currentPosition; 
  
    destRotation.current = go(diff , destRotation.current, itemCount); 
  
    play(destRotation.current, rotation.current, speed, (data, timer) => {
      if (!boxRef.current) return;
  
      playTimer.current = timer;
      rotation.current = data;
      const { xOrigin, yOrigin, xRadius, yRadius } = xy(boxRef.current, props);
      renderItems(boxRef.current?.children, {
        rotation: data,
        farScale,
        xOrigin,
        yOrigin,
        xRadius,
        yRadius,
      });
    });
  }, []);
  

  useEffect(() => {
    let autoPlayTimer;
    const children = boxRef.current?.children || [];
    const { xOrigin, yOrigin, xRadius, yRadius } = xy(boxRef.current, props);

    renderItems(boxRef.current?.children, {
      rotation: rotation.current,
      farScale,
      xOrigin,
      yOrigin,
      xRadius,
      yRadius,
    });

    if (autoPlay && !hovered && !touched) {
      autoPlayTimer = setInterval(function () {
        carousel(1);
      }, 2000);
    }

    return () => {
      clearInterval(autoPlayTimer);
      cancelFrame(playTimer.current);
    };
  }, [hovered, touched]);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleTouchStart = () => {
    setTouched(true);
  };

  const handleTouchEnd = () => {
    setTouched(false);
  };

  const itemClick = useCallback((e) => {
    const idx = e.currentTarget.getAttribute('index');
    const count = children.length;

    let diff = idx - (floatIndex(count, rotation.current) % count);

    if (!diff) return;

    if (2 * Math.abs(diff) > count) diff += diff > 0 ? -count : count;

    if (Math.abs(diff) > 0.5) e.preventDefault()

    carousel(-diff);
  }, []);

  const content = (items, onClick) => {
    const style = {
      display: 'inline-block',
      position: 'absolute',
      transformOrigin: '0 0',
    };
    return items.map((item, index) => {
      return cloneElement(item, { index, onClick, style: { ...item.props.style, ...style } });
    });
  };  
  
  return (
    <div
      onClick={(e) => {}}
      ref={boxRef} 
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      style={{position: 'relative', height , width}}
    >
      {content(children, itemClick)}
    </div>
  );
}

export default Carousel;
