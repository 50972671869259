import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Avatar, Breadcrumbs, Link, Typography } from "@material-ui/core";
import { SectionHeader } from "../../../../components/molecules";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "50px",
    "& .MuiGrid-root.section-header__subtitle-wrapper.MuiGrid-item.MuiGrid-grid-xs-12": {
      marginBottom: "24px",
    },
    "& .kg-hero-content-area .kg-inner-hero-content .kg_h2": {
      // marginBottom: "0.75em !important",
      color:"#091d35",
      fontSize: "3.125rem",
    },
    "&  .kg-hero-content-area .kg-inner-hero-content h1": {
      fontSize: "48px",
      lineHeight: "56px",
      marginTop: "0",
      marginBottom: "30px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "38px",
        marginTop: "0",
        marginBottom: "24px",
        lineHeight: "46px",
      },
    },
    "& .kg-hero-content-area-right .kg-inner-hero-content-img img ": {
      position: "absolute",
      top: "0",
      right: "0",
      left: "0",
      bottom: "0",
      objectFit: "contain",
      objectPosition: "right",
      width: "100%",
      height: "100%",
      display: "inline-block",

      "@media only screen and (max-width:1023px)": {
        // .kg-hero-content-area-right .kg-inner-hero-content-img img {
        objectPosition: "center",
        // },
      },
    },
    "& .kg-hero-content-area-right .kg-inner-hero-content-img:after ": {
      paddingBottom: "84%",
      content: '" "',
      position: "relative",
      display: "block",
      width: "100%",
    },
    "& .kg-hero-content-area .kg-inner-hero-content p.kg-full-content": {
      fontSize: "1.4375rem",
      // marginBottom: "24px",
      marginTop: "34px",
      fontWeight: 600,
      color :"#091d35",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
        marginTop: "22px",
      },
    },
    "& .kg-hero-content-area-right": {
      width: "100%",
      height: "100%",
      paddingTop: "42px",
    },
    "& .kg-inner-hero-content-img": {
      display: "block",
      // maxWidth: "640px",
      objectFit: "cover",
      position: "relative",
      marginLeft: "auto",
    },
    "& .kg-hero-content-area": {
      maxWidth: "1000px",
      marginTop: "40px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "0",
      },
    },
    "& .kg-hero-content-area .kg-inner-hero-content h2": {
      "@media (max-width:1024px)": {
        fontSize: "1.5rem",
      },
      fontSize: "3.75rem",
      marginBottom: "0.35em",
      marginTop: 0,
    },
  },
}));

const Header = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const post = props.post;

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Grid container justify="space-between">
        <Grid
          item
          container
          alignItems="center"
          xs={12}
          md={6}
          lg={6}
          xl={6}
          data-aos={"fade-up"}
        >
          <div class="kg-hero-content-area">
            <div class="kg-inner-hero-content">
              <div
              dangerouslySetInnerHTML={{
                __html: post?.content,
              }}
              >
              </div>
            </div>
          </div>
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          xs={12}
          md={6}
          lg={6}
          xl={6}
          data-aos={"fade-up"}
        >
          <div class="kg-hero-content-area-right">
            <div class="kg-inner-hero-content-img">
              <img
                src={post?.featuredImage?.node?.mediaItemUrl}
                alt=""
                className={classes.images}
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

Header.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Header;
