import React from "react";
import { Section } from "./components/organisms";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    backgroundColor: "#fff",
  },
  headerPaddingTop: {
    textAlign: "center",
    paddingTop: "300px",
    color: "#0e44ff",
    fontSize: "24px",
    textTransform: "capitalize",
    backgroundColor: "#f9f9f9",
    paddingBottom: "300px",
    paddingLeft: "120px",
    paddingRight: "120px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingBottom: 0,
    },
    " @media (max-width: 1023.95px)": {
      paddingBottom: "140px !important",
      paddingTop: "150px !important",
    },
  },
}));

export default function PageNotFound() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Section className={classes.headerPaddingTop}>
        <div>
          <h2>404 Page not found</h2>
        </div>
      </Section>
    </div>
  );
}
