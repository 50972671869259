import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  root: {
    "&": {
      background: "#f2f3f8",
      padding: "120px 120px !important",
      "@media only screen and (max-width:1023px)": {
        padding: "60px 20px !important",
      },
    },
  },
  headingh4: {
    fontSize: "1.5rem",
    marginTop: "25px",
    fontWeight: 600,
    lineHeight: "28px",
    fontFamily: "AktivGrotesk",
    width: "100%",
    textAlign: "center",
    "@media only screen and (min-width:1921px)": {
      fontSize: "2.4rem",
    },
  },
  headingh5: {
    fontSize: "1.125rem",
    marginTop: "20px",
    fontWeight: "600",
    lineHeight: "28px",
    fontFamily: "AktivGrotesk",
    textAlign: "center",
    "@media only screen and (min-width:1921px)": {
      fontSize: "1.5rem",
    },
    "@media only screen and (max-width:1023px)": {
      fontSize: "1rem",
      marginTop: "5px",
    },
  },
  grids: {
    justifyContent: "center",
    padding: "0 100px",
    marginBottom: "60px",
    "@media (min-width:1024px) and (max-width:1440px)": {
      padding: "0 30px",
    },
    "@media only screen and (max-width:1023px)": {
      padding: "0 20px",
    },
    "@media only screen and (max-width:991px)": {
      padding: "0 0px",
    },
  },
  figure: {
    display: "block",
    position: "relative",
    maxWidth: "100%",
    width: "100%",
    margin: "auto",
    objectFit: "cover",
    "&::after": {
      content: '""',
      position: "relative",
      display: "block",
      width: "100%",
      paddingBottom: "55%",
    },
    "& img": {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      objectFit: "contain",
      objectPosition: "center",
      display: "inline-block",
      width: "auto",
      height: "auto",
      maxWidth: "100%",
      maxHeight: "100%",
      margin: "auto",
    },
  },
  titleh4: {
    marginTop: 0,
    fontSize: "3.75rem",
    fontFamily: "AktivGrotesk-Medium",
    lineHeight: "72px",
    marginBottom: "44px",
    "@media only screen and (min-width:1921px)": {
      fontSize: "5rem",
    },
    "@media only screen and (max-width:1023px)": {
      fontSize: "2.25rem",
      marginBottom:"20px",
      lineHeight:"38px",
    },
  },
  titledescription: {
    fontSize: "21px",
    textAlign: "center",
    fontFamily: "AktivGrotesk",
    fontWeight: 600,
    lineHeight: "28px",
    marginTop: 0,
    marginBottom: "100px",
    maxWidth: "69%",
    "@media only screen and (min-width:1921px)": {
      fontSize: "1.8rem",
    },
    "@media only screen and (max-width:1023px)": {
      fontSize: "1rem",
      marginBottom: "75px",
    },
    "@media only screen and (max-width:991px)": {
      maxWidth: "100%",
      marginBottom: "60px",
    },
  },
}));


const AuditServices = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const post = props.pageContent?.node?.auditservices;
  return (
    <div className={clsx(classes.root, className, "kg_services")} {...rest}>
      <Grid container direction="row" justify="center" className={classes}>
        {post?.serviceAuditTitle ? (
          <Typography variant="h2" display="block" className={classes.titleh4}>
            {post.serviceAuditTitle}
          </Typography>
        ) : (
          <Typography variant="h2" display="block" className={classes.titleh4}>
            Audit Services
          </Typography>
        )}
      </Grid>
      <Grid container direction="row" justify="center" className={classes}>
        {post?.serviceAuditDescription ? (
          <Typography
            variant="p"
            display="block"
            className={classes.titledescription}
          >
            <div
              className="page-content"
              dangerouslySetInnerHTML={{
                __html: post.serviceAuditDescription,
              }}
            ></div>
          </Typography>
        ) : (
          <Typography
            variant="p"
            display="block"
            className={classes.titledescription}
          >
            All of our custom audits can either focus on the complete picture or
            be tailored to specific areas you feel need attention. Here are some
            of the core areas we can assess.
          </Typography>
        )}
      </Grid>
      <Grid container direction="row" justify="space-between">
        {post?.typesOfServicesAudit.map((item, index) => {
          return (
            <>
              {item ? (
                <Grid
                  item
                  container
                  justify="flex-start"
                  xs={12}
                  md={4}
                  lg={4}
                  xl={4}
                  className={classes.grids}
                >
                  {item.serviceAuditImage?.mediaItemUrl ? (
                    <>
                      <figure className={classes.figure}>
                        <img
                          src={item.serviceAuditImage.mediaItemUrl}
                          className={classes.consultant}
                          alt="Jordan Pearce"
                        />
                      </figure>
                    </>
                  ) : (
                    <>
                      <figure className={classes.imageContainer}>
                        <img
                          src="/images/placeholder.png"
                          className={classes.consultant}
                          alt="Jordan Pearce"
                        />
                      </figure>
                    </>
                  )}


                  {item?.nameOfServiceAudit ? (
                    <Typography
                      variant="h4"
                      display="block"
                      className={classes.headingh4}
                    >
                      {item.nameOfServiceAudit}
                    </Typography>
                  ) : (
                    ""
                  )}


                  {item?.serviceAuditInfo ? (
                    <Typography
                      variant="h5"
                      display="block"
                      className={classes.headingh5}
                    >
                      <div
                        className="page-content"
                        dangerouslySetInnerHTML={{
                          __html: item.serviceAuditInfo,
                        }}
                      ></div>
                    </Typography>
                  ) : (
                    ""
                  )}
                </Grid>
              ) : (
                ""
              )}
            </>
          );
        })}
      </Grid>
    </div>
  );
};


AuditServices.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};


export default AuditServices;